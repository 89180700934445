import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import app from "../firebase";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";

import { AuthContext } from "../Auth";

import * as toastr from "toastr";

const AdminUsers: React.FunctionComponent<unknown> = (props: any) => {

    const { t } = useTranslation();

    const { user } = useContext(AuthContext);

    const [users, setUsers] = useState<Array<any>>([]);

    useEffect(() => {
        const asyncUseEffect = async () => {
            try {
                if (user) {
                    const db = getFirestore(app);
                    const q = query(collection(db, "users"), orderBy("email", "asc"));
                    const data = await getDocs(q);
                    const users = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setUsers(users);
                }
            } catch (error: any) {
                toastr.error(error);
            }
        };
    
        asyncUseEffect();
    }, [user]);

    const isBlank = (txt: string) => txt.trim() === "" ? "n/a" : txt;

    const userRows = users.map((u) =>
        <tr key={u.email}>
            <td>{u.email}</td>
            <td>{isBlank(u.displayName)}</td>
            <td>{u.emailVerified.toString()}</td>
        </tr>
    )

    return (
        <div className="pageBody">

            <h1 className="pageHeading">{t("adminUsers.adminUsers")}</h1>

            <p>{users.length} users.</p>

            <table className="table table-striped table-sm">
                <thead className="thead-dark">
                    <tr>
                        <th>{t("adminUsers.email")}</th>
                        <th>{t("adminUsers.displayName")}</th>
                        <th>{t("adminUsers.emailVerified")}</th>
                    </tr>
                </thead>
                <tbody>
                    {userRows}
                </tbody>
            </table>

        </div>
    );
}

export default AdminUsers;
