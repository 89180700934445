import React, { useEffect } from "react";

import htmlToPdfmake from "html-to-pdfmake";

import { detect } from "detect-browser";

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";

import { CharacterTraits, EquipmentItem, PrintTraits } from "../interfaces/CharacterTraits";
import { FocusLevel } from "../interfaces/FocusLevel";
import { attributeAbr, attributeLongNames } from "../lookups/Enums";
import { Lookups } from "../lookups/Lookups";
import { getSkillsByNotType, getSkillsByType } from "../utilities/SkillUtilities";
import { formatCredits, getAttributeModifierPlusMinus, getRerolls, viDescription } from "../utilities/Utilities";
import { PsychicTechniqueLevel } from "../interfaces/PsychicTechniqueLevel";
import { getDroneDesc, getMeleeWeaponDesc, getRangedWeaponDesc, getVehicleDesc, getRobotDesc, sortItems } from "../utilities/charMainUtilities";
import { attributeMethod } from "../utilities/Utilities";
import ValidationAlert from "./ValidationAlert";

interface IProps {
    charTraits: CharacterTraits;
    onSetPrintTraits: (printTraits: PrintTraits) => void;
}

const CharacterDesignSheetScreen: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    useEffect(() => {
        (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
    }, []);

    const charDerivedStats = new CharacterDerivedStats(char);
    charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100, 100);
    charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, -1, -1, 100, 100);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);
    charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, char.level, 100);
    charDerivedStats.calculateHitPointsLevels(CreationStep.AllSteps, char.level);
    charDerivedStats.calculateBaseAttack(CreationStep.AllSteps);
    charDerivedStats.calculateSavingThrows(CreationStep.AllSteps);
    charDerivedStats.calculateEffort(CreationStep.AllSteps);
    charDerivedStats.calculateArmorClassVsPrimitiveWeapons(CreationStep.AllSteps);
    charDerivedStats.calculateArmorClassVsAdvancedWeapons(CreationStep.AllSteps);
    charDerivedStats.calculateEncumbrance(CreationStep.AllSteps);
    charDerivedStats.calculateEffort(CreationStep.AllSteps);
    charDerivedStats.calculateBaseAttack(CreationStep.AllSteps);
    charDerivedStats.calculateIsPsychic();
    charDerivedStats.calculateRangedWeaponStats(CreationStep.AllSteps, false);
    charDerivedStats.calculateMeleeWeaponStats(CreationStep.AllSteps, false);
    charDerivedStats.calculateVehicleStats(CreationStep.AllSteps, false);
    charDerivedStats.calculateDroneStats(CreationStep.AllSteps, false);
    charDerivedStats.calculateRobotStats(CreationStep.AllSteps, false);
    charDerivedStats.calculateSystemStrain();
    charDerivedStats.calculateTotalGearValue();

    const lookups = Lookups.getInstance();

    const fontSize = 10;
    const lineHeight = 1.3;

    const blank = (text: string, def: string) => {
        if (text.trim() === "") { return def; }
        return text.trim();
    }

    const removeBar = (t: string) => t.replaceAll("|", " ");

    const getName = () => {
        return {
            text: blank(char.basicTraits.name, "No Name"),
            style: 'heading'
        }
    }

    const getTextWithBoldTitle = (heading: string, theText: string, noText: string, margin: number[] = [0, 0, 0, 0]) => {

        return {
            text: [
                { text: heading, style: "bold", lineHeight, fontSize },
                { text: blank(removeBar(theText), noText), lineHeight, fontSize },
            ],
            margin
        }
    };

    const getClassName = () => {

        let theClass = "";
        if (props.charTraits.levelOne.classes.length === 0) { theClass = ""; }
        else if (props.charTraits.levelOne.classes.length === 1) {
            theClass = props.charTraits.levelOne.classes[0].className;
        } else {
            theClass = "Adventurer (" + props.charTraits.levelOne.classes.map((c) => c.className.replace("Partial", "").trim()).join("/") + ")";
        }
        return theClass;
    }

    const getAttributesAsTable = () => {
        let attrArray: any[] = [];
        if (charDerivedStats.attributeLevels.length > 0) {
            charDerivedStats.attributeLevels.forEach((al) => {
                const thisAttr = attributeAbr[al.attributeIndex];
                if (al.level) {
                    const thisAttrLevel = al.level;
                    let thisAttrMod = "";
                    if (al.level !== null) {
                        thisAttrMod = getAttributeModifierPlusMinus(al.attributeName, al.level, charDerivedStats);
                    }
                    const thisAttrArray = [{ text: thisAttr + " " + thisAttrLevel.toString(), fontSize, lineHeight: 1 }, { text: thisAttrMod, alignment: 'right', fontSize, lineHeight: 1 }];
                    attrArray.push(thisAttrArray);
                }
                else {
                    const thisAttrArray = [thisAttr + " -", { text: "", fontSize, lineHeight: 1 }];
                    attrArray.push(thisAttrArray);
                }
            })
        }
        else {
            attributeLongNames.forEach((an) => {
                const thisAttrLevel = 0;
                const thisAttrMod = getAttributeModifierPlusMinus(an, thisAttrLevel, charDerivedStats);
                const thisAttrArray = [an + " " + thisAttrLevel, { text: thisAttrMod, alignment: 'right', fontSize, lineHeight }];
                attrArray.push(thisAttrArray);
            })
        }

        return {
            table: {
                body: attrArray,
            },
            layout: 'noBorders',
            width: "25%"
        };
    }

    const getClassAbilities = () => {

        let theClass = "";
        if (props.charTraits.levelOne.classes.length === 0) { theClass = ""; }
        else if (props.charTraits.levelOne.classes.length === 1) {
            theClass = props.charTraits.levelOne.classes[0].className;
            switch (theClass) {
                case "Warrior": return "Once per scene, negate a successful attack roll against you or turn a missed attack roll you made into a hit.";
                case "Expert": return "Once per scene, you can reroll a failed skill check, taking the new roll if it's better.";
                case "Psychic": return "Psychic disciplines and techniques, plus possess an Effort score.";
                default: return "-";
            }
        } else {
            let cb: string[] = [];
            props.charTraits.levelOne.classes.forEach((c) => {
                switch (c.className) {
                    case "Partial Warrior": break;
                    case "Partial Expert": break;
                    case "Partial Psychic": cb.push("Restricted Psychic disciplines and techniques, plus possess an Effort score."); break;
                }
            })
            return cb.join("; ");
        }
        return "-";
    }

    const getSkills = () => {
        const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
        let s: string[] = [];
        charDerivedStats.skillLevels.sort((a, b) => a.skill > b.skill ? 1 : -1).forEach((sl) => {
            const isPsySkill = allPsychicSkills.find((ps) => ps.skill === sl.skill);
            if (!isPsySkill) {
                if (sl.level !== null) {
                    let thisSkill = sl.skill + "-" + (sl.level - 1);
                    s.push(thisSkill);
                }
            }
        });
        return s.join(", ");
    }

    const getIsPsychic = () => {
        let isPsychic = false;

        const psychicClasses = char.levelOne.classes.filter((c) => c.className === "Psychic" || c.className === "Partial Psychic");
        if (psychicClasses.length > 0) { isPsychic = true; }

        return isPsychic;
    }

    const getIsWildTalent = () => {
        let isWildTalent = false;

        const wildTalentFocuses = charDerivedStats.focusLevels.filter((f) => f.focus === "Wild Psychic Talent");
        if (wildTalentFocuses.length > 0) { isWildTalent = true; }

        return isWildTalent;
    }

    const getFoci = () => {
        let t: string[] = [];
        charDerivedStats.focusLevels.sort((a, b) => a.focus > b.focus ? 1 : -1).forEach((fl) => {
            // Check does not also have level-2 of focus
            const level1Focus = charDerivedStats.focusLevels.find((f) => f.focus === fl.focus && f.level === 2);
            if (!level1Focus || fl.level === 2) {
                let thisFocus = fl.focus + "-" + fl.level;
                t.push(thisFocus);
            }
        });
        return t.join(", ");
    }

    const techniqueSort = (a: PsychicTechniqueLevel, b: PsychicTechniqueLevel) => {
        return a.level < b.level ? -1 : 1;
    }

    const getPsychicDisciplinesAndTechniques = () => {
        const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
        // Get all the character's psychic skills (disciplines)
        let s: string[] = [];
        charDerivedStats.skillLevels.sort((a, b) => a.skill > b.skill ? 1 : -1).forEach((sl) => {
            const isPsySkill = allPsychicSkills.find((ps) => ps.skill === sl.skill);
            if (isPsySkill) {
                if (sl.level !== null) {
                    let thisSkill = sl.skill + "-" + (sl.level - 1);
                    // Get the techniques for the skill:
                    let techniques: string[] = [];

                    // (JSON.stringify(charDerivedStats.psychicTechniqueLevels));

                    charDerivedStats.psychicTechniqueLevels.filter((tl) => tl.skill === sl.skill).sort(techniqueSort).forEach((tl) => {

                        let level = "";
                        if (tl.level === 0) {
                            // core technique
                            level = sl.level ? (sl.level - 1).toString() : "";
                        } else {
                            // additional technique
                            level = tl.level.toString();
                        }

                        techniques.push(tl.technique + "-" + level);
                    });

                    s.push(thisSkill + " (" + techniques.join(", ") + ")");
                }
            }
        });
        s.sort();
        return s.join(", ");
    }

    const getWildTalentTechniques = () => {

        let techniques: string[] = [];
        charDerivedStats.psychicTechniqueLevels.sort(techniqueSort).forEach((tl) => {
            techniques.push(tl.technique + "-" + tl.level);
        });

        const psiSkill = char.levelOne.wildTalentPicks.wildTalentPsychicDiscipline;

        return psiSkill + ": " + techniques.join(", ");
    }


    const getEffort = () => {
        return charDerivedStats.effortLevels.effort;
    }

    const getEquipment = (storageType: string) => {
        let gear: string[] = [];

        const replaceCommaWithParentheses = (theText: string) => {
            if (theText.indexOf(",") !== -1) {
                return theText.replace(", ", " (") + ")";
            }
            return theText;
        }

        const getByStorage = (e: EquipmentItem, storage: string) => {
            const storQuant = e.storageQuantity.find((sq) => sq.storage === storage);
            if (storQuant) { return true; }
            return false;
        }

        const items = props.charTraits.gear.equipment.filter((i) => getByStorage(i, storageType)).sort((a, b) => a.name < b.name ? -1 : 1);
        if (items.length > 0) {
            items.forEach((i) => {

                // // Don't show TK Armory items in owned equipment list.
                // let isTKItem = false;
                // const packDetails = lookups.gearPacks.find((gp) => gp.name === "TK Armory Items");
                // if (packDetails) {
                //     if (packDetails.items.find((pi) => pi.id === i.id)) { isTKItem = true; }
                // }

                const getName = (item: EquipmentItem) => {
                    if (i.customName !== undefined) { return i.customName };
                    return i.name;
                }

                if (i.id !== "ME9") { // exclude Unarmed Attack 

                    const storQuant = i.storageQuantity.find((sq) => sq.storage === storageType);
                    if (storQuant) {
                        if (storQuant.quantity > 1) {
                            gear.push(storQuant.quantity + " x " + replaceCommaWithParentheses(getName(i)));
                        } else {
                            gear.push(replaceCommaWithParentheses(getName(i)));
                        }
                    }
                }
            });
        }

        return gear.join(", ");
    }

    const getArmorStats = () => {

        let results: any[] = [];

        const detailsLowTech = [...charDerivedStats.armorClassLevels.history, ...charDerivedStats.armorClassLevels.notes].join("; ");
        const detailsHighTech = [...charDerivedStats.armorClassLevelsVsTL4.history, ...charDerivedStats.armorClassLevelsVsTL4.notes].join("; ");

        results.push(getTextWithBoldTitle("AC " + charDerivedStats.armorClassLevels.ac + " vs low-tech (TL3 or lower) weapons: ", detailsLowTech, "-"));
        results.push(getTextWithBoldTitle("AC " + charDerivedStats.armorClassLevelsVsTL4.ac + " vs high-tech (TL4 or higher) melee weapons and all firearms: ", detailsHighTech, "-"));

        return results;
    }

    const getRangedWeaponStats = () => {
        let results: any[] = [];
        charDerivedStats.rangedWeaponStats
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .forEach((r) => {
                const desc = getRangedWeaponDesc(r)
                results.push(getTextWithBoldTitle(desc.name + ": ", [...desc.shortDesc, ...desc.notes].join(", "), "-"));
                desc.modDesc.forEach((desc) => {
                    const descArray = desc.split("^"); 
                    results.push(getTextWithBoldTitle(descArray[0] + ": ", descArray[1], "-", [10, 0, 0, 0]));
                })

            });
        return results;
    }

    const getMeleeWeaponStats = () => {

        let results: any[] = [];
        charDerivedStats.meleeWeaponStats
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .forEach((r) => {
                const desc = getMeleeWeaponDesc(r)
                results.push(getTextWithBoldTitle(desc.name + ": ", [...desc.shortDesc, ...desc.notes].join(", "), "-"));
                desc.modDesc.forEach((desc) => {
                    const descArray = desc.split("^"); 
                    results.push(getTextWithBoldTitle(descArray[0] + ": ", descArray[1], "-", [10, 0, 0, 0]));
                })
            });
        return results;
    }

    const getVehicleStats = () => {

        let results: any[] = [];
        charDerivedStats.vehicleStats
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .forEach((r) => {
                const desc = getVehicleDesc(r);
                const details = [desc.speed, desc.armor, desc.hp, desc.crew, desc.tonnage];
                if (desc.notes !== "") { details.push(desc.notes); }
                details.push(desc.TL);
                results.push(getTextWithBoldTitle(desc.name + ": ", details.join(", "), "-"));
                desc.modDesc.forEach((desc) => {
                    const descArray = desc.split("^"); 
                    results.push(getTextWithBoldTitle(descArray[0] + ": ", descArray[1], "-", [10, 0, 0, 0]));
                })
            });
        return results;
    }

    const getDroneStats = () => {

        let results: any[] = [];
        charDerivedStats.droneStats
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .forEach((r) => {
                const desc = getDroneDesc(r);
                const details = [desc.speed, desc.ac, desc.hp, desc.range, desc.enc, desc.TL];
                if (desc.notes !== "") { details.push(desc.notes); }
                // results.push(getTextWithBoldTitle(r.name + ": ", desc.join("; "), "-"));
                results.push(getTextWithBoldTitle(desc.name + ": ", details.join(", "), "-"));
                desc.modDesc.forEach((desc) => {
                    const descArray = desc.split("^"); 
                    results.push(getTextWithBoldTitle(descArray[0] + ": ", descArray[1], "-", [10, 0, 0, 0]));
                })
            });
        return results;
    }

    const getRobotStats = () => {

        let results: any[] = [];
        charDerivedStats.robotStats
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .forEach((r) => {
                const desc = getRobotDesc(r);
                const details = [desc.hitDice, desc.ac, desc.atk, desc.move, desc.skill, desc.save, desc.TL];
                if (desc.notes !== "") { details.push(desc.notes); }
                results.push(getTextWithBoldTitle(desc.name + ": ", details.join(", "), "-"));
                desc.modDesc.forEach((desc) => {
                    const descArray = desc.split("^"); 
                    results.push(getTextWithBoldTitle(descArray[0] + ": ", descArray[1], "-", [10, 0, 0, 0]));
                })
            });
        return results;
    }

    const getFociDetails = () => {

        const focusLevelSort = (a: FocusLevel, b: FocusLevel) => {
            if (a.focus < b.focus) {
                return -1;
            } else {
                if (a.focus > b.focus) {
                    return 1;
                } else {
                    if (a.level < b.level) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
            }
        }

        let prevFoci: string[] = [];

        let results: any[] = [];
        charDerivedStats.focusLevels
            .sort(focusLevelSort)
            .forEach((fl) => {

                const thisFocus = lookups.focuses.find((fd) => fd.focus === fl.focus);
                if (thisFocus) {

                    if (prevFoci.indexOf(fl.focus) === -1) {
                        let desc = thisFocus?.desc;
                        if (thisFocus.focus === "Unique Gift") { desc = "You have an unique gift." }
                        results.push(getTextWithBoldTitle(fl.focus + ": ", removeBar(desc), "-", [0, 5, 0, 0]));
                        // results.push({ text: fl.focus + ": " + thisFocus?.desc, lineHeight: LH, margin: [0, 5, 0, 0] });
                        prevFoci.push(fl.focus);
                    }

                    if (fl.level === 1) {
                        let desc = thisFocus.levels[0].desc;
                        if (thisFocus.focus === "Unique Gift") { desc = char.basicTraits.uniqueGift1; }
                        results.push({ ul: [{ text: [{ text: "Level 1: ", style: "bold", lineHeight, fontSize }, { text: desc, lineHeight, fontSize }] }] });

                        // extra details for an Alien Origin focus:
                        if (thisFocus.focus.indexOf("Alien -") !== -1) {

                            const convertSkillName = (skill: string | undefined) => {
                                return skill === "AnyCombatSkill" ? "Punch/Shoot/Stab" : skill;
                            }

                            const selectedFocusData = lookups.focuses.find((fd) => fd.focus === thisFocus.focus);
                            if (selectedFocusData) {
                                if (selectedFocusData?.benefits) {
                                    selectedFocusData?.benefits.forEach((b) => {
                                        const theBenefit = lookups.alienBenefits.find((ben) => ben.id === b.benefit);
                                        if (theBenefit) {

                                            const comments: string[] = [];
                                            if (theBenefit?.benefitMechanicDesc !== "") {
                                                if (theBenefit?.isOriginSkill) {
                                                    comments.push("Gains a free skill level in " + convertSkillName(b.originSkill));
                                                } else if (theBenefit.isStrongAttribute) {
                                                    if (b.attributeModifierBonusIndex) {
                                                        comments.push("Gains +1 to " + attributeLongNames[b.attributeModifierBonusIndex] + " modifier");
                                                    }
                                                } else {
                                                    comments.push(theBenefit?.benefitMechanicDesc);
                                                }
                                            }
                                            if (b.note) (comments.push(b.note));

                                            let majorMinor = b.isMajor ? "Major" : "Minor";

                                            results.push({ ul: [{ text: [{ text: theBenefit.benefitTitle + " (" + majorMinor + "): ", style: "bold", lineHeight, fontSize }, { text: comments.join("; "), lineHeight, fontSize }] }] });

                                        }
                                    })
                                }
                            }

                            if (thisFocus.flaws) {
                                results.push({ ul: [{ text: [{ text: "Flaws/Drawbacks: ", style: "bold", lineHeight, fontSize }, { text: thisFocus.flaws, lineHeight, fontSize }] }] });
                            }

                        }

                    }
                    if (fl.level === 2) {
                        let desc = thisFocus.levels[1].desc;
                        if (thisFocus.focus === "Unique Gift") { desc = char.basicTraits.uniqueGift2; }
                        results.push({ ul: [{ text: [{ text: "Level 2: ", style: "bold", lineHeight, fontSize }, { text: desc, lineHeight, fontSize }] }] });
                    }
                }
            })

        return results;
    }

    const isPsychic = getIsPsychic();
    const isWildTalent = getIsWildTalent();

    const getPsiSkillsDetails = () => {

        let results: any[] = [];

        const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);

        charDerivedStats.skillLevels.sort((a, b) => a.skill > b.skill ? 1 : -1).forEach((sl) => {
            const thisPsySkill = allPsychicSkills.find((ps) => ps.skill === sl.skill);
            if (thisPsySkill) {
                if (sl.level !== null) {

                    const thisSkillData = lookups.psychicTechniques.find((pt) => pt.skill === sl.skill);
                    if (thisSkillData) {
                        let thisSkill = sl.skill + "-" + (sl.level - 1);

                        if (props.charTraits.print.psychicDisciplinesDesc) {

                            let intro = "";
                            thisSkillData.intro.forEach((i) => {
                                intro = intro + i.paragraphs.join(" * ");
                            })

                            let introParagraphs: any[] = [];

                            introParagraphs.push(getTextWithBoldTitle(thisSkill + ": ", thisPsySkill.desc, "-"));

                            thisSkillData.intro.forEach((i) => {
                                if (i.header) {
                                    introParagraphs.push(getTextWithBoldTitle(i.header + ":", "", ""));
                                }
                                i.paragraphs.forEach(((p) => {
                                    introParagraphs.push({ text: "    " + p, preserveLeadingSpaces: true })
                                }))
                            })

                            results.push(introParagraphs);
                        }

                        // Core technique levels:
                        let coreTechniques: any[] = [];
                        charDerivedStats.psychicTechniqueLevels.filter((tl) => tl.skill === sl.skill && tl.level === 0).sort(techniqueSort).forEach((tl) => {

                            const coreTechniqueName = thisSkillData.coreTechnique.name;
                            const coreTechniqueDesc: any[] = [];
                            coreTechniqueDesc.push(getTextWithBoldTitle("Core Technique - " + coreTechniqueName + ": ", "", ""));

                            if (props.charTraits.print.psychicTechniquesDesc) {
                                thisSkillData.coreTechnique.description.forEach((ct) => {
                                    coreTechniqueDesc.push({ text: "    " + ct, preserveLeadingSpaces: true })
                                })
                            }

                            let coreTechniqueLevels: any[] = [];
                            if (sl.level) {
                                for (let x = 0; x < sl.level; x++) {
                                    coreTechniqueLevels.push(getTextWithBoldTitle("Level-" + x + ": ", thisSkillData.coreTechnique.levels[x].description, "-"));
                                }
                            }
                            coreTechniqueDesc.push({ ul: coreTechniqueLevels });

                            coreTechniques.push(coreTechniqueDesc);
                        });

                        results.push({ ul: coreTechniques, margin: [0, 5, 0, 5] });

                        // Additional technique levels:
                        let otherTechniques: any[] = [];
                        charDerivedStats.psychicTechniqueLevels.filter((tl) => tl.skill === sl.skill && tl.level > 0).sort(techniqueSort).forEach((tl) => {

                            const techniqueDesc: any[] = [];
                            const thisTechnique = thisSkillData.techniques.find((sd) => sd.name === tl.technique && sd.level === tl.level);
                            if (thisTechnique) {
                                techniqueDesc.push({ text: thisTechnique.name + "-" + thisTechnique.level + ": ", style: "bold" });
                                thisTechnique.paragraphs.forEach((p) => {
                                    techniqueDesc.push({ text: p, lineHeight, fontSize });
                                })
                            }

                            otherTechniques.push(techniqueDesc);
                        });

                        if (otherTechniques.length > 0) {
                            results.push({ ul: [getTextWithBoldTitle("Other Techniques:", "", ""), { ul: otherTechniques, margin: [0, 5, 0, 0] }], margin: [0, 5, 0, 5] });
                        }

                    }
                }
            }
        });
        return results;
    }

    const getWildTalentPsiSkillsDetails = () => {

        let results: any[] = [];

        const wildTalentPsiSkill = props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline;

        // Show the psi skill details.

        const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);

        const thisPsySkill = allPsychicSkills.find((ps) => ps.skill === wildTalentPsiSkill);
        const thisSkillData = lookups.psychicTechniques.find((pt) => pt.skill === wildTalentPsiSkill);

        if (thisPsySkill) {
            if (thisSkillData) {
                let thisSkill = wildTalentPsiSkill;

                let intro = "";
                thisSkillData.intro.forEach((i) => {
                    intro = intro + i.paragraphs.join(" * ");
                })

                let introParagraphs: any[] = [];

                introParagraphs.push(getTextWithBoldTitle(thisSkill + ": ", thisPsySkill.desc, "-"));

                if (props.charTraits.print.psychicDisciplinesDesc) {
                    thisSkillData.intro.forEach((i) => {
                        if (i.header) {
                            introParagraphs.push(getTextWithBoldTitle(i.header + ":", "", ""));
                        }
                        i.paragraphs.forEach(((p) => {
                            introParagraphs.push({ text: "    " + p, preserveLeadingSpaces: true })
                        }))
                    })
                }

                results.push(introParagraphs);
            }
        }

        // Display the character's techniques.

        const techniques: any[] = [];
        charDerivedStats.psychicTechniqueLevels.forEach((thisTL) => {
            if (thisTL.level === 0) {

                if (thisSkillData) {
                    const coreTechniqueName = thisSkillData.coreTechnique.name;
                    const coreTechniqueDesc: any[] = [];
                    coreTechniqueDesc.push(getTextWithBoldTitle("Core Technique: " + coreTechniqueName + ": ", "", ""));

                    if (props.charTraits.print.psychicTechniquesDesc) {
                        thisSkillData.coreTechnique.description.forEach((ct) => {
                            coreTechniqueDesc.push({ text: "    " + ct, preserveLeadingSpaces: true })
                        })
                    }

                    let coreTechniqueLevels: any[] = [];
                    coreTechniqueLevels.push(getTextWithBoldTitle("Level-" + 0 + ": ", thisSkillData.coreTechnique.levels[0].description, "-"));

                    coreTechniqueDesc.push({ ul: coreTechniqueLevels });
                    techniques.push(coreTechniqueDesc);
                }

            }

            if (thisTL.level > 0) {

                if (thisSkillData) {


                    const techniqueDesc: any[] = [];

                    const thisTechnique = thisSkillData.techniques.find((sd) => sd.name === thisTL.technique && sd.level === thisTL.level);
                    if (thisTechnique) {
                        techniqueDesc.push({ text: thisTechnique.name + "-" + thisTechnique.level + ": ", style: "bold" });
                        thisTechnique.paragraphs.forEach((p) => {
                            techniqueDesc.push({ text: p, lineHeight, fontSize });
                        })
                    }

                    techniques.push(techniqueDesc);
                }
            }

        })

        results.push({ ul: [{ text: "Psychic Abilities:", style: "bold" }, { ul: techniques }] });

        return results;

    }

    const getPsiRefinementNote = () => {
        let note = "";

        const psychicRefinement = charDerivedStats.psychicTechniqueLevels.find((pt) => pt.skill === "Metapsionics");
        if (psychicRefinement) {

            const metapsionics = charDerivedStats.skillLevels.find((s) => s.skill === "Metapsionics");
            if (metapsionics) {
                if (metapsionics.level && metapsionics.level >= 3) {
                    note = "Psychic Refinement (level-2): +3 save versus any psionic power";
                } else {
                    note = "Psychic Refinement (level-0): +2 save versus any psionic power";
                }
            } else {
                // Wild Psychic Taleent can have the core technique without the skill. 
                note = "Psychic Refinement (level-0): +2 save versus any psionic power";
            }
        }

        if (note !== "") {
            return { text: note, style: "small" }
        }
    }


    const getEquipmentDetails = () => {
        const eq: any[] = [];

        props.charTraits.gear.equipment.sort(sortItems).forEach((e) => {
            let enc: any = e.enc;
            if (e.enc.toFixed(2) === "0.33") { enc = "1 for 3" }
            if (e.enc === -1) { enc = " -" };

            let name = e.name;
            if (e.customName) { name = e.customName; }

            let desc = e.desc;

            if (e.mods !== undefined) {
                desc = desc + "; Mods: ";
                const modDesc: string[] = [];
                e.mods.forEach((m) => {
                    const theMod = lookups.gearMods.find((mod) => mod.id === m.id);
                    if (theMod) {
                        let theDesc = theMod.name;
                        if (m.quantity > 1) {
                            theDesc = theDesc + " x " + m.quantity;
                        }
                        theDesc = theDesc + " (" + theMod.shortDesc + ")";
                        modDesc.push(theDesc);
                    }
                })
                desc = desc + modDesc.join("; ");
            }

            if (e.customNotes !== undefined) { desc = desc + ". " + e.customNotes; }

            eq.push(getTextWithBoldTitle(name + ": ", desc + " (enc. " + enc + ")", "-"));
        })

        return { ul: eq };
    }

    const getSkillsDetails = () => {

        const convertSkillLevel = (s: any) => {
            if (s === null) { return "Untrained" };
            return "Level " + (s - 1);
        }

        const skills: any[] = [];

        charDerivedStats.skillLevels.forEach((s) => {
            const allNonPsychicSkills = getSkillsByNotType("Psychic", lookups.skills);
            const thisSkill = allNonPsychicSkills.find((ls) => s.skill === ls.skill);
            if (thisSkill) {
                if (!props.charTraits.print.includeUntrainedSkills) {
                    if (s.level && s.level > 0) {
                        skills.push(getTextWithBoldTitle(s.skill + " (" + convertSkillLevel(s.level) + "): ", thisSkill.desc, "-"));
                    }
                } else {
                    skills.push(getTextWithBoldTitle(s.skill + " (" + convertSkillLevel(s.level) + "): ", thisSkill.desc, "-"));
                }
            }
        })
        return { ul: skills };
    }


    const getStyles = () => {
        const styles = {
            heading: {
                fontSize: fontSize * 1.5,
                lineHeight: lineHeight,
                bold: true
            },
            subheading: {
                fontSize: 16,
                lineHeight: 1.25
            },
            bold: {
                bold: true
            },
            small: {
                fontSize: 8
            },
            boldMedium: {
                bold: true,
                fontSize: 10,
                lineHeight: 1.5
            },
            medium: {
                fontSize: 10,
                lineHeight: 1.25
            }
        }
        return styles;
    }

    const downloadSheet = (): void => {
        const docDefinition = getDocDefinition();
        pdfMake.createPdf(docDefinition).download(blank(char.basicTraits.name, "UnnamedCharacter") + ".pdf");
    }

    const getDocDefinition = (): any => {
        let text: any[] = []
        text.push(getName());

        const classAndLevel =
        {
            columns: [
                getTextWithBoldTitle("Class: ", getClassName(), "-"),
                getTextWithBoldTitle("Level: ", props.charTraits.level.toString(), "-")
            ]
        };
        text.push(classAndLevel);

        const originAndBackground =
        {
            columns: [
                getTextWithBoldTitle("Origin: ", char.basicTraits.origin, "-"),
                getTextWithBoldTitle("Background: ", char.background.backgroundName, "-")
            ]
        };
        text.push(originAndBackground);

        const homeworldAndLanguage =
        {
            columns: [
                getTextWithBoldTitle("Homeworld: ", char.background.homeworld, "-"),
                getTextWithBoldTitle("Languages: ", char.background.languages, "-")
            ]
        };
        text.push(homeworldAndLanguage);

        const goal = getTextWithBoldTitle("Goal: ", char.basicTraits.goal, "-");
        text.push(goal);

        text.push({ text: ' ', lineHeight, fontSize });

        const hpRerollPlural = props.charTraits.levelOne.hitPointRerolls === 1 ? "" : "s";

        let totalHitPointRerolls = props.charTraits.levelOne.hitPointRerolls;
        totalHitPointRerolls += props.charTraits.levels.reduce((accum, level) => accum + level.hitPointRerolls, 0);
        const hitPointRerolls = totalHitPointRerolls > 0 ? " (" + totalHitPointRerolls + " reroll" + hpRerollPlural + ")" : "";

        const finalLevelHP = charDerivedStats.hitPointsLevels.find((hp) => hp.level === char.level);
        let HP: any = null;
        if (finalLevelHP) {
            HP = getTextWithBoldTitle("HP ", finalLevelHP.totalHitPointsAtLevel.toString() + hitPointRerolls, "-");
        }

        const AC_3 = getTextWithBoldTitle("AC ", charDerivedStats.armorClassLevels.ac.toString() + " vs TL3 weapons", "-");
        const AC_4 = getTextWithBoldTitle("AC ", charDerivedStats.armorClassLevelsVsTL4.ac + " vs TL4+ melee weapons & firearms", "-");

        const Move = getTextWithBoldTitle("Move ", charDerivedStats.encumbranceLevelBaseMove + "m (" + charDerivedStats.encumbranceLevel + ")", "-");
        const BaseAttack = getTextWithBoldTitle("Base Attack ", "+" + charDerivedStats.baseAttackLevels.baseAttack.toString(), "-");
        const SystemStrain = getTextWithBoldTitle("System Strain ", "max " + charDerivedStats.systemStrainMaximum.strain + ", permanent " + charDerivedStats.systemStrainPermanent.strain, "-");

        const attributesAndOtherStats =
        {
            columns: [
                getAttributesAsTable(),
                {
                    stack: [
                        HP,
                        AC_3,
                        AC_4,
                        Move,
                        BaseAttack,
                        SystemStrain
                    ],
                    width: "50%"
                },
                {
                    stack: [
                        { text: 'Saving Throws:', style: "bold", lineHeight, fontSize },
                        { text: "Physical " + charDerivedStats.savingThrowLevels.physical.score, lineHeight, fontSize },
                        { text: "Evasion " + charDerivedStats.savingThrowLevels.evasion.score, lineHeight, fontSize },
                        { text: "Mental " + charDerivedStats.savingThrowLevels.mental.score, lineHeight, fontSize },
                        getPsiRefinementNote(),
                    ],
                    width: "25%"
                }
            ]
        };
        text.push(attributesAndOtherStats);

        const attributeMethodText = attributeMethod(char.attributeTraits.method) + " " + getRerolls(props.charTraits);

        text.push({ text: attributeMethodText, lineHeight, fontSize: Math.round(fontSize * 0.75) });

        text.push({ text: ' ', lineHeight, fontSize });

        const classAbilities = getTextWithBoldTitle("Class Abilities: ", getClassAbilities(), "-");
        text.push(classAbilities);

        const skills = getTextWithBoldTitle("Skills: ", getSkills(), "-");
        text.push(skills);

        const foci = getTextWithBoldTitle("Foci: ", getFoci(), "-");
        text.push(foci);

        const isVIRobot = charDerivedStats.focusLevels.find((fl) => fl.focus.indexOf("VI ") !== -1);
        if (isVIRobot) {
            const viText = getTextWithBoldTitle("Virtual Intelligence: ", viDescription(), "-");
            text.push(viText);
        }

        if (isPsychic || isWildTalent) {

            if (isPsychic) {
                const psiTechniquesAndDisciplines = getTextWithBoldTitle("Psychic Disciplines & Techniques: ", getPsychicDisciplinesAndTechniques(), "-");
                text.push(psiTechniquesAndDisciplines);
            }

            if (isWildTalent) {
                const wildTalentDisciplines = getTextWithBoldTitle("Psychic Disciplines: ", getWildTalentTechniques(), "-");
                text.push(wildTalentDisciplines);
            }

            const effort = getTextWithBoldTitle("Effort: ", getEffort().toString(), "-");
            text.push(effort);
        }

        text.push({ text: ' ', lineHeight, fontSize });

        text.push({ text: 'Equipment', style: "bold", lineHeight, fontSize: fontSize * 1.25 });

        const readied = getTextWithBoldTitle("Readied: ", getEquipment("Readied"), "-");
        const stowed = getTextWithBoldTitle("Stowed: ", getEquipment("Stowed"), "-");

        let builtIn: any = null;
        const hasCyberware = props.charTraits.gear.equipment.find((g) => g.type === "Cyberware");
        if (hasCyberware) {
            builtIn = getTextWithBoldTitle("Cyberware: ", getEquipment("Cyberware"), "-");
        }

        const cached = getTextWithBoldTitle("Cached: ", getEquipment("Cached"), "-");

        const credits = getTextWithBoldTitle("Credits: ", formatCredits(charDerivedStats.creditsAvailable), "-");

        text.push({ ul: [readied, stowed, builtIn, cached, credits] });

        text.push({ text: ' ', lineHeight, fontSize });

        const readiedEnc = "Readied Items: " + charDerivedStats.readiedEquipmentEncumbrance + " (Unencumbered: up to " + charDerivedStats.readiedUnencumberedLimit + ", Light Enc: up to " + charDerivedStats.readiedLightlyEncumberedLimit + ", Heavy Enc.: up to " + charDerivedStats.readiedHeavilyEncumberedLimit + ")";
        const stowedEnc = "Stowed Items: " + charDerivedStats.stowedEquipmentEncumbrance + " (Unencumbered: up to " + charDerivedStats.stowedUnencumberedLimit + ", Light Enc: up to " + charDerivedStats.stowedLightlyEncumberedLimit + ", Heavy Enc.: up to  " + charDerivedStats.stowedHeavilyEncumberedLimit + ")";

        text.push({ stack: [readiedEnc, stowedEnc], lineHeight, fontSize });

        const armorStats = getArmorStats();
        if (armorStats.length > 0) {
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Armor', style: "bold", lineHeight, fontSize });
            text.push({ ul: armorStats, lineHeight, fontSize });
        };

        const rangedWeaponStats = getRangedWeaponStats();
        if (rangedWeaponStats.length > 0) {
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Ranged Weapons', style: "bold", lineHeight, fontSize });
            text.push({ ul: rangedWeaponStats, lineHeight, fontSize });

        };

        const meleeWeaponStats = getMeleeWeaponStats();
        if (meleeWeaponStats.length > 0) {
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Melee Weapons', style: "bold", lineHeight, fontSize });
            text.push({ ul: meleeWeaponStats, lineHeight, fontSize });
        };

        const vehicleStats = getVehicleStats();
        if (vehicleStats.length > 0) {
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Vehicles', style: "bold", lineHeight, fontSize });
            text.push({ ul: vehicleStats, lineHeight, fontSize });
        };

        const droneStats = getDroneStats();
        if (droneStats.length > 0) {
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Drones', style: "bold", lineHeight, fontSize });
            text.push({ ul: droneStats, lineHeight, fontSize });
        };

        const robotStats = getRobotStats();
        if (robotStats.length > 0) {
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Robots', style: "bold", lineHeight, fontSize });
            text.push({ ul: robotStats, lineHeight, fontSize });
        };

        if (props.charTraits.print.foci) {
            const fociDetails = getFociDetails();
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Foci', style: "bold", fontSize: fontSize * 1.25, lineHeight });
            text.push({ ul: fociDetails, lineHeight, fontSize });
            if (charDerivedStats.focusLevels.length === 0) {
                text.push({ text: '-', lineHeight, fontSize });
            }
        }

        if (props.charTraits.print.psychicDisciplines) {
            if (isPsychic) {
                const psiDetails = getPsiSkillsDetails();
                if (psiDetails.length > 0) {
                    text.push({ text: ' ', lineHeight, fontSize });
                    text.push({ text: 'Psychic Disciplines & Techniques', style: "bold", fontSize: fontSize * 1.25, lineHeight });
                    text.push({ ul: psiDetails, lineHeight, fontSize, style: "ul" });
                    if (psiDetails.length === 0) {
                        text.push({ text: '-', lineHeight, fontSize });
                    }
                }
            }
        }

        if (props.charTraits.print.psychicDisciplines) {
            if (isWildTalent) {
                const wildTalentDetails = getWildTalentPsiSkillsDetails();
                if (wildTalentDetails.length > 0) {
                    text.push({ text: ' ', lineHeight, fontSize });
                    text.push({ text: 'Wild Talent Psychic Abilities', style: "bold", fontSize: fontSize * 1.25, lineHeight });
                    text.push({ ul: wildTalentDetails, lineHeight, fontSize, style: "ul" });
                    if (wildTalentDetails.length === 0) {
                        text.push({ text: '-', lineHeight, fontSize });
                    }
                }
            }
        }

        if (props.charTraits.print.equipment) {
            if (props.charTraits.gear.equipment.length > 0) {
                const equipment = getEquipmentDetails();
                text.push({ text: ' ', lineHeight, fontSize });
                text.push({ text: 'Equipment Descriptions', style: "bold", fontSize: fontSize * 1.25, lineHeight });
                text.push(equipment);
            }
        }

        if (props.charTraits.print.skills) {
            const skillsDesc = getSkillsDetails();
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Skills', style: "bold", fontSize: fontSize * 1.25, lineHeight });
            text.push(skillsDesc);
        }

        if (props.charTraits.print.notes) {
            text.push({ text: ' ', lineHeight, fontSize });
            text.push({ text: 'Notes', style: "bold", fontSize: fontSize * 1.25, lineHeight });
            const notesInPdf = htmlToPdfmake(props.charTraits.notes);
            text.push(notesInPdf);
        }

        // Return PDF
        const styles = getStyles();

        const docDefinition = {
            content: text,
            info: {
                title: blank(char.basicTraits.name, "UnnamedCharacter"),
            },
            styles,
            defaultStyle: {
                lineHeight: lineHeight,
                fontSize: fontSize
            }
        };

        return docDefinition;
    }

    const docDefinition = getDocDefinition();

    useEffect(() => {

        const doc: any = pdfMake.createPdf(docDefinition);
        doc.getDataUrl((dataUrl: any) => {
            const theIframe = document.querySelector('iframe');
            if (!theIframe) {
                const targetElement = document.querySelector('#iframeContainer');
                const iframe = document.createElement('iframe');
                iframe.src = dataUrl;
                iframe.setAttribute("class", "sheet");
                if (targetElement) { targetElement.appendChild(iframe); }
            } else {
                const iframe = document.querySelector('iframe');
                if (iframe) {
                    iframe.src = dataUrl;
                }
            }
        });

    }, [props.charTraits, docDefinition])

    const browser = detect();

    // handle the case where we don't detect the browser
    let chromeWarningDisplay = { display: "none" };
    if (browser) {
        if (browser.name.toLowerCase() === "chrome") {
            chromeWarningDisplay = { display: "block" };
        }
    }

    const setPrintCheckbox = (fieldName: string) => {
        const printTraits: any = { ...props.charTraits.print };
        printTraits[fieldName] = !printTraits[fieldName];
        props.onSetPrintTraits(printTraits);
    }

    return (
        <div>

            {!props.charTraits.isValid &&
                <div className="mb-2">
                    <ValidationAlert msg="Character has validation errors." />
                </div>
            }

            <div style={{ marginBottom: "15px" }}>
                <div className="form-check">
                    <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.foci} onChange={() => setPrintCheckbox("foci")} className="form-check-input"></input> Foci</label>
                </div>
                {isPsychic &&
                    <>
                        <div className="form-check">
                            <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.psychicDisciplines} onChange={() => setPrintCheckbox("psychicDisciplines")} className="form-check-input"></input> Psychic Disciplines</label>
                        </div>
                        {props.charTraits.print.psychicDisciplines &&
                            <>
                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                    <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.psychicDisciplinesDesc} onChange={() => setPrintCheckbox("psychicDisciplinesDesc")} className="form-check-input"></input> Psychic Disciplines Descriptions</label>
                                </div>
                                <div className="form-check" style={{ marginLeft: "20px" }}>
                                    <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.psychicTechniquesDesc} onChange={() => setPrintCheckbox("psychicTechniquesDesc")} className="form-check-input"></input> Psychic Techniques Descriptions</label>
                                </div>
                            </>
                        }
                    </>
                }
                <div className="form-check">
                    <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.equipment} onChange={() => setPrintCheckbox("equipment")} className="form-check-input"></input> Equipment</label>
                </div>
                <div className="form-check">
                    <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.skills} onChange={() => setPrintCheckbox("skills")} className="form-check-input"></input> Skills</label>
                </div>
                {
                    props.charTraits.print.skills &&
                    <div className="form-check" style={{ marginLeft: "20px" }}>
                        <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.includeUntrainedSkills} onChange={() => setPrintCheckbox("includeUntrainedSkills")} className="form-check-input"></input> Include Untrained Skills</label>
                    </div>
                }
                <div className="form-check">
                    <label className="form-check-label"><input type="checkbox" checked={props.charTraits.print.notes} onChange={() => setPrintCheckbox("notes")} className="form-check-input"></input> Notes</label>
                </div>

            </div>

            <p><button type="button" onClick={downloadSheet} className="btn btn-primary btn-lg">Download Character Sheet</button></p>

            <div id="iframeContainer"></div>

        </div >
    )
}

export default CharacterDesignSheetScreen;