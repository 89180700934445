import React from "react";
import uniqid from "uniqid";

import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";

import { CharacterTraits } from "../interfaces/CharacterTraits";

import { formatAttack, formatDieRoll, PlusMinus, viDescription } from "../utilities/Utilities";
import { WeaponStat } from "../interfaces/WeaponStat";
import { DieRoll } from "../interfaces/DieRoll";
import { VehicleStat } from "../interfaces/VehicleStat";
import { DroneStat } from "../interfaces/DroneStat";
import { getDroneDescText, getModList } from "../utilities/charMainUtilities";
import { RobotStat } from "../interfaces/RobotStat";
import { useCollapsibleSection } from "../hooks/useCollapsibleSection";

interface IProps {
    charTraits: CharacterTraits;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep10Stats: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "otherStats";
    
    const { sectionClassName, switchDisplay, getCollapseIcon } = useCollapsibleSection(
        props.charTraits.basicTraits.collapsedSections,
        props.onSwitchCollapsed,
        sectionName,
    );

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateBaseAttack(CreationStep.AllSteps);
    charDerivedStats.calculateHitPointsLevels(CreationStep.AllSteps, char.level);
    charDerivedStats.calculateSavingThrows(CreationStep.AllSteps);
    charDerivedStats.calculateEffort(CreationStep.AllSteps);
    charDerivedStats.calculateArmorClassVsPrimitiveWeapons(CreationStep.AllSteps);
    charDerivedStats.calculateArmorClassVsAdvancedWeapons(CreationStep.AllSteps);
    charDerivedStats.calculateRangedWeaponStats(CreationStep.AllSteps, true);
    charDerivedStats.calculateMeleeWeaponStats(CreationStep.AllSteps, true);
    charDerivedStats.calculateVehicleStats(CreationStep.AllSteps, true);
    charDerivedStats.calculateDroneStats(CreationStep.AllSteps, true);
    charDerivedStats.calculateRobotStats(CreationStep.AllSteps, true);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);
    charDerivedStats.calculateIsPsychic();
    charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100, 100);
    charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);
    charDerivedStats.calculateSystemStrain();
    charDerivedStats.calculateTotalModsMaintenance();
    charDerivedStats.calculateMaintenanceScore();

    const getRangedWeaponDesc = (r: WeaponStat) => {
        let d: string[] = [];

        d.push(PlusMinus(r.toHitBonus) + " to hit");
        d.push(formatDieRoll(r.damage) + " dmg");
        d.push("rng " + r.range.join("/"));

        if (r.mag > 0) {
            const formatMag = (mag: number) => mag > 1000 ? "Unlimited" : mag;
            d.push("mag " + formatMag(r.mag));
        }

        if (r.burst) {
            const burstDamage: DieRoll = { numDice: r.damage.numDice, dieSize: r.damage.dieSize, bonus: r.damage.bonus + 2 };
            d.push("burst fire (uses 3 ammo, " + PlusMinus(r.toHitBonus + 2) + " to hit, " + formatDieRoll(burstDamage) + " dmg)");
        }

        if (r.suppress) {
            d.push("can fire to suppress");
        }

        const add1d4DamageOnShootMiss = charDerivedStats.focusLevels.find((fl) => fl.focus === "Gunslinger" && fl.level === 2);
        if (add1d4DamageOnShootMiss) { d.push("1d4 damage on a miss"); }

        const mods = getModList(r);
        if (mods !== "") { d.push(mods) };

        d.push("TL " + r.techLevel);

        return <li key={uniqid()}><b>{r.name}:</b> {d.join(", ")}</li>;

    }

    const getMeleeWeaponDesc = (r: WeaponStat) => {
        let d: string[] = [];

        const isPunchWeapon = r.name === "Unarmed Attack" || r.name === "Natural Weapons (Alien)";

        d.push(PlusMinus(r.toHitBonus) + " to hit");
        d.push(formatDieRoll(r.damage) + " dmg");

        if (r.shockDamage > 0) {
            d.push("Shock " + r.shockDamage + "pt/AC" + r.shockAC);
        }

        if (isPunchWeapon) {
            const add1d6DamageOnMeleeMiss = charDerivedStats.focusLevels.find((fl) => fl.focus === "Unarmed Combatant" && fl.level === 2);
            if (add1d6DamageOnMeleeMiss) { d.push("1d6 damage on a miss (plus any Shock dmg)"); }
        }

        if (!isPunchWeapon) {
            const Armsman2 = charDerivedStats.focusLevels.find((fl) => fl.focus === "Armsman" && fl.level === 2);
            if (Armsman2) { d.push("1d4 dmg on a miss in melee (plus any Shock dmg)"); }
        }

        const mods = getModList(r);
        if (mods !== "") { d.push(mods) };

        d.push("TL " + r.techLevel);

        return <li key={uniqid()}><b>{r.name}:</b> {d.join(", ")}</li>;
    }

    const getVehicleDesc = (v: VehicleStat) => {
        let d: string[] = [];

        const isNull = (x: any) => {
            if (x !== undefined) { return x; }
            return "?";
        }

        const isSpecial = (armor: string) => {
            if (armor === "100") { return "Special"; }
            return armor;
        }

        d.push("Speed " + isNull(v.speed));
        d.push("Armor " + isNull(isSpecial(v.armor.toString())));
        d.push("HP " + isNull(v.hp));
        d.push("Crew " + isNull(v.crew));
        d.push("Tonnage " + isNull(v.tonnage));

        // v.notes.forEach((n) => d.push(n));

        const mods = getModList(v);
        if (mods !== "") { d.push(mods) };

        d.push("TL " + v.techLevel);

        return <li key={uniqid()}><b>{v.name}:</b> {d.join(", ")}</li>;

    }

    const getDroneDesc = (v: DroneStat) => {

        const droneDesc = getDroneDescText(v);

        return <li key={uniqid()}><b>{v.name}:</b> {droneDesc.join(", ")}</li>;
    }

    const getRobotDesc = (r: RobotStat) => {
        let d: string[] = [];

        const isNull = (x: any) => {
            if (x !== undefined) { return x; }
            return "?";
        }

        const attDmg = isNull(formatAttack(r.attackBonus)) + "/" + isNull(formatDieRoll(r.damage));

        d.push("HD " + isNull(r.hitDice));
        d.push("AC " + isNull(r.ac));
        d.push("Atk " + attDmg);
        d.push("Move " + isNull(r.move) + "m");
        d.push("Skill +" + isNull(r.skill));
        d.push("Save " + isNull(r.save) + "+");

        // v.notes.forEach((n) => d.push(n));

        const mods = getModList(r);
        if (mods !== "") { d.push(mods) };

        d.push("TL " + r.techLevel);

        return <li key={uniqid()}><b>{r.name}:</b> {d.join(", ")}</li>;

    }

    const getTotalHP = () => {
        const finalLevelHP = charDerivedStats.hitPointsLevels.find((hp) => hp.level === char.level);
        let HP: any = 0;
        if (finalLevelHP) {
            HP = finalLevelHP.totalHitPointsAtLevel;
        }
        return HP;
    }

    const canMountWeapons = () => {
        let canMount = false;
        charDerivedStats.vehicleStats.forEach((vs) => {
            vs.notes.forEach((n) => {
                if (n.indexOf("mount") !== -1) { canMount = true; }
            })
        })
        return canMount;
    }

    const isVIRobot = () => {
        return charDerivedStats.focusLevels.find((fl) => fl.focus.indexOf("VI ") !== -1);
    }

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Other Statistics {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                {isVIRobot() &&
                    <div className="mb-2">
                        <b>VI (Virtual Intelligence):</b> {viDescription()}
                    </div>
                }

                <div className="mb-2">
                    <b>Hit Points:</b> {getTotalHP()}
                </div>

                <div className="mb-2">
                    <b>Base Attack Bonus:</b> +{charDerivedStats.baseAttackLevels.baseAttack}
                </div>

                <div className="mb-2">
                    <b>Saving Throws:</b>
                    <ul className="mb-0">
                        <li>Physical: {charDerivedStats.savingThrowLevels.physical.score}</li>
                        <li>Mental: {charDerivedStats.savingThrowLevels.mental.score}</li>
                        <li>Evasion: {charDerivedStats.savingThrowLevels.evasion.score}</li>
                        {charDerivedStats.savingThrowLevels.notes.length > 0 &&
                            <>
                                {charDerivedStats.savingThrowLevels.notes.map((n: string) => <li key={uniqid()}>{n}</li>)}
                            </>
                        }
                    </ul>
                </div>

                {charDerivedStats.isPsychic && charDerivedStats.effortLevels.effort > 0 &&
                    <div className="mb-2">
                        <b>Effort:</b> {charDerivedStats.effortLevels.effort}
                    </div>
                }

                <div className="mb-2">
                    <b>Armor Class:</b>
                    {charDerivedStats.armorClassLevels.ac !== charDerivedStats.armorClassLevelsVsTL4.ac &&
                        <ul>
                            <li><b>AC {charDerivedStats.armorClassLevels.ac}</b> vs low-tech (TL3 or lower) weapons</li>
                            <li><b>AC {charDerivedStats.armorClassLevelsVsTL4.ac}</b> vs high-tech (TL4 or higher) melee weapons and all firearms</li>
                        </ul>
                    }
                    {charDerivedStats.armorClassLevels.ac === charDerivedStats.armorClassLevelsVsTL4.ac &&
                        <ul>
                            <li><b>AC {charDerivedStats.armorClassLevels.ac}</b> vs all weapons</li>
                        </ul>
                    }
                </div>

                {charDerivedStats.rangedWeaponStats.length > 0 &&
                    <div className="mb-2">
                        <b>Ranged Weapons:</b>
                        <ul>
                            {
                                charDerivedStats.rangedWeaponStats.sort((a, b) => a.name < b.name ? -1 : 1).map((r) => getRangedWeaponDesc(r))
                            }
                        </ul>
                    </div>
                }

                {charDerivedStats.meleeWeaponStats.length > 0 &&
                    <div className="mb-2">
                        <b>Melee Weapons:</b>
                        <ul>
                            {
                                charDerivedStats.meleeWeaponStats.sort((a, b) => a.name < b.name ? -1 : 1).map((r) => getMeleeWeaponDesc(r))
                            }
                        </ul>
                    </div>
                }

                {charDerivedStats.vehicleStats.length > 0 &&
                    <div className="mb-2">
                        <b>Vehicles:</b>
                        <ul className="mb-1">
                            {
                                charDerivedStats.vehicleStats.sort((a, b) => a.name < b.name ? -1 : 1).map((r) => getVehicleDesc(r))
                            }
                        </ul>
                        {canMountWeapons() &&
                            <div className="small">One heavy weapon may be mounted in place of two standard weapons.</div>
                        }
                    </div>
                }

                {charDerivedStats.robotStats.length > 0 &&
                    <div className="mb-2">
                        <b>Robots:</b>
                        <ul className="mb-1">
                            {
                                charDerivedStats.robotStats.sort((a, b) => a.name < b.name ? -1 : 1).map((r) => getRobotDesc(r))
                            }
                        </ul>
                    </div>
                }

                {charDerivedStats.droneStats.length > 0 &&
                    <div className="mb-2">
                        <b>Drones:</b>
                        <ul className="mb-1">
                            {
                                charDerivedStats.droneStats.sort((a, b) => a.name < b.name ? -1 : 1).map((r) => getDroneDesc(r))
                            }
                        </ul>
                    </div>
                }

                <div className="mb-2">
                    <b>Equipment Mods Maintenance:</b>
                    <ul>
                        <li>Total Mods Requiring Maintenance: {charDerivedStats.totalModsMaintenance.toString()}</li>
                        <li>Maintenance Score: {charDerivedStats.maintenanceScore.toString()}</li>
                    </ul>

                </div>

                <div className="mb-2">
                    <b>System Strain:</b>
                    <ul className="mb-0">
                        <li>Maximum: {charDerivedStats.systemStrainMaximum.strain}</li>
                        <li>Permanent: {charDerivedStats.systemStrainPermanent.strain}</li>
                    </ul>
                </div>

            </div>
        </div>
    );

}

export default CharacterDesignStep10Stats;