import React from "react";
import { faChevronCircleUp, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function useCollapsibleSection(
    collapsedSections: string[],
    onSwitchCollapsed: (sectionName: string) => void,
    sectionName: string,
) {
    const isCollapsed = collapsedSections.indexOf(sectionName) !== -1;
    const sectionClassName = isCollapsed
        ? "sectionCollapsed"
        : "sectionUncollapsed";

    function switchDisplay() {
        onSwitchCollapsed(sectionName);
    }

    function getCollapseIcon() {
        return (
            <FontAwesomeIcon
                icon={isCollapsed ? faChevronCircleUp : faChevronCircleDown}
                title="text-info"
                className="floatRight"
            ></FontAwesomeIcon>
        );
    }

    return { sectionClassName, switchDisplay, getCollapseIcon };
}

export { useCollapsibleSection };
