import React, { useEffect, useState } from "react";
import { getAuth, User, onAuthStateChanged } from "firebase/auth";
import app from "./firebase"

// see https://medium.com/wesionary-team/react-firebase-authentication-with-context-api-a770975f33cf

export type ContextProps = {
    user: User | null;
    authenticated: boolean;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    isAdmin: boolean;
    loadingAuthState: boolean;
};

const initialContext: ContextProps = {
    user: null,
    authenticated: false,
    setUser: () => { },
    isAdmin: false,
    loadingAuthState: true,
};

export const AuthContext = React.createContext(initialContext);

export const AuthProvider = ({ children }: any): any => {

    const auth = getAuth(app); // Get Firebase Auth instance

    const [user, setUser] = useState<User | null>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            setIsAdmin(false);

            if (currentUser) {
                try {
                    const idTokenResult = await currentUser.getIdTokenResult();
                    if (idTokenResult.claims.Admin) {
                        setIsAdmin(true);
                    }
                } catch (error) {
                    console.error("Error fetching ID token:", error);
                }
            }

            setLoadingAuthState(false);
        });

        return () => unsubscribe(); // Cleanup subscription
    }, [auth]);

    return (
        <AuthContext.Provider
            value={{
                user,
                authenticated: user !== null,
                setUser,
                isAdmin,
                loadingAuthState
            }}>
            {children}
        </AuthContext.Provider>
    );
}