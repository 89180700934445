import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import app from "../firebase"
import { AuthContext } from "../Auth";
import { getAuth, updatePassword } from "firebase/auth";

const AccountChangePassword: React.FunctionComponent<{}> = () => {

    const { t } = useTranslation();

    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordSet, setPasswordSet] = useState(false);

    const [error, setError] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (values: any) => {
        setError("");

        if (values.password1 !== values.password2) {
            setPasswordsMatch(false);
            return;
        }

        setPasswordsMatch(true);
        const auth = getAuth(app);
        const currentUser = auth.currentUser;

        if (currentUser) {
            try {
                await updatePassword(currentUser, values.password1);
                setPasswordSet(true);
                setTimeout(() => setPasswordSet(false), 5000);
            } catch (error: any) {
                setPasswordSet(false);
                if (error.code) {
                    switch (error.code) {
                        case "auth/requires-recent-login":
                            setError(t("changePwd.logInAgain"));
                            break;
                        case "auth/weak-password":
                            setError(t("changePwd.weakPassword"));
                            break;
                        default:
                            setError(t("changePwd.error") + " " + error.message);
                    }
                } else {
                    setError(t("changePwd.unknownError"));
                }
            }
        }
    };

    return (
        <div className="card">
            <div className="card-header">
                <h4>{t("changePwd.changePassword")}</h4>
            </div>
            <div className="card-body">

                {passwordSet &&
                    <div className="alert alert-primary" role="alert">
                        {t("changePwd.passwordChanged")}
                    </div>
                }

                {error &&
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                }

                <form action="#!" method="get" onSubmit={handleSubmit(onSubmit)}>

                    {error &&
                        <div className="alert alert-danger" role="alert">{error}</div>
                    }

                    <div className="form-group">
                        <label htmlFor="password1">{t("changePwd.newPassword")}</label>
                        <input type="password" defaultValue="" className="form-control textMid" ref={register({ required: true })} id="password1" name="password1" />
                        {errors.password1 &&
                            <div className="text-danger">{t("changePwd.fieldReq")}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="password2">{t("changePwd.confirmPassword")}</label>
                        <input type="password" defaultValue="" className="form-control textMid" ref={register({ required: true })} id="password2" name="password2" />
                        {errors.password2 &&
                            <div className="text-danger">{t("changePwd.fieldReq")}</div>
                        }
                        {!passwordsMatch &&
                            <div className="text-danger">{t("changePwd.doNotMatch")}</div>
                        }
                    </div>

                    <div>
                        <button type="submit" className="btn btn-primary">{t("changePwd.changePassword")}</button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default AccountChangePassword; 