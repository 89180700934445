import React from "react";
import { CharacterTraits } from "../interfaces/CharacterTraits";
import { useCollapsibleSection } from "../hooks/useCollapsibleSection";

import 'react-quill-new/dist/quill.snow.css';
import ReactQuill from "react-quill-new";

interface IProps {
    charTraits: CharacterTraits;
    onSetNotes: (notes: string) => void;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep11Notes: React.FunctionComponent<IProps> = (props: IProps) => {

    const sectionName = "notes";
    
    const { sectionClassName, switchDisplay, getCollapseIcon } = useCollapsibleSection(
        props.charTraits.basicTraits.collapsedSections,
        props.onSwitchCollapsed,
        sectionName,
    );

    const onSetNotes = (value: string) => {
        props.onSetNotes(value);
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 
        'link'
    ];

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2>Notes {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                <div style={{ height: "280px" }}>
                    <ReactQuill value={props.charTraits.notes} onChange={onSetNotes} modules={modules} formats={formats} style={{ height: "230px" }} />
                </div>

            </div>
            
        </div>
    );

}

export default CharacterDesignStep11Notes;