import React from "react";

type IProps = {}

const Environment: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <div className="pageBody">
            <h1 className="pageHeading">ENVIRONMENT</h1>

            <p>{process.env.REACT_APP_ENVIRONMENT}</p>
        </div>
    );
}

export default Environment;
