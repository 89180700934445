import React, { useState } from "react";
import { withRouter } from "react-router";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import app from "../firebase";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";

import { isEmail } from "../utilities/emailValidation";

const SignUp: React.FunctionComponent<unknown> = () => {

    const { t } = useTranslation();

    const [signUpState, setSignUpState] = useState("started");
    const [error, setError] = useState("");

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (values: any) => {
        try {
            const auth = getAuth(app);
            const db = getFirestore(app);
    
            const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
            const newUser = userCredential.user;
    
            if (newUser) {
                await sendEmailVerification(newUser);
    
                const userToSave = {
                    displayName: "",
                    email: newUser.email,
                    emailVerified: false,
                    user_id: newUser.uid
                };
    
                await setDoc(doc(db, "users", newUser.uid), userToSave);
                await signOut(auth);
    
                setSignUpState("success");
            }
        } catch (error: any) {
            setSignUpState("failed");
            if (error.code) {
                switch (error.code) {
                    case "auth/email-already-in-use":
                        setError(t("signUp.emailUsed"));
                        break;
                    case "auth/invalid-email":
                        setError(t("signUp.invalidEmail"));
                        break;
                    case "auth/weak-password":
                        setError(t("signUp.weakPassword"));
                        break;
                    default:
                        setError(error.message);
                        break;
                }
            } else {
                setError(error.message);
            }
        }
    };

    return (
        <div className="pageBody">
            <h1 className="pageHeading">{t("signUp.signUp")}</h1>

            <div className="card" style={{ maxWidth: "400px" }}>
                <div className="card-body">

                    {signUpState === "success" &&
                        <div className="alert alert-primary" role="alert">
                            <div className="pb-2">{t("signUp.success")}</div>
                            <div className="pb-2"><b>{t("signUp.emailSent")}</b></div>
                            <div className="pb-2">{t("signUp.pleaseLogIn")}</div>
                            <div><Link className="btn btn-primary" to="/login">{t("signUp.log_in")}</Link></div>
                        </div>
                    }

                    {signUpState !== "success" &&
                        <form action="#!" method="get" onSubmit={handleSubmit(onSubmit)}>

                            <div className="pb-2">Sign up for an account so you can save your characters.</div>

                            {signUpState === "failed" &&
                                <div className="alert alert-danger">{t("signUp.signUpFailed")} {error}</div>
                            }
                            <div className="form-group">
                                <label htmlFor="email">{t("signUp.email")}</label>
                                <input type="email" placeholder={t("signUp.email")} className="form-control textMid" ref={register({ required: true, validate: isEmail })} id="email" name="email" />
                                {(errors.email && errors.email.type === "required") &&
                                    <div className="text-danger">{t("signUp.fieldReq")}</div>
                                }
                                {(errors.email && errors.email.type === "validate") &&
                                    <div className="text-danger">{t("signUp.invalidEmail")}</div>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">{t("signUp.password")}</label>
                                <input type="password" className="form-control textMid" placeholder={t("signUp.password")} ref={register({ required: true })} id="password" name="password" />
                                {errors.password &&
                                    <div className="text-danger">{t("signUp.fieldReq")}</div>
                                }
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">{t("signUp.signUp")}</button>
                            </div>
                        </form>
                    }

                </div>
            </div>
        </div>

    )
};

export default withRouter(SignUp);
