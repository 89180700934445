import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import app from "../../firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import { version } from "../../version";

import { AuthContext } from "../../Auth";

import CharacterDesignMenu from "../../components/CharacterDesignMenu";

import UpdateVersionModal from "../../components/UpdateVersionModal";
import { useEffectHookLoadStarship } from "../../hooks/useEffectHookLoadStarship";
import StarshipDesignScreen from "./StarshipDesignScreen";
import { getInitialStarshipTraits, StarshipTraits } from "../../interfaces/StarshipTraits";
import { SaveNewStarship, UpdateStarship } from "../../data/UpdateStarship";
import Audit from "./Audit";

interface RouteParams {
    id: string
}

const StarshipDesignMain: React.FunctionComponent<{}> = () => {

    const { user: currentUser } = useContext(AuthContext);

    // Get the character Id from the URL route (if any). 
    let { id: IdInQuerystring } = useParams<RouteParams>();

    // State hooks:

    const getInitialShip = () => {
        // check if a ship was saved in local storage while trying out the ship designer
        const savedTraits = localStorage.getItem("tryShipTraits");
        if (savedTraits !== null) {
            const traits: StarshipTraits = JSON.parse(savedTraits) as unknown as StarshipTraits;
            return traits;
        }
        return getInitialStarshipTraits();
    }


    const [isLoading, setIsLoading] = useState(false);
    const [screen, setScreen] = useState("design");
    const [starshipId, setStarshipId] = useState("");
    const [starshipTraits, setStarshipTraits] = useState(getInitialShip());
    const [goToStarshipList, setGoToStarshipList] = useState(false);
    const [showReloadModal, setShowReloadModal] = useState(false);
    const [versionOnServer, setVersionOnServer] = useState("");

    // On first render, check if the code version thas been updated.
    useEffect(() => {
        const fetchVersion = async () => {
            const db = getFirestore(app);
            const docRef = doc(db, "version", "1");
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data) {
                    const versionOnServer = data.version;
                    if (version.trim() !== versionOnServer) {
                        setVersionOnServer(versionOnServer);
                        setShowReloadModal(true);
                    }
                }
            }
        };
    
        fetchVersion();
    }, [setVersionOnServer, setShowReloadModal]);

    // useEffect hook to load character if id in querystring. 
    useEffectHookLoadStarship(false, IdInQuerystring, currentUser, setStarshipId, setStarshipTraits, setIsLoading);

    // Save the character:

    const saveCharacter = async () => {
        if (currentUser) {
            setIsLoading(true);
            if (starshipId !== "") {
                await UpdateStarship(currentUser, starshipTraits, starshipId);
            } else {
                await SaveNewStarship(currentUser, starshipTraits, setStarshipId);
            }
            setGoToStarshipList(true);
        }
    }

    // const setPrintTraits = (printTraits: PrintTraits) => {
    //     const charTraits = { ...characterTraits };
    //     charTraits.print = printTraits;
    //     setCharacterTraits(charTraits);
    // }

    const cancel = () => {
        setGoToStarshipList(true);
    }

    return (
        <>

            {showReloadModal &&
                <UpdateVersionModal newVersion={versionOnServer} />
            }

            {isLoading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

            {!isLoading &&
                <>

                    {screen === "design" &&
                        <h1>Starship Designer</h1>
                    }

                    {screen === "audit" &&
                        <h1>Starship Audit</h1>
                    }

                    {screen === "sheet" &&
                        <h1>Starship Download</h1>
                    }

                    <CharacterDesignMenu
                        demoMode={false}
                        readOnly={false}
                        screen={screen}
                        setScreen={(screen: string) => setScreen(screen)}
                        saveCharacter={saveCharacter}
                        cancel={cancel}
                    />

                    {screen === "design" &&
                        <StarshipDesignScreen starshipTraits={starshipTraits} setStarshipTraits={setStarshipTraits} readOnly={false} />}

                    {screen === "audit" &&
                        <Audit shipTraits={starshipTraits} />
                    }

                    {screen === "sheet" &&
                        <p>Coming soon!</p>
                    }

                    {screen !== "sheet" &&
                        <CharacterDesignMenu
                            demoMode={false}
                            readOnly={false}
                            screen={screen}
                            setScreen={(screen: string) => setScreen(screen)}
                            saveCharacter={saveCharacter}
                            cancel={cancel}
                        />
                    }

                </>
            }

            {goToStarshipList &&
                <Redirect to="/starships" />
            }

            {/* <div><pre>{localStorage.getItem("tryShipTraits")}</pre></div> */}

        </>

    );
}

export default StarshipDesignMain;

