import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import app from "../firebase";
import { getFirestore, collection, getDocs, query, orderBy as firebaseOrderBy } from "firebase/firestore";
import { AuthContext } from "../Auth";

import * as toastr from "toastr";

const AdminShips: React.FunctionComponent<unknown> = (props: any) => {

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [ships, setShips] = useState<Array<any>>([]);
    const [reloadShips, setReloadShips] = useState(0);
    const [orderBy, setOrderBy] = useState("name_lower");

    useEffect(() => {
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {
                    const db = getFirestore(app);
                    const q = query(collection(db, "starship"), firebaseOrderBy(orderBy, "asc"));
                    const data = await getDocs(q);
                    const ships = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setShips(ships);
                }
            } catch (error: any) {
                toastr.error(error);
            }
            setLoading(false);
        };
    
        asyncUseEffect();
    }, [user, reloadShips, orderBy]);

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const shipsRows = ships.map((c) =>
        <tr key={c.id}>
            <td style={{ width: "33%" }} className="" ><Link to={"/starshipDesigner/" + c.id}>{isBlank(c.name, "(No name)")}</Link></td>
            <td style={{ width: "33%" }} className="" >{isBlank(c.hullType, "(No hull type)")}</td>
            <td style={{ width: "33%" }} className="" >{isBlank(c.user_email, "(No email)")}</td>
        </tr>
    )

    const orderByShipName = () => {
        setOrderBy("name_lower");
        setReloadShips(reloadShips + 1);
    }

    const orderByHullType = () => {
        setOrderBy("hullType");
        setReloadShips(reloadShips + 1);
    }

    const orderByUser = () => {
        setOrderBy("user_email");
        setReloadShips(reloadShips + 1);
    }

    return (
        <div className="pageBody">

            <h1 className="pageHeading">All Starships</h1>

            <p>{ships.length} ships.</p>

            <div style={{ marginBottom: "10px" }}>Order by:&nbsp;
                <button onClick={orderByShipName} style={{ marginRight: "10px" }}>Ship Name</button>
                <button onClick={orderByHullType} style={{ marginRight: "10px" }}>Hull Class</button>
                <button onClick={orderByUser}>User</button>
            </div>

            {!loading && ships.length > 0 &&

                <table className="table table-striped table-sm w-auto">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: "33%" }} className="">Ship Name</th>
                            <th scope="col" style={{ width: "33%" }} className="d-none d-md-table-cell">Hull Class</th>
                            <th scope="col" style={{ width: "33%" }} className="d-none d-md-table-cell">User</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shipsRows}
                    </tbody>
                </table>
            }

            {!loading && ships.length === 0 &&
                <p>Currently no ships</p>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}

export default AdminShips;
