import React, { Suspense } from 'react';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./Auth";

// All layouts/containers
import Layout from './layouts/Layout';

import AdminOnlyRoute from "./components/AdminOnlyRoute";
import LoggedInUserOnlyRoute from "./components/LoggedInUserOnlyRoute";

import AdminUsers from "./pages/AdminUsers";
import AdminCharacters from './pages/AdminCharacters';
import AdminVersion from './pages/AdminVersion';

import AdminCustomAlienFoci from './pages/AdminCustomAlienFoci';
import AdminShips from './pages/AdminShips';
import CharacterDesigner from "./pages/CharacterDesigner";
import CharacterDesignerDemo from "./pages/CharacterDesignerDemo";
import Characters from "./pages/Characters";
import Contact from "./pages/Contact";
import CustomAlienFoci from './pages/CustomAlienFoci';
import CustomAlienFociDesigner from './pages/CustomAlienFociDesigner';
import CustomHome from './pages/CustomHome';
import EmailRequiresVerification from "./pages/EmailRequiresVerification";
import Environment from './pages/Environment';
import FAQ from "./pages/FAQ";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import HomeGuest from "./pages/HomeGuest";
import Login from "./pages/Login";
import MyAccount from "./pages/MyAccount";
import PageNotFound from "./pages/PageNotFound";
import Privacy from "./pages/Privacy";
import SignUp from "./pages/SignUp";
import StarshipDesigner from './pages/StarshipDesigner';
import StarshipDesignerDemo from './pages/StarshipDesignerDemo';
import StarshipShare from './pages/StarshipShare';
import Starships from './pages/Starships';
import Updates from "./pages/Updates";

const App: React.FunctionComponent<unknown> = () => {

  // See /components/Menu.ts for the renderign of the menu links. This just creates the routes/URLs.

  return (
    <Suspense fallback="Loading">
      <AuthProvider>
        <Router>
          <Layout>
            <Switch>

              <Route exact path="/" component={HomeGuest} />
              <Route exact path="/characterDesignerDemo" component={CharacterDesignerDemo} />
              <Route exact path="/starshipDesignerDemo" component={StarshipDesignerDemo} />
              <Route exact path="/faq" component={FAQ} />
              {/* <Route exact path="/custom" component={Custom} /> */}
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/updates" component={Updates} />

              <Route exact path="/privacy" component={Privacy} />

              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />

              <Route exact path="/starshipShare/:id" component={StarshipShare} />

              <Route exact path="/environment" component={Environment} />

              <LoggedInUserOnlyRoute exact path="/home" component={Home} />
              <LoggedInUserOnlyRoute exact path="/emailRequiresVerification" component={EmailRequiresVerification} />
              <LoggedInUserOnlyRoute exact path="/myAccount" component={MyAccount} />
              
              <LoggedInUserOnlyRoute exact path="/characters" component={Characters} />
              <LoggedInUserOnlyRoute exact path="/characterDesigner" component={CharacterDesigner} />
              <LoggedInUserOnlyRoute exact path="/characterDesigner/:id" component={CharacterDesigner} />

              <LoggedInUserOnlyRoute exact path="/starships" component={Starships} />
              <LoggedInUserOnlyRoute exact path="/starshipDesigner" component={StarshipDesigner} />
              <LoggedInUserOnlyRoute exact path="/starshipDesigner/:id" component={StarshipDesigner} />

              <LoggedInUserOnlyRoute exact path="/custom" component={CustomHome} />
              <LoggedInUserOnlyRoute exact path="/customAlienFoci" component={CustomAlienFoci} />
              <LoggedInUserOnlyRoute exact path="/customAlienFociDesigner" component={CustomAlienFociDesigner} />
              <LoggedInUserOnlyRoute exact path="/customAlienFociDesigner/:id" component={CustomAlienFociDesigner} />

              <AdminOnlyRoute exact path="/adminUsers" component={AdminUsers} />
              <AdminOnlyRoute exact path="/adminCharacters" component={AdminCharacters} />
              <AdminOnlyRoute exact path="/adminCustomAlienFoci" component={AdminCustomAlienFoci} />
              <AdminOnlyRoute exact path="/adminShips" component={AdminShips} />
              <AdminOnlyRoute exact path="/adminVersion" component={AdminVersion} />

              <Route component={PageNotFound} />

            </Switch>
          </Layout>
        </Router>
      </AuthProvider>
    </Suspense>
  );
}

export default App;