import app from "../firebase";
import { getFirestore, collection, addDoc, setDoc, doc } from "firebase/firestore";
import { User } from "firebase/auth";

import * as toastr from "toastr";
import { StarshipTraits } from '../interfaces/StarshipTraits';

const getBasicDataToSave = (s: StarshipTraits, currentUser: User) => {
    return {
        hullType: s.hullType,
        isPublic: s.isPublic,
        name: s.name,
        name_lower: s.name.toLowerCase(),
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}

const getTraitsDataToSave = (s: StarshipTraits, currentUser: User) => {
    return {
        name: s.name,
        isPublic: s.isPublic,
        traits: s,
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}


export const SaveNewStarship = async (
    currentUser: User,
    starshipTraits: StarshipTraits,
    setStarshipId: any,
) => {

    localStorage.removeItem("tryShipTraits");

    const s = starshipTraits;

    if (currentUser) {

        let starshipId = "";

        // Save basic details of ship into 'starship' collection, used for listing starships.
        const basicDataToSave = getBasicDataToSave(s, currentUser);

        const db = getFirestore(app);

        try {
            const docRef = await addDoc(collection(db, "starship"), basicDataToSave);
            starshipId = docRef.id;
            setStarshipId(starshipId);
        } catch (error: any) {
            toastr.error(error);
        }

        // Save details of character into 'characterTraits' collection, used for editing a character.

        const shipTraitsDataToSave = getTraitsDataToSave(s, currentUser);
        starshipTraits.id = starshipId;

        try {
            await setDoc(doc(db, "starshipTraits", starshipId), shipTraitsDataToSave); // ✅ Correct way to set a document
        } catch (error: any) {
            toastr.error(error);
        }
    }
}

export const UpdateStarship = async (
    currentUser: User | null,
    starshipTraits: StarshipTraits,
    starshipId: string,

) => {

    localStorage.removeItem("tryShipTraits");

    const s = starshipTraits;

    if (currentUser) {

        // Update basic details of character into 'character' collection, used for listing characters.
        const basicDataToSave = getBasicDataToSave(s, currentUser);

        const db = getFirestore(app);

        try {
            await setDoc(doc(db, "starship", starshipId), basicDataToSave);
        } catch (error: any) {
            toastr.error(error);
        }

        // Update details of character into 'characterTraits' collection, used for editing a character.
        const starshipTraitsDataToSave = getTraitsDataToSave(s, currentUser);
        try {
            await setDoc(doc(db, "starshipTraits", starshipId), starshipTraitsDataToSave); 
        } catch (error: any) {
            toastr.error(error);
        }
    }
}