import React, { useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { AuthContext } from "../Auth";
import { History } from 'history';

import HomeIntro from "../components/HomeIntro";
import HomeFooter from "../components/HomeFooter";
import HomeNote from "../components/HomeNote";

type IProps = {
    history: History,
}

const HomeGuest: React.FunctionComponent<IProps> = (props: IProps) => {

    const { t } = useTranslation();

    const { user: currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to={"/home"} />
    }

    return (
        <div className="pageBody heroShot">

            <HomeIntro />

            <div className="tryDesigner2">
                <div className="tryDesigner2margin"><Link className="btn btn-primary btn-lg tryButton" to="/characterDesignerDemo">Try the Character Designer</Link></div>
                <div className="tryDesigner2margin"><Link className="btn btn-primary btn-lg tryButton" to="/starshipDesignerDemo">Try the Starship Designer</Link></div>
                <div>
                    <Link className="btn btn-primary mr-2" to="/signup" style={{ width: "90px" }}>{t("menu.sign_up")}</Link>
                    <Link className="btn btn-primary mr-2" to="/login" style={{ width: "90px" }}>{t("menu.login")}</Link>
                    <a className="btn btn-primary mr-2" style={{ width: "100px" }} href="https://www.buymeacoffee.com/swoone" target="_blank" rel="noopener noreferrer"><img src="/BuyMeACoffee.svg" height="20px" width="20px" alt="" /> Coffee</a>
                </div>
                <div className="mt-2">
                </div>
            </div>

            {/* <HomeNote /> */}

            {/* <p className="homeIntro">Then sign up for an account so that you can save your characters.</p> */}

            <HomeFooter />

        </div>
    );
}

export default HomeGuest;
