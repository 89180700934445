import { CharacterDerivedStats } from './../classes/CharacterDerivedStats';
import { CharacterTraits } from './../interfaces/CharacterTraits';
import { DieRoll } from "../interfaces/DieRoll";
import { FocusData } from '../interfaces/FocusData';
import { Lookups } from '../lookups/Lookups';
import { User } from "firebase/auth";

import { getFirestore, collection, query, where, orderBy, getDocs } from 'firebase/firestore';

export const PlusMinus = (num: number): string => {
    if (num < 0) { return num.toString() };
    return "+" + num;
}

export const getAttributeModifierPlusMinus = (attributeName: string, score: number, charDerivedStats: CharacterDerivedStats | null): string => {

    let modifier = getAttributeModifierRaw(attributeName, score, charDerivedStats);

    if (modifier < 0) {
        return modifier.toString();
    } else {
        return "+" + modifier;
    }
}

export const getAttributeModifierRaw = (attributeName: string, score: number, charDerivedStats: CharacterDerivedStats | null): number => {

    let modifier = 0;

    if (score === 0) { modifier = 0; }
    else if (score <= 3) { modifier = -2; }
    else if (score <= 7) { modifier = -1; }
    else if (score <= 13) { modifier = 0; }
    else if (score <= 17) { modifier = 1; }
    else if (score >= 18) { modifier = 2; }


    // check max modifer:  Alien origin focus with Strong Attribute can go up to +3, all others +2
    let maxModifier = 2;

    const alienFocus = charDerivedStats?.focusLevels.find((fl) => fl.focus.indexOf("Alien -") !== -1);
    if (alienFocus) {
        const lookups = Lookups.getInstance();
        const selectedFocusData = lookups.focuses.find((fd) => fd.focus === alienFocus.focus);
        if (selectedFocusData) {
            if (selectedFocusData.benefits?.find((ben) => ben.benefit === "strongAttribute")) {
                maxModifier = 3;
            }
        }
    }

    if (charDerivedStats) {
        const attrModLevel = charDerivedStats.attributeModifierLevels.filter((al) => al.attributeName === attributeName);
        attrModLevel.forEach((am) => {
            if (am && am.modifierAdjustment) {
                if (modifier < maxModifier) { //
                    modifier = modifier + am.modifierAdjustment;
                }
            }
        })
    }

    return modifier;
}

export const formatCredits = (credits: number) => {
    return "$" + credits.toLocaleString('en-US', { style: 'decimal', currency: 'USD' });
}

export const formatLargeCredits = (credits: number) => {

    if (Math.abs(credits) >= 1000000) {
        return "$" + (credits / 1000000).toLocaleString('en-US', { style: 'decimal', currency: 'USD' }) + "m";
    } else if (Math.abs(credits) >= 1000) {
        return "$" + (credits / 1000).toLocaleString('en-US', { style: 'decimal', currency: 'USD' }) + "k";
    } else {
        return "$" + (credits).toLocaleString('en-US', { style: 'decimal', currency: 'USD' });
    }
}

export const formatDamage = (damageRoll: number[] | undefined, burst?: boolean, suppress?: boolean) => {
    if (damageRoll !== undefined) {
        if (damageRoll.length === 0) { return "-"; }
        if (damageRoll[0] === 0 && damageRoll[1] === 0 && damageRoll[2] === 0) { return "-"; }
        let d = damageRoll[0] + "d" + damageRoll[1];
        if (damageRoll[2]) {
            const damBonus = damageRoll[2];
            if (damBonus > 0) { d += "+" + damBonus; }
            if (damBonus < 0) { d += damBonus; }
        }
        if (burst) { d = d + "*"; }
        if (suppress) { d = d + "%"; }
        return d;
    }
    return "?";
}

export const formatAttack = (atk: number | undefined) => {
    if (atk === -1 || atk === undefined) { return "-"; }
    return "+" + atk;
}

export const formatDieRoll = (die: DieRoll) => {
    if (die !== undefined) {

        if (die.numDice === 0 && die.dieSize === 0 && die.bonus === 0) { return "-"; }

        let d = die.numDice + "d" + die.dieSize;

        const damBonus = die.bonus;
        if (damBonus > 0) { d += "+" + damBonus; }
        if (damBonus < 0) { d += damBonus; }
        return d;
    }
    return "?";
}

export const formatRange = (range: number[] | undefined) => {
    if (range !== undefined) {
        return range[0] + "/" + range[1];
    }
    return "?";
}

export const formatMagazine = (magazine: number | undefined, magazineReload: number | undefined) => {
    let m = "";
    if (magazine !== undefined) {
        m = m + magazine;
        if (magazineReload !== undefined) {
            m = m + "@";
        }
        return m;
    }
    return "N/A";
}

export const formatAttributes = (attributes: string[] | undefined) => {
    if (attributes !== undefined) {
        return attributes.join("/");
    }
    return "?";
}

export const formatShock = (shock: number[] | undefined) => {
    if (shock !== undefined) {
        return shock[0] + "pt/AC" + shock[1];
    }
    return "N/A";
}

export const formatArmor = (armor: number | undefined) => {
    if (armor === 100) { return "Special" };
    return armor;
}

export const attributeMethod = (method: string) => {
    switch (method) {
        case "roll": return "Attributes were rolled randomly (3d6 each, in order, set one to 14).";
        case "assign": return "Attributes were assigned from a standard array.";
        case "rollAndReorder": return "Non-standard method: Attributes were rolled randomly (3d6 each, assign order, set one to 14).";
        case "assignAny": return "Non-standard method: Attribute scores were assigned as desired.";
        default: return "Attribute generation method has not been selected.";
    }
}

export const getRerolls = (charTraits: CharacterTraits) => {
    if (charTraits.attributeTraits.method === "roll" || charTraits.attributeTraits.method === "rollAndReorder") {
        if (charTraits.attributeTraits.rerolls > 0) {
            const plural = charTraits.attributeTraits.rerolls === 1 ? "" : "s";
            return charTraits.attributeTraits.rerolls + " reroll" + plural;
        } else {
            return "";
        }
    }
    return "";
}

export const ordinal_suffix = (i: number) => {
    let j = i % 10, k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export const convertSkillPointsToBoosts = (sp: number) => {
    if (sp === 0) {
        return 0;
    } else if (sp >= 1 && sp < 3) {
        return 1;
    } else if (sp >= 3 && sp < 6) {
        return 2;
    } else if (sp >= 6 && sp < 10) {
        return 3;
    } else if (sp >= 10 && sp < 15) {
        return 4;
    } else {
        return 5;
    }
}

export const convertSkillPointsToPointsInvested = (sp: number) => {
    if (sp === 0) {
        return 0;
    } else if (sp >= 1 && sp < 3) {
        return 1;
    } else if (sp >= 3 && sp < 6) {
        return 3;
    } else if (sp >= 6 && sp < 10) {
        return 6;
    } else if (sp >= 10 && sp < 15) {
        return 10;
    } else {
        return 15;
    }
}

export const convertPointTypeToName = (pointsType: string) => {

    if (pointsType.indexOf("_skill") !== -1) {
        return pointsType.replace("_skill", " skill");
    }

    switch (pointsType) {
        case "general": return "general";
        case "nonCombatNonPsychic": return "non-combat non-psychic";
        case "psychic": return "psychic";
        default: return pointsType;
    }
}

export const convertPointTypeToNameCapitalised = (pointsType: string, shortenNonComNonPsy: boolean = false) => {

    if (pointsType.indexOf("_skill") !== -1) {
        return pointsType.replace("_skill", " Skill");
    }

    switch (pointsType) {
        case "general": return "General";
        case "nonCombatNonPsychic": {
            if (shortenNonComNonPsy) {
                return "Non-Com/Non-Psy";
            } else {
                return "Non-Combat, Non-Psychic";
            }
        }
        case "psychic": return "Psychic";
        default: return pointsType;
    }
}

export const getCharacterDesc = (characterTraits: CharacterTraits) => {
    if (characterTraits.basicTraits.name.trim() === "") {
        return "Character Name";
    }
    return characterTraits.basicTraits.name;
}

export const viDescription = () => {
    return "Base AC 10. Do not eat, sleep or drink. Require one Type B cell per week. Immmune to vacuum, disease and poison. Do not heal naturally and are unaffected by biopsionic healing. Can be healed with a unit of spare parts and Fix-1 skill, restoring hit points equal to the VI's level. Becomes incapacitated and Badly Damaged at zero hit points. Badly Damaged can be removed with 24 hours work and a unit of spare parts. A Badly Damaged VI can be healed as usual with spare parts, but if reduced to zero hit points again must make a Physical save or be destroyed.";
}

export const getAlienFocusesFromDB = async (user: User, app: any) => {

    const focuses: FocusData[] = [];

    try {
    if (user) {

        const db = getFirestore(app);

        const alienFociQuery = query(
            collection(db, "alienFoci"),
            where("user_id", "==", user.uid),
            orderBy("alienFocus_lower", "asc")
        );
        const querySnapshot = await getDocs(alienFociQuery);

        const alienFoci: any[] = [];
        querySnapshot.forEach((doc) => {
            alienFoci.push({ ...doc.data(), id: doc.id });
        });

        alienFoci.forEach((af) => {

            const alienFocus: FocusData = {
                focus: "Alien - " + af.alienFocus,
                desc: af.shortDesc,
                type: "NonCombat",
                levels: [
                    {
                        level: 1,
                        desc: af.longDesc,
                        bonuses: af.bonuses
                    }
                ],
                source: "alienFoci",
                flaws: af.flaws,
                benefits: af.benefits
            };
            focuses.push(alienFocus);
        });
        focuses.sort((a, b) => a.focus > b.focus ? 1 : -1);
    }
    }
    catch (error: any) {
        console.log(JSON.stringify(error, null, 2));
    }
    return focuses;
}