import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import app from "../firebase";
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from "../Auth";

import jQuery from 'jquery';
import { getAlienFocusesFromDB } from "../utilities/Utilities";
import { Lookups } from "./../lookups/Lookups";

// import SetLanguage from "./SetLanguage";

type IProps = {}

const Menu: React.FunctionComponent<IProps> = (props: IProps) => {

    const { user: currentUser, isAdmin } = useContext(AuthContext);

    const { user } = useContext(AuthContext);

    // Load the custom alien foci from the database when the app starts. 
    useEffect(() => {
        const asyncGetData = async () => {
            if (user) {
                const alienFocusesFromDB = await getAlienFocusesFromDB(user, app);
                const lookups = Lookups.getInstance();
                lookups.addFoci("alienFoci", alienFocusesFromDB);
            }
        };
        asyncGetData();
    }, [user]);

    const onSignOut = (): any => {
        const auth = getAuth(app);
        signOut(auth);
    }

    jQuery('.navbar-nav>li>a').on('click', function () {
        (jQuery('.navbar-collapse') as any).collapse('hide'); // tslint disable-line
    });

    return (
        <div className="pb-1">

            <nav className="navbar navbar-expand-lg navbar-light bg-light mainMenu">
                <ul className="navbar-nav nav mr-auto">
                    {(currentUser && currentUser.emailVerified) &&
                        <li className="nav-item">
                            <Link className="siteHeader navbar-brand nav-link active freebooter" to="/home">
                                freebooter
                            </Link>
                        </li>
                    }
                    {(!currentUser || (currentUser && !currentUser.emailVerified)) &&
                        <li className="nav-item">
                            <Link className="siteHeader navbar-brand  nav-link active freebooter" to="/">
                                freebooter
                            </Link>
                        </li>
                    }
                </ul>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav nav mr-auto">

                        {currentUser && currentUser.emailVerified &&
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/characters">Characters</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/starships">Starships</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/customAlienFoci">Aliens</Link>
                                </li>
                            </>
                        }

                        {!currentUser &&
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/characterDesignerDemo">Characters</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/starshipDesignerDemo">Starships</Link>
                                </li>
                            </>
                        }

                        <li className="nav-item">
                            <Link className="nav-link" to="/faq">FAQ</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/updates">Updates</Link>
                        </li>

                        {isAdmin &&
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link text-primary" to="/adminUsers">Users</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-primary" to="/adminCharacters">All Chars</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-primary" to="/adminCustomAlienFoci">All Aliens</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-primary" to="/adminShips">All Ships</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-primary" to="/adminVersion">Version</Link>
                                </li>
                            </>
                        }


                    </ul>

                    <ul className="navbar-nav nav ml-auto">
                        {currentUser &&
                            <li className="nav-item">
                                <Link className="nav-link" to="/myAccount">Account</Link>
                            </li>
                        }
                        {!currentUser &&
                            <li className="nav-item">
                                <Link className="nav-link" to="/signup">Sign Up</Link>
                            </li>
                        }
                        {/* <li className="nav-item">
                            <SetLanguage />
                        </li> */}
                        {currentUser &&
                            <li className="nav-item">
                                <a href="#!" className="nav-link" onClick={onSignOut}>Sign Out</a>
                            </li>
                        }
                        {!currentUser &&
                            <li className="nav-item">
                                <Link className="nav-link" to="/login">Log In</Link>
                            </li>
                        }
                    </ul>

                </div>

            </nav>

        </div >
    );
}

export default Menu;
