import React, { useContext, useState } from "react";
import { useTranslation, Trans } from 'react-i18next';

import app from "../firebase";
import { AuthContext } from "../Auth";
import { getAuth, sendEmailVerification } from "firebase/auth";

type IProps = {}

const EmailRequiresVerification: React.FunctionComponent<IProps> = (props: IProps) => {

    const { t } = useTranslation();

    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState("");

    const { user: currentUser } = useContext(AuthContext);

    let email = t("emailVerify.unknownAddress");
    if (currentUser && currentUser.email) {
        email = currentUser.email;
    }

    const onResendVerificationEmail = async () => {
        const auth = getAuth(app);
        const user = auth.currentUser;

        try {
            if (user) {
                await sendEmailVerification(user);
                setEmailSent(true);
            }
        } catch (error:any) {
            setError(error);
        }
    }

    return (
        <div className="pageBody">

            <h1 className="pageHeading">{t("emailVerify.emailReqVerification")}</h1>

            {error &&
                <div className="text-danger">{error}</div>
            }

            {!emailSent &&
                <>
                    <div className="pb-3">
                        <Trans i18nKey="emailVerify.clickToResend">If you cannot find the email, click this button to resend the verification email to {{ email }}:</Trans>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={onResendVerificationEmail}>{t("emailVerify.resend")}</button>
                    </div>
                </>
            }

            {emailSent &&
                <div className="alert alert-primary" role="alert">
                    <Trans i18nKey="emailVerify.emailSent">The verification email has been re-sent to {{ email }}.</Trans>
                </div>
            }

        </div>
    );
}

export default EmailRequiresVerification;
