import { getTechLevel } from './../utilities/charMainUtilities';
import { SkillPointAssignment } from './../interfaces/SkillPointAssignment';
import { convertPointTypeToName, formatDamage, formatDieRoll, getAttributeModifierPlusMinus, getAttributeModifierRaw, PlusMinus } from './../utilities/Utilities';

import { ArmorClassLevel } from './../interfaces/ArmorClassLevel';
import { AttributeLevel } from "../interfaces/AttributeLevel";
import { BaseAttackLevel } from '../interfaces/BaseAttackLevel';
import { CharacterTraits, EquipmentItem } from "../interfaces/CharacterTraits";
import { FocusLevel } from "../interfaces/FocusLevel";
import { HitPointsLevel } from './../interfaces/HitPointLevel';
import { PsychicTechniqueLevel } from "../interfaces/PsychicTechniqueLevel";
import { SkillLevel } from "../interfaces/SkillLevel";
import { AllSavingThrowLevels } from "../interfaces/AllSavingThrowLevels";

import SkillsJSON from "../lookups/SkillsAll.json";
import { Lookups } from "../lookups/Lookups";
import { attributeLongNames } from "../lookups/Enums";
import { EffortLevel } from '../interfaces/EffortLevel';
import { getSkillsByType } from '../utilities/SkillUtilities';
import { WeaponStat } from '../interfaces/WeaponStat';
import { DieRoll } from '../interfaces/DieRoll';
import { AttributeSkillPointsTracking } from '../interfaces/AttributeSkillPointsTracking';
import { VehicleStat } from '../interfaces/VehicleStat';
import { AttributeModifierLevel } from '../interfaces/attributeModifierLevel';
import { SystemStrainLevel } from '../interfaces/SystemStrainLevel';
import { DroneStat } from '../interfaces/DroneStat';
import { RobotStat } from '../interfaces/RobotStat';

export enum CreationStep { Name = 1, Attributes = 2, Background = 3, Classes = 4, FreeFocus = 5, FreeSkill = 6, PsychicDisciplines = 7, AllSteps = 100 }

export class CharacterDerivedStats {

    private charTraits: CharacterTraits;

    private skillsData = SkillsJSON;

    attributeLevels: AttributeLevel[];
    skillLevels: SkillLevel[];
    focusLevels: FocusLevel[];
    attributeModifierLevels: AttributeModifierLevel[];
    psychicTechniqueLevels: PsychicTechniqueLevel[];
    hitPointsLevels: HitPointsLevel[];
    baseAttackLevels: BaseAttackLevel;
    savingThrowLevels: AllSavingThrowLevels;
    effortLevels: EffortLevel;
    armorClassLevels: ArmorClassLevel;
    armorClassLevelsVsTL4: ArmorClassLevel;
    readiedEquipmentEncumbrance: number;
    stowedEquipmentEncumbrance: number;
    readiedUnencumberedLimit: number;
    readiedLightlyEncumberedLimit: number;
    readiedHeavilyEncumberedLimit: number;
    stowedUnencumberedLimit: number;
    stowedLightlyEncumberedLimit: number;
    stowedHeavilyEncumberedLimit: number;
    encumbranceLevel: string;
    encumbranceLevelBaseMove: number;
    rangedWeaponStats: WeaponStat[];
    meleeWeaponStats: WeaponStat[];
    vehicleStats: VehicleStat[];
    robotStats: RobotStat[];
    droneStats: DroneStat[];
    isPsychic: boolean;
    isRestrictedPsychic: boolean;
    // skillPointsGainedAtThisLevel: SkillPoints;
    // skillPointsSpentAtThisLevel: SkillPoints;
    skillPointsAvailableSoFar: any; // See SkillPoints interface for example members. 
    skillPointsSpentSoFar: any;
    skillPointsSpentSoFarOnTechniques: any;
    skillPointsLeftSoFar: any;
    totalAttributeBoosts: number;
    skillPointAssignments: SkillPointAssignment[];
    attributeSkillPointsTracking: AttributeSkillPointsTracking[];
    systemStrainMaximum: SystemStrainLevel;
    systemStrainPermanent: SystemStrainLevel;
    totalModsMaintenance: number;
    maintenanceScore: number;
    totalGearCost: number;
    creditsAvailable: number;

    constructor(characterTraits: CharacterTraits) {
        this.charTraits = characterTraits;
        this.attributeLevels = [];
        this.skillLevels = [];
        this.focusLevels = [];
        this.attributeModifierLevels = [];
        this.psychicTechniqueLevels = [];
        this.hitPointsLevels = [{ level: 1, hitPoints: 0, totalHitPointsAtLevel: 0, history: [], didNotBeatPreviousLevelHP: false }];
        this.baseAttackLevels = { baseAttack: 0, history: [] };
        this.savingThrowLevels = { physical: { score: 0, history: [] }, mental: { score: 0, history: [] }, evasion: { score: 0, history: [] }, notes: [] };
        this.effortLevels = { effort: 0, history: [] };
        this.armorClassLevels = { ac: 10, history: [], notes: [] };
        this.armorClassLevelsVsTL4 = { ac: 10, history: [], notes: [] };
        this.readiedEquipmentEncumbrance = 0;
        this.stowedEquipmentEncumbrance = 0;
        this.readiedUnencumberedLimit = 0;
        this.readiedLightlyEncumberedLimit = 0;
        this.readiedHeavilyEncumberedLimit = 0;
        this.stowedUnencumberedLimit = 0;
        this.stowedLightlyEncumberedLimit = 0;
        this.stowedHeavilyEncumberedLimit = 0;
        this.encumbranceLevel = "";
        this.encumbranceLevelBaseMove = 0;
        this.rangedWeaponStats = [];
        this.meleeWeaponStats = [];
        this.vehicleStats = [];
        this.droneStats = [];
        this.robotStats = [];
        this.isPsychic = false;
        this.isRestrictedPsychic = false;
        this.skillPointsAvailableSoFar = { general: 0, nonCombatNonPsychic: 0, psychic: 0 };
        this.skillPointsSpentSoFar = { general: 0, nonCombatNonPsychic: 0, psychic: 0 };
        this.skillPointsSpentSoFarOnTechniques = {};
        this.skillPointsLeftSoFar = { general: 0, nonCombatNonPsychic: 0, psychic: 0 };
        this.totalAttributeBoosts = 0;
        this.skillPointAssignments = [];
        this.attributeSkillPointsTracking = [];
        this.systemStrainMaximum = { strain: 0, history: [] };
        this.systemStrainPermanent = { strain: 0, history: [] };
        this.totalModsMaintenance = 0;
        this.maintenanceScore = 0;
        this.totalGearCost = 0;
        this.creditsAvailable = 0;
    }

    pluralise = (num: number) => num > 1 ? "s" : "";

    calculateIsPsychic = () => {
        let isPsychic = false;
        let isWildTalent = false;

        const psychicClasses = this.charTraits.levelOne.classes.filter((c) => c.className === "Psychic" || c.className === "Partial Psychic");
        if (psychicClasses.length > 0) { isPsychic = true; }

        const wildTalentFocuses = this.focusLevels.filter((f) => f.focus === "Wild Psychic Talent");
        if (wildTalentFocuses.length > 0) { isWildTalent = true; }

        this.isPsychic = (isPsychic || isWildTalent);

        // check if is restricted psychic
        const restrictedPsychicClasses = this.charTraits.levelOne.classes.filter((c) => c.className === "Partial Psychic");
        if (restrictedPsychicClasses.length > 0) { this.isRestrictedPsychic = true; }
    }

    calculateAttributeLevels = (step: CreationStep, backgroundTableRollNumber: number = -1, level: number = -1, index: number = -1) => {

        this.attributeLevels = [];

        // initialise Attributes with initial scores
        this.charTraits.attributeTraits.attributeScores.forEach((as, index) => {

            const originalScore = this.charTraits.attributeTraits.originalScores[index];
            const method = this.charTraits.attributeTraits.method;
            if (originalScore !== as) {
                if (method === "assign" || method === "assignAny") {
                    const newAttributeLevel: AttributeLevel = { attributeName: attributeLongNames[index], attributeIndex: index, level: as, history: ["Assigned as " + as] };
                    this.attributeLevels.push(newAttributeLevel);
                } else {
                    const newAttributeLevel: AttributeLevel = { attributeName: attributeLongNames[index], attributeIndex: index, level: as, history: ["Initial score of " + originalScore, "Set to 14"] };
                    this.attributeLevels.push(newAttributeLevel);
                }
            } else {
                const newAttributeLevel: AttributeLevel = { attributeName: attributeLongNames[index], attributeIndex: index, level: as, history: ["Initial score of " + as] };
                this.attributeLevels.push(newAttributeLevel);
            }

            index = index + 1;
        })

        // Add score bonuses from background rolls:
        if (CreationStep.Background <= step) {

            // Background attribute bonuses from rolling on growth and learning tables:
            if (this.charTraits.background) {
                let rollNum = 0;
                this.charTraits.background.tableRolls.forEach((tr) => {

                    if (step !== CreationStep.Background || rollNum <= backgroundTableRollNumber) {
                        tr.attributeScoreBonuses.forEach((asp) => {
                            const thisAttributeLevel = this.attributeLevels.find((a) => a.attributeIndex === asp.attributeNumber);
                            if (thisAttributeLevel) {
                                if (thisAttributeLevel.level === null) { thisAttributeLevel.level = 0 };
                                thisAttributeLevel.level += asp.bonus;
                                thisAttributeLevel.history.push("+" + asp.bonus + " bonus from " + this.charTraits.background.backgroundName + " background (Roll #" + (rollNum + 1) + " on " + tr.table + " table)");
                            }
                        })
                    }

                    rollNum = rollNum + 1;

                });
            }
        }

        // Attribute boosts purchased from spending skill points as level up

        if (level > 1) {

            let pointsSpentOnAttributes: any = {};
            attributeLongNames.forEach((att) => {
                const spt: AttributeSkillPointsTracking = { attribute: att, pointsSpent: 0, boosts: 0, pointsInvested: 0, boostNumbers: [] };
                pointsSpentOnAttributes[att] = spt;
            })

            // loop through all levels and skill point picks (up tp this index)
            let costPerBoost = 1;
            let boostNumber = 1;
            this.charTraits.levels.forEach((lev) => {
                if (lev.level <= level) {
                    lev.skillPointSpends.forEach((sps, thisIndex) => {

                        let indexOnLastLevel = 100;
                        if (lev.level === level) { indexOnLastLevel = index; }

                        if (thisIndex <= indexOnLastLevel) {
                            if (sps.spendType === "improveAttribute" && sps.attributeName !== "") {
                                pointsSpentOnAttributes[sps.attributeName].pointsSpent = pointsSpentOnAttributes[sps.attributeName].pointsSpent + sps.pointsSpent;
                                let pointsLeft = pointsSpentOnAttributes[sps.attributeName].pointsSpent - pointsSpentOnAttributes[sps.attributeName].pointsInvested;

                                while (pointsLeft >= costPerBoost) {
                                    pointsLeft = pointsLeft - costPerBoost;
                                    pointsSpentOnAttributes[sps.attributeName].boosts += 1;
                                    pointsSpentOnAttributes[sps.attributeName].boostNumbers.push(boostNumber);
                                    boostNumber = boostNumber + 1;
                                    pointsSpentOnAttributes[sps.attributeName].pointsInvested += costPerBoost;
                                    costPerBoost = costPerBoost + 1;
                                }
                            }
                        }

                    })
                }
            });

            // Add the attribute levels to the history. 
            let totalBoosts = 0;
            attributeLongNames.forEach((att) => {
                const thisPointTrack: AttributeSkillPointsTracking = pointsSpentOnAttributes[att];
                if (thisPointTrack.boosts > 0) {
                    const thisAttributeLevel = this.attributeLevels.find((al) => al.attributeName === att);
                    if (thisAttributeLevel) {
                        if (thisAttributeLevel.level === null) { thisAttributeLevel.level = 0 };

                        thisAttributeLevel.level += thisPointTrack.boosts;
                        const boostsNote = thisPointTrack.boostNumbers.length === 0 ? "" : " (Attribute boost" + this.pluralise(thisPointTrack.boostNumbers.length) + " " + thisPointTrack.boostNumbers.join(" & ") + ")"
                        thisAttributeLevel.history.push("+" + thisPointTrack.boosts + " from " + thisPointTrack.pointsInvested + " skill point" + this.pluralise(thisPointTrack.pointsInvested) + boostsNote);

                        totalBoosts += thisPointTrack.boosts;
                    }
                }
            })

            this.totalAttributeBoosts = totalBoosts;
            this.attributeSkillPointsTracking = pointsSpentOnAttributes;
        }

        // Also calculate attribute modifier adjustments (i.e. for VI Worker Bot and Alien 'String Ability' benefit)
        this.attributeModifierLevels = [];
        if (this.charTraits.levelOne.freeFocusLevelPick.attributeModifierPicks) {
            attributeLongNames.forEach((att, index) => {
                const thisAttributeLevel = this.attributeLevels.find((al) => al.attributeName === att);
                if (thisAttributeLevel) {
                    const thisAttrMod = this.charTraits.levelOne.freeFocusLevelPick.attributeModifierPicks?.find((amp) => amp.attributeIndex === index);
                    if (thisAttrMod) {
                        thisAttributeLevel.history.push("+" + thisAttrMod.modifierAdjustment + " attribute modifier from Free Focus Pick");

                        const thisAttributeModifierLevel: AttributeModifierLevel = { attributeName: att, attributeIndex: index, modifierAdjustment: thisAttrMod.modifierAdjustment };
                        this.attributeModifierLevels.push(thisAttributeModifierLevel);
                    }
                }
            })
        }

        // If has Stabilization Overrides cyberware, reduce CON mod by 1.
        const hasStabilizationOverrides = this.charTraits.gear.equipment.find((g) => g.name.indexOf("Stabilization Overrides") !== -1);
        if (hasStabilizationOverrides) {
            const thisAttributeLevel = this.attributeLevels.find((al) => al.attributeName === "Constitution");
            if (thisAttributeLevel) {
                thisAttributeLevel.history.push("-1 attribute modifier from Stabilization Overrides cyberware");

                const thisAttributeModifierLevel: AttributeModifierLevel = { attributeName: "Constitution", attributeIndex: 2, modifierAdjustment: -1 };
                this.attributeModifierLevels.push(thisAttributeModifierLevel);
            }
        }

    }

    calculateSkillLevels = (step: CreationStep, classNumber: number = -1, backgroundTableRollNumber: number = -1, level: number = -1, propIndex: number = -1, isLevelFocus: boolean = false, withoutFocusBonusSkillPoints: boolean = false, excludeSkillPointBuys: boolean = false) => {

        this.skillLevels = [];

        // Initialise skill list
        this.skillsData.sort((a, b) => a.skill > b.skill ? 1 : -1).forEach((sd) => {
            const newSkillLevel: SkillLevel = { skill: sd.skill, level: null, history: [] };
            this.skillLevels.push(newSkillLevel);
        })

        // Calculate each skill's final level and history. 

        // Background skills:

        if (CreationStep.Background <= step) {

            // Background skills from assigning three skills:
            if (this.charTraits.background) {
                this.charTraits.background.backgroundSkillLevelPicks.forEach((slp) => {
                    const thisSkillLevel = this.skillLevels.find((s) => s.skill === slp.skill);
                    if (thisSkillLevel) {
                        if (thisSkillLevel.level === null) { thisSkillLevel.level = 0 };
                        thisSkillLevel.level += slp.levels;
                        const pluralise = slp.levels > 1 ? "s" : "";
                        thisSkillLevel.history.push("+" + slp.levels + " level" + pluralise + " from " + this.charTraits.background.backgroundName + " background");
                    }
                })
            }

            // Background skills from rolling on growth and learning tables:
            if (this.charTraits.background) {
                let rollNum = 0;
                this.charTraits.background.tableRolls.forEach((tr) => {

                    if (step !== CreationStep.Background || rollNum <= backgroundTableRollNumber) {

                        const slp = tr.skillLevelPicks[0];
                        if (slp) {
                            const thisSkillLevel = this.skillLevels.find((s) => s.skill === slp.skill);
                            if (thisSkillLevel) {
                                if (thisSkillLevel.level === null) { thisSkillLevel.level = 0 };
                                thisSkillLevel.level += slp.levels;
                                const pluralise = slp.levels > 1 ? "s" : "";
                                thisSkillLevel.history.push("+" + slp.levels + " level" + pluralise + " from " + this.charTraits.background.backgroundName + " background (Roll #" + (rollNum + 1) + " on " + tr.table + " table)");
                            }
                        }

                    }

                    rollNum = rollNum + 1;

                });
            }
        }

        // Class skills:
        if (CreationStep.Classes <= step) {
            this.charTraits.levelOne.classes.forEach((c, index) => {

                if (step !== CreationStep.Classes || index <= classNumber) {

                    // Class skill picks. 
                    c.classSkillPicks.forEach((csp) => {
                        const thisSkillLevel = this.skillLevels.find((s) => s.skill === csp.skill);
                        if (thisSkillLevel) {
                            if (thisSkillLevel.level === null) { thisSkillLevel.level = 0 };
                            thisSkillLevel.level += csp.levels;
                            const pluralise = csp.levels > 1 ? "s" : "";
                            thisSkillLevel.history.push("+" + csp.levels + " level" + pluralise + " from " + c.className + " class at 1st level");
                        }
                    })

                    if (c.classFocusLevelPick && c.classFocusLevelPick.grantsSkill) {
                        c.classFocusLevelPick.skillLevelPicks.forEach((slp) => {
                            const thisSkillLevel = this.skillLevels.find((s) => s.skill === slp.skill);
                            if (thisSkillLevel && c.classFocusLevelPick) {
                                if (thisSkillLevel.level === null) { thisSkillLevel.level = 0 };
                                thisSkillLevel.level += slp.levels;
                                const pluralise = slp.levels > 1 ? "s" : "";
                                thisSkillLevel.history.push("+" + slp.levels + " level" + pluralise + " from " + c.classFocusLevelPick.focus + " " + c.classFocusLevelPick.level + " focus from " + c.className + " class at 1st level");
                            }
                        })
                    }

                }

            });
        }

        // Skill from free focus level at level one (if any):
        if (CreationStep.FreeFocus <= step) {
            const freeFocusLevel = this.charTraits.levelOne.freeFocusLevelPick;
            if (freeFocusLevel.grantsSkill) {
                freeFocusLevel.skillLevelPicks.forEach((slp) => {
                    const thisSkillLevel = this.skillLevels.find((s) => s.skill === slp.skill);
                    if (thisSkillLevel) {
                        if (thisSkillLevel.level === null) { thisSkillLevel.level = 0 };
                        thisSkillLevel.level += slp.levels;
                        const pluralise = slp.levels > 1 ? "s" : "";
                        thisSkillLevel.history.push("+" + slp.levels + " level" + pluralise + " from " + freeFocusLevel.focus + " " + freeFocusLevel.level + " free focus at 1st level");
                    }
                })
            }
        }

        // Free skill level at level one:
        if (CreationStep.FreeSkill <= step) {
            this.charTraits.levelOne.freeSkillLevelPicks.forEach((slp) => {
                const thisSkillLevel = this.skillLevels.find((s) => s.skill === slp.skill);
                if (thisSkillLevel) {
                    if (thisSkillLevel.level === null) { thisSkillLevel.level = 0 };
                    thisSkillLevel.level += slp.levels;
                    const pluralise = slp.levels > 1 ? "s" : "";
                    thisSkillLevel.history.push("+" + slp.levels + " level" + pluralise + " from free skill pick at 1st level");
                }
            })
        }

        // Skill levels purchased from spending skill points as level up
        const pluralise = (num: number) => num > 1 ? "s" : "";

        let skillPointAssignments: SkillPointAssignment[] = [];

        if (level > 1) {

            // loop through skills, add initial points from skill level picks at level 1
            this.skillLevels.forEach((sl) => {
                const thisSkillLevel = this.skillLevels.find((s) => s.skill === sl.skill);
                if (thisSkillLevel) {
                    let initialPoints = 0;
                    if (thisSkillLevel.level === 1) {
                        initialPoints = 1;
                    }
                    if (thisSkillLevel.level === 2) {
                        initialPoints = 3;
                    }
                    if (thisSkillLevel.level === 3) {
                        initialPoints = 6;
                    }
                    if (thisSkillLevel.level === 4) {
                        initialPoints = 10;
                    }
                    if (thisSkillLevel.level === 4) {
                        initialPoints = 15;
                    }
                    if (initialPoints !== 0) {
                        const newAssignment: SkillPointAssignment = {
                            skill: sl.skill,
                            attribute: "",
                            pointsFromSkillPicks: initialPoints,
                            pointsFromSkillPoints: 0,
                            notes: []
                        };
                        skillPointAssignments.push(newAssignment);
                    }
                }
            })

            this.charTraits.levels.forEach((lev) => {
                if (lev.level <= level) {

                    // loop through skill point spends and add up the total points invested in each skill
                    if (!isLevelFocus) {

                        let skillPointSpends = lev.skillPointSpends;

                        // Used to exclude bonus skill points provioded by foci, whih alre permitted to pass the character level limit.
                        if (withoutFocusBonusSkillPoints) {
                            skillPointSpends = skillPointSpends.filter((sps) => sps.pointType.indexOf("_skill") === -1);
                        }

                        let indexOnLastLevel = lev.skillPointSpends.length;
                        if (lev.level === level) {
                            if (excludeSkillPointBuys) {
                                indexOnLastLevel = -1;
                            } else {
                                indexOnLastLevel = propIndex;
                            }
                        }

                        skillPointSpends.forEach((slp, thisIndex) => {
                            if (thisIndex <= indexOnLastLevel) {

                                if (slp.spendType === "improveSkill") {

                                    const note = slp.pointsSpent + " " + convertPointTypeToName(slp.pointType) + " skill point" + this.pluralise(slp.pointsSpent) + " assigned at level " + lev.level;

                                    let thisSkillPointAssignment = skillPointAssignments.find((spa) => spa.skill === slp.skillName);
                                    if (thisSkillPointAssignment) {
                                        thisSkillPointAssignment.pointsFromSkillPoints = thisSkillPointAssignment.pointsFromSkillPoints + slp.pointsSpent;
                                        thisSkillPointAssignment.notes.push(note);
                                    }
                                    else {
                                        const newAssignment: SkillPointAssignment = {
                                            skill: slp.skillName,
                                            attribute: "",
                                            pointsFromSkillPicks: 0,
                                            pointsFromSkillPoints: slp.pointsSpent,
                                            notes: [note]
                                        };
                                        skillPointAssignments.push(newAssignment);
                                    }

                                }

                            }
                        })
                    }

                }
            })
        }

        // figure out the skill level increases

        this.skillPointAssignments = skillPointAssignments;

        skillPointAssignments.forEach((spa) => {

            const thisSkillLevel = this.skillLevels.find((s) => s.skill === spa.skill);
            if (thisSkillLevel) {
                if (thisSkillLevel.level === null) { thisSkillLevel.level = 0 };
                const currentSkillLevel = thisSkillLevel.level - 1; // -1 = no skill, 0 = level-0, etc.

                let skillLevelsIncreased = 0;
                let pointsInvested = 0;

                if (currentSkillLevel === -1) { // no skill levels from skill picks or foci at level 1
                    if (spa.pointsFromSkillPoints >= 1) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 1;
                    }
                    if (spa.pointsFromSkillPoints >= 3) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 3;
                    }
                    if (spa.pointsFromSkillPoints >= 6) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 6;
                    }
                    if (spa.pointsFromSkillPoints >= 10) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 10;
                    }
                    if (spa.pointsFromSkillPoints >= 15) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 15;
                    }
                }

                if (currentSkillLevel === 0) { // level-0 from skill picks or foci at level 1
                    if (spa.pointsFromSkillPoints >= 2) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 2;
                    }
                    if (spa.pointsFromSkillPoints >= 5) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 5;
                    }
                    if (spa.pointsFromSkillPoints >= 9) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 9;
                    }
                    if (spa.pointsFromSkillPoints >= 14) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 14;
                    }
                }

                if (currentSkillLevel === 1) { // level-1 from skill picks or foci at level 1
                    if (spa.pointsFromSkillPoints >= 3) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 3;
                    }
                    if (spa.pointsFromSkillPoints >= 7) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 7;
                    }
                    if (spa.pointsFromSkillPoints >= 12) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 12;
                    }
                }

                if (currentSkillLevel === 2) { // level-2 from skill picks or foci at level 1
                    if (spa.pointsFromSkillPoints >= 4) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 4;
                    }
                    if (spa.pointsFromSkillPoints >= 9) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 9;
                    }
                }

                if (currentSkillLevel === 3) { // level-3 from skill picks or foci at level 1
                    if (spa.pointsFromSkillPoints >= 5) {
                        skillLevelsIncreased = skillLevelsIncreased + 1;
                        pointsInvested = 5;
                    }
                }

                const pointsOver = spa.pointsFromSkillPoints - pointsInvested;
                const pointsOverNote = pointsOver > 0 ? " with " + pointsOver + " skill point" + this.pluralise(pointsOver) + " towards next skill level" : "";

                if (skillLevelsIncreased > 0) {
                    thisSkillLevel.level += skillLevelsIncreased;
                    thisSkillLevel.history.push("+" + skillLevelsIncreased + " level" + pluralise(skillLevelsIncreased) + " from " + pointsInvested + " skill point" + pluralise(pointsInvested) + pointsOverNote + " (" + spa.notes.join("; ") + ")");
                }

            }
        })

    }

    calculateFocusLevels = (step: CreationStep, classNumber: number = -1, level: number = -1) => {

        this.focusLevels = [];

        // Calculate each focus's final level and history.

        // Class foci from level one:

        if (CreationStep.Classes <= step) {
            this.charTraits.levelOne.classes.forEach((c, index) => {
                if (step !== CreationStep.Classes || index <= classNumber) {
                    if (c.classFocusLevelPick && c.classFocusLevelPick.focus !== "") {

                        let grantedSkill: any = undefined;
                        if (c.classFocusLevelPick.grantsSkill) {
                            if (c.classFocusLevelPick.skillLevelPicks && c.classFocusLevelPick.skillLevelPicks.length > 0) {
                                grantedSkill = c.classFocusLevelPick.skillLevelPicks[0].skill;
                            }
                            if (c.classFocusLevelPick.skillPointsPicks && c.classFocusLevelPick.skillPointsPicks.length > 0) {
                                grantedSkill = c.classFocusLevelPick.skillPointsPicks[0].skill;
                            }
                        }

                        let gainedAtLevel: any = undefined;
                        if (c.classFocusLevelPick.focus === "Psychic Training") {
                            gainedAtLevel = 1;
                        }

                        const newFocusLevel: FocusLevel = { focus: c.classFocusLevelPick.focus, level: c.classFocusLevelPick.level, history: ["Level " + c.classFocusLevelPick.level + " from " + c.className + " class at 1st level"], grantedSkill, gainedAtLevel };
                        this.focusLevels.push(newFocusLevel);
                    }
                }
            });
        }

        // Free focus from level one:
        if (CreationStep.FreeFocus <= step) {
            const freeFocusLevel = this.charTraits.levelOne.freeFocusLevelPick;
            if (freeFocusLevel.focus !== "") {

                let grantedSkill: any = undefined;
                if (freeFocusLevel.grantsSkill) {
                    if (freeFocusLevel.skillLevelPicks && freeFocusLevel.skillLevelPicks.length > 0) {
                        grantedSkill = freeFocusLevel.skillLevelPicks[0].skill;
                    }
                    if (freeFocusLevel.skillPointsPicks && freeFocusLevel.skillPointsPicks.length > 0) {
                        grantedSkill = freeFocusLevel.skillPointsPicks[0].skill;
                    }
                }

                let gainedAtLevel: any = undefined;
                if (freeFocusLevel.focus === "Psychic Training") {
                    gainedAtLevel = 1;
                }

                const newFocusLevel: FocusLevel = { focus: freeFocusLevel.focus, level: freeFocusLevel.level, history: ["Level " + freeFocusLevel.level + " from free focus level at 1st level"], grantedSkill, gainedAtLevel };
                this.focusLevels.push(newFocusLevel);
            }
        }

        // Foci from levels at 2, 5, 7 and 10
        if (CreationStep.AllSteps <= step) {
            this.charTraits.levels.forEach((lev) => {
                if (level === -1 || (level !== -1 && lev.level <= level)) {
                    const levelFocusLevel = lev.focusLevelPick;
                    if (levelFocusLevel && levelFocusLevel.focus && levelFocusLevel.focus !== "") {

                        let grantedSkill: any = undefined;
                        if (levelFocusLevel.grantsSkill) {
                            if (levelFocusLevel.skillLevelPicks.length > 0) {
                                grantedSkill = levelFocusLevel.skillLevelPicks[0].skill;
                            }
                            if (levelFocusLevel.skillPointsPicks.length > 0) {
                                grantedSkill = levelFocusLevel.skillPointsPicks[0].skill;
                            }
                        }

                        let gainedAtLevel: any = undefined;
                        if (levelFocusLevel.focus === "Psychic Training") {
                            gainedAtLevel = lev.level;
                        }

                        const newFocusLevel: FocusLevel = { focus: levelFocusLevel.focus, level: levelFocusLevel.level, history: ["Level " + levelFocusLevel.level + " from focus gained at level " + lev.level], grantedSkill, gainedAtLevel };
                        this.focusLevels.push(newFocusLevel);
                    }
                }
            })
        }

    }

    calculatePsychicTechniqueLevels = (step: CreationStep, level: number, pickNumber: number) => {
        this.psychicTechniqueLevels = [];

        const lookups = Lookups.getInstance();

        // Calculate each Psychic Technique's final level and history.
        if (CreationStep.PsychicDisciplines <= step) {
            // this.removeAnyNulls();
            this.removeAnyTechniquePicksThatDoNotHaveRequiredSource();
            this.addPsychicTechniquesAtLevel1(step);
            this.addPsychicTechniquesAtLevel2AndHigher(level, pickNumber, lookups);
            this.addWildTalentTechniques(lookups);
            this.addCoreTechniques(lookups);
            this.addPsychicTechniquesPurchasedWithSkillPoints(level, pickNumber, lookups);
            this.removeAnyDuplicateTechniques();
            this.sortTechniques();
        }
    }

    removeAnyTechniquePicksThatDoNotHaveRequiredSource = () => {

        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100);

        this.charTraits.levels.forEach((lev) => {
            lev.skillPointSpends.forEach((sps) => {
                if (sps.psychicTechniquePicks) {
                    sps.psychicTechniquePicks = sps.psychicTechniquePicks.filter((ptp) => {
                        // Picks from gaining skill levels:
                        if (ptp && ptp.sourceType) {
                            if (ptp.sourceType.indexOf("SkillLevel") >= -1) {
                                const sourceTypeArray = ptp.sourceType.split("-"); // e.g: 'SkillLevel-Precognition-2'
                                const reqSkillName = sourceTypeArray[1];
                                const reqSkillLevel = parseInt(sourceTypeArray[2]);
                                const matchingSkill = charDerivedStats.skillLevels.find((sl) => sl.skill === reqSkillName && this.nullToZero(sl.level) >= reqSkillLevel);
                                if (matchingSkill) {
                                    return true;
                                }
                            }
                        } else {
                            return true;
                        }
                        return false;
                    })
                }
            })

        })

    }

    nullToZero = (val: any) => {
        if (!val) {
            return 0;
        } else {
            return parseInt(val);
        }
    }

    addPsychicTechniquesAtLevel1 = (step: CreationStep) => {
        const charDerivedStatsAtLevel1 = new CharacterDerivedStats(this.charTraits);
        charDerivedStatsAtLevel1.calculateSkillLevels(step, 100, 100, 1);

        // Add techniques earned via Psychic skill levels > 0.
        this.charTraits.levelOne.psychicTechniquePicks.forEach((ptp, index) => {
            ptp.pickIndex = index;
            let history = "";
            let level = 0;
            if (ptp.level > 0) {
                level = ptp.level;
                history = "Technique gained when learned " + ptp.skill + "-" + level;
            }
            const newPsychicTechniqueLevel: PsychicTechniqueLevel = { skill: ptp.skill, level: level, technique: ptp.technique, history: [history], sourceType: ptp.sourceType };
            this.psychicTechniqueLevels.push(newPsychicTechniqueLevel);
        });
    }

    addPsychicTechniquesAtLevel2AndHigher = (level: number, skillPickNumber: number, lookups: Lookups) => {
        // add techniques gained by purchasing psychic skill levels when levelling up.
        this.charTraits.levels.forEach((lev) => {
            if (lev.level <= level) {

                // Add techniques earned via Psychic skill levels > 0 at this level
                if (lev.skillPointSpends) {
                    lev.skillPointSpends.forEach((sps, spsIndex) => {
                        if (spsIndex <= skillPickNumber) {
                            if (sps.psychicTechniquePicks) {
                                sps.psychicTechniquePicks.forEach((pt, ptpIndex) => {

                                    if (pt) {
                                        pt.pickIndex = ptpIndex;
                                        if (ptpIndex <= skillPickNumber) {
                                            const thisPsySkillTechniques = lookups.psychicTechniques.find((t) => t.skill === pt.skill);
                                            if (thisPsySkillTechniques) {

                                                // Higher-level techniques:
                                                const thisTechnique = thisPsySkillTechniques.techniques.find((t) => t.name === pt.technique);
                                                if (thisTechnique) {
                                                    const history = pt.note;
                                                    const newPsychicTechniqueLevel: PsychicTechniqueLevel = { skill: pt.skill, level: thisTechnique.level, technique: thisTechnique.name, history: [history], sourceType: pt.sourceType };
                                                    this.psychicTechniqueLevels.push(newPsychicTechniqueLevel);
                                                }
                                            }
                                        }
                                    }

                                })
                            }
                        }

                    })
                }

            }
        })
    }

    addWildTalentTechniques = (lookups: Lookups) => {
        // Add techniques earned via Wild Psychic Talent focus:
        const addTechniqueDescription = (techniqueName: string, focusLevel: number) => {
            if (techniqueName !== "") {
                // Check if it's a core technique:
                const thisWildTalent_CoreTech = lookups.psychicTechniques.find((t) => t.coreTechnique.name === techniqueName);
                if (thisWildTalent_CoreTech) {
                    const history = "Core technique granted by Wild Psychic Talent focus (level-" + focusLevel + ")";
                    const newPsychicTechniqueLevel: PsychicTechniqueLevel = { skill: thisWildTalent_CoreTech.skill, level: 0, technique: thisWildTalent_CoreTech.coreTechnique.name, history: [history], sourceType: "WildTalent-1" };
                    this.psychicTechniqueLevels.push(newPsychicTechniqueLevel);
                }

                // Check if it's a non-core technique:
                const psychicDiscipline = lookups.psychicTechniques.find((t) => t.skill === this.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline);
                if (psychicDiscipline) {
                    const thisWildTalent_NonCoreTech = psychicDiscipline.techniques.find((t) => t.name === techniqueName);
                    if (thisWildTalent_NonCoreTech) {
                        const history = "Technique granted by Wild Psychic Talent focus (level-" + focusLevel + ")";
                        const newPsychicTechniqueLevel: PsychicTechniqueLevel = { skill: psychicDiscipline.skill, level: thisWildTalent_NonCoreTech.level, technique: thisWildTalent_NonCoreTech.name, history: [history], sourceType: "WildTalent-2" };
                        this.psychicTechniqueLevels.push(newPsychicTechniqueLevel);
                    }
                }
            }
        }

        addTechniqueDescription(this.charTraits.levelOne.wildTalentPicks.wildTalentTechnique1, 1);
        addTechniqueDescription(this.charTraits.levelOne.wildTalentPicks.wildTalentTechnique2, 2);
    }

    addCoreTechniques = (lookups: Lookups) => {

        let isWildTalent = false;
        const wildTalentFocuses = this.focusLevels.filter((f) => f.focus === "Wild Psychic Talent");
        if (wildTalentFocuses.length > 0) { isWildTalent = true; }

        if (!isWildTalent) {
            // Add all core techniques for any psychic skills at Level-0 or better
            const getAllPsychicSkills = () => {
                const charDerivedStatsAtThisClass = new CharacterDerivedStats(this.charTraits);
                charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100);
                const allCharactersSkills = charDerivedStatsAtThisClass.skillLevels;
                const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
                const allCharactersPsychicSkills = allCharactersSkills.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));
                return allCharactersPsychicSkills;
            }
            // remove all existing core techniques:
            this.psychicTechniqueLevels = this.psychicTechniqueLevels.filter((ut) => ut.level !== 0);
            // add the core technique for each Psychic skill
            const allPsychicSkills = getAllPsychicSkills();
            allPsychicSkills.forEach((ps) => {
                // add the core technique for the skill
                const allTechniques = lookups.psychicTechniques;
                const theCoreTech = allTechniques.find((t) => t.skill === ps.skill);
                if (theCoreTech && theCoreTech.coreTechnique) {
                    const newPsychicTechniqueLevel: PsychicTechniqueLevel = { skill: ps.skill, level: 0, technique: theCoreTech?.coreTechnique.name, history: ["Core technique gained when learned " + ps.skill + "-0"], sourceType: "SkillLevel-" + ps.skill + "-0" };
                    this.psychicTechniqueLevels.push(newPsychicTechniqueLevel);
                }
            });
        }
    }

    getPointsSpentOnTechnique = (technique: string, level: number, index: number) => {
        let pointsSpentOnTheTechnique = 0;
        this.charTraits.levels.forEach((lev) => {
            if (lev.level <= level) {

                // Get skill points assigned as level up
                lev.skillPointSpends.forEach((slp, thisIndex) => {
                    let maxIndex = 100;
                    if (lev.level === level) { maxIndex = index; }
                    if (thisIndex <= maxIndex) {
                        if (slp.techniqueName === technique) {
                            pointsSpentOnTheTechnique = pointsSpentOnTheTechnique + slp.pointsSpent;
                        }
                    }
                })
            }
        })
        return pointsSpentOnTheTechnique;
    }

    addPsychicTechniquesPurchasedWithSkillPoints = (level: number, pickNumber: number, lookups: Lookups) => {

        interface techniqueAdded { level: number, skillPickNumber: number, technique: string };
        const techniquesAdded: techniqueAdded[] = [];

        this.charTraits.levels.forEach((lev) => {
            if (lev.level <= level) {
                lev.skillPointSpends.forEach((ps, index) => {

                    let indexMax = 100;
                    if (level === lev.level) { indexMax = pickNumber; }

                    if (index <= indexMax) {
                        if (ps.spendType === "learnTechnique" && ps.techniqueName !== "") {

                            // Check user has paid total cost of the technique (possibly over multiple skill spends).
                            let techniqueCost = 0;
                            const allTechniques = lookups.psychicTechniques;
                            const techniquesForSkill = allTechniques.find((t) => t.skill === ps.skillName);
                            const theTechnique = techniquesForSkill?.techniques.find((t) => t.name === ps.techniqueName);
                            if (theTechnique) { techniqueCost = theTechnique.level; }

                            let hasPaidForTechnique = false;

                            const pointsSpentOnTechnique = this.getPointsSpentOnTechnique(ps.techniqueName, lev.level, index);

                            if (pointsSpentOnTechnique >= techniqueCost) { hasPaidForTechnique = true; }

                            const techniqueAlreadyKnown = this.psychicTechniqueLevels.find((ptl) => ptl.technique === ps.techniqueName);
                            if (hasPaidForTechnique && !techniqueAlreadyKnown) {
                                const newPsychicTechniqueLevel: PsychicTechniqueLevel = { skill: ps.skillName, level: techniqueCost, technique: ps.techniqueName, history: ["Technique purchased with skill points at level " + lev.level], sourceType: "PurchasedWithSkillPoints-" + ps.techniqueName };
                                this.psychicTechniqueLevels.push(newPsychicTechniqueLevel);
                                techniquesAdded.push({ level, skillPickNumber: index, technique: ps.techniqueName });
                            }

                        }
                    }

                })
            }

        });

        // // Remove added techniques from any later skill point spends; 
        // if (techniquesAdded.length > 0) {
        //     techniquesAdded.forEach((t) => {
        //         this.charTraits = deleteTechniqueFromPsychicTechniquePicksAtLaterLevelsAndIndexes(t.level, t.skillPickNumber, -1, t.technique, this.charTraits);
        //         this.charTraits = deleteTechniqueFromSkillPointSpendsAtLaterLevelsAndIndexes(level, t.skillPickNumber, t.technique, this.charTraits);
        //     })
        // }

    }

    sortTechniques = () => {

        // Sort:
        const techSort = (pt1: PsychicTechniqueLevel, pt2: PsychicTechniqueLevel) => {
            if (pt1.skill < pt2.skill) {
                return -1
            } else if (pt1.skill === pt2.skill) {
                if (pt1.level < pt2.level) {
                    return -1
                } else if (pt1.level === pt2.level) {
                    if (pt1.technique < pt2.technique) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                } else {
                    return 1;
                }
            } else {
                return 1;
            }
        }

        let isWildTalent = false;
        const wildTalentFocuses = this.focusLevels.filter((f) => f.focus === "Wild Psychic Talent");
        if (wildTalentFocuses.length > 0) { isWildTalent = true; }

        if (!isWildTalent) {
            this.psychicTechniqueLevels.sort(techSort);
        }
    }

    removeAnyDuplicateTechniques = () => {
        // Remove any duplicate techniques at all levels
        this.psychicTechniqueLevels = this.psychicTechniqueLevels.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.skill === value.skill && t.level === value.level && t.technique === value.technique
            ))
        )
    }

    calculateHitPointsLevels = (step: CreationStep, level: number) => {
        this.hitPointsLevels = [{ level: 1, hitPoints: 0, totalHitPointsAtLevel: 0, history: [], didNotBeatPreviousLevelHP: false }];

        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);
        charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);
        charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, 100, 100);

        // Level 1 

        // HP rolled at 1st level
        const levelOneHP = this.hitPointsLevels.find((hp) => hp.level === 1);
        if (levelOneHP) {
            levelOneHP.hitPoints = this.charTraits.levelOne.rolledHitPoints;
            levelOneHP.history.push("Rolled " + this.charTraits.levelOne.rolledHitPoints + " HP at 1st level");

            // Constitution modifier at 1st level
            const CON = charDerivedStats.attributeLevels[2].level;
            if (CON) {
                const CONmod = getAttributeModifierRaw("Constitution", CON, charDerivedStats);
                if (CONmod !== 0) {
                    const CONModStr = getAttributeModifierPlusMinus("Constitution", CON, charDerivedStats);
                    levelOneHP.hitPoints = levelOneHP.hitPoints + CONmod;
                    levelOneHP.history.push(CONModStr + " HP for CON mod.");
                }
            }

            // Bonus +2 HP for Warriors at 1st level
            const thisWarrior = this.charTraits.levelOne.classes.find((c) => c.className === "Warrior" || c.className === "Partial Warrior");
            if (thisWarrior) {
                levelOneHP.hitPoints = levelOneHP.hitPoints + 2;
                levelOneHP.history.push("+2 HP for " + thisWarrior.className + " class");
            }

            // Bonus +2 HP for Die Hard-1 focus.
            const dieHard1 = charDerivedStats.focusLevels.find((f) => f.focus === "Die Hard" && f.level === 1);
            if (dieHard1) {
                levelOneHP.hitPoints = levelOneHP.hitPoints + 2;
                levelOneHP.history.push("+2 HP for Die Hard (Level 1) focus");
            }

            // Round zero or negative HP up to 1
            if (levelOneHP.hitPoints <= 0) {
                levelOneHP.hitPoints = 1;
                levelOneHP.history.push("Minimum 1 HP at 1st level");
            }

            // If is a VI Vehicle Bot, has at least the HP of the vehicle.
            const isVIRobotVehicle = charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Vehicle Bot");
            if (isVIRobotVehicle) {
                const lookups = Lookups.getInstance();
                const theVehicle = lookups.gear.find((g) => g.names[0] === this.charTraits.levelOne.vehicleBody);
                if (theVehicle && theVehicle.hp && theVehicle.names[0]) {
                    const vehicleHP = theVehicle.hp;
                    if (levelOneHP.totalHitPointsAtLevel < vehicleHP) {
                        levelOneHP.totalHitPointsAtLevel = vehicleHP;
                        levelOneHP.hitPoints = vehicleHP;
                        levelOneHP.history.push(theVehicle.names[0] + " vehicle bot grants minimum " + vehicleHP + " HP");
                    }
                }
            }

            levelOneHP.totalHitPointsAtLevel = levelOneHP.hitPoints;
        }

        if (level > 1) {
            this.charTraits.levels.forEach((lev) => {

                let hpAtThisLevel = 0;
                const thisLevelHP: HitPointsLevel = { level: lev.level, hitPoints: 0, totalHitPointsAtLevel: 0, history: [], didNotBeatPreviousLevelHP: false };

                lev.rolledHitPoints.forEach((rhp, index) => {
                    // Apply Constitution modifier to each die
                    const CON = charDerivedStats.attributeLevels[2].level;
                    if (CON) {
                        const CONmod = getAttributeModifierRaw("Constitution", CON, charDerivedStats);
                        if (CONmod !== 0) {
                            const CONModStr = getAttributeModifierPlusMinus("Constitution", CON, charDerivedStats);
                            hpAtThisLevel = hpAtThisLevel + Math.max(rhp + CONmod, 1);
                            thisLevelHP.history.push("Die " + (index + 1) + ": " + rhp + " " + CONModStr + " CON = +" + Math.max(rhp + CONmod, 1) + " HP");
                        } else {
                            hpAtThisLevel = hpAtThisLevel + rhp;
                            thisLevelHP.history.push("Die " + (index + 1) + ": " + rhp + " HP");
                        }
                    } else {
                        hpAtThisLevel = hpAtThisLevel + rhp;
                        thisLevelHP.history.push("Die " + (index + 1) + ": " + rhp + " HP");
                    }
                })

                // Bonus +2 HP/level for Warriors and Partial Warriors
                const thisWarrior = this.charTraits.levelOne.classes.find((c) => c.className === "Warrior" || c.className === "Partial Warrior");
                if (thisWarrior) {
                    hpAtThisLevel = hpAtThisLevel + (2 * level);
                    thisLevelHP.history.push("+" + (2 * level) + " HP for " + thisWarrior.className + " class");
                }

                // Bonus +2 HP for Die Hard-1 focus.
                const dieHard1 = charDerivedStats.focusLevels.find((f) => f.focus === "Die Hard" && f.level === 1);
                if (dieHard1) {
                    hpAtThisLevel = hpAtThisLevel + (2 * level);
                    thisLevelHP.history.push("+" + (2 * level) + " HP for Die Hard (Level 1) focus");
                }

                // If is a VI Vehicle Bot, has at least the HP of the vehicle.
                let gotVehicleHP = false;
                const isVIRobotVehicle = charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Vehicle Bot");
                if (isVIRobotVehicle) {
                    const lookups = Lookups.getInstance();
                    const theVehicle = lookups.gear.find((g) => g.names[0] === this.charTraits.levelOne.vehicleBody);
                    if (theVehicle && theVehicle.hp && theVehicle.names[0]) {
                        const vehicleHP = theVehicle.hp;
                        if (hpAtThisLevel < vehicleHP) {
                            thisLevelHP.totalHitPointsAtLevel = vehicleHP;
                            thisLevelHP.history.push(theVehicle.names[0] + " vehicle bot grants minimum " + vehicleHP + " HP");
                            gotVehicleHP = true;
                        }
                    }
                }

                // Figure total HP at this level, depending on whether rolled better at this level than previous level
                if (!gotVehicleHP) {
                    if (level === 2 && levelOneHP) {
                        if (hpAtThisLevel > levelOneHP.totalHitPointsAtLevel) {
                            thisLevelHP.totalHitPointsAtLevel = hpAtThisLevel;
                        }
                        else {
                            thisLevelHP.totalHitPointsAtLevel = levelOneHP.totalHitPointsAtLevel + 1;
                            thisLevelHP.didNotBeatPreviousLevelHP = true;
                        }
                    } else {
                        const previousHitPointLevel = this.hitPointsLevels[lev.level - 2];
                        if (previousHitPointLevel) {
                            if (hpAtThisLevel > previousHitPointLevel.totalHitPointsAtLevel) {
                                thisLevelHP.totalHitPointsAtLevel = hpAtThisLevel;
                            }
                            else {
                                thisLevelHP.totalHitPointsAtLevel = previousHitPointLevel.totalHitPointsAtLevel + 1;
                                thisLevelHP.didNotBeatPreviousLevelHP = true;
                            }
                        } else {
                            thisLevelHP.totalHitPointsAtLevel = 99;
                        }
                    }
                }

                // Add the final results:
                thisLevelHP.hitPoints = hpAtThisLevel;
                this.hitPointsLevels.push(thisLevelHP);
            })
        }

    }

    calculateBaseAttack = (step: CreationStep) => {

        this.baseAttackLevels = { baseAttack: 0, history: [] };

        // Bonus +1 for Warriors at 1st level
        const thisWarrior = this.charTraits.levelOne.classes.find((c) => c.className === "Warrior");
        if (thisWarrior) {
            this.baseAttackLevels.baseAttack = 1;
            this.baseAttackLevels.history.push({ level: 1, history: "Base Attack +1 for " + thisWarrior.className + " class at level 1" });
        } else {
            this.baseAttackLevels.history.push({ level: 1, history: "Base Attack +0 for non-Warrior classes at level 1" });
        }

        // Partial Warrior gets +1 at level 1
        const thisPartialWarrior = this.charTraits.levelOne.classes.find((c) => c.className === "Partial Warrior");
        if (thisPartialWarrior) {
            this.baseAttackLevels.baseAttack = 1;
            this.baseAttackLevels.history.push({ level: 1, history: "extra +1 for " + thisPartialWarrior.className + " class at level 1" })
        };


        // Bonus +1 for Warriors for each additional level
        if (thisWarrior) {
            this.charTraits.levels.forEach((lev, index) => {
                this.baseAttackLevels.baseAttack = this.baseAttackLevels.baseAttack + 1;
                this.baseAttackLevels.history.push({ level: lev.level, history: "+1 for " + thisWarrior.className + " class at level " + (index + 2) });
            })
        } else {
            // Bonus +1 for Non-Warriors for each even-numbered additional level
            this.charTraits.levels.forEach((lev, index) => {
                if (lev.level % 2 === 0) {
                    this.baseAttackLevels.baseAttack = this.baseAttackLevels.baseAttack + 1;
                    this.baseAttackLevels.history.push({ level: lev.level, history: "+1 for non-Warrior class at level " + (index + 2) });
                }
                // Partial Warrior gets extra +1 at level 5
                if (thisPartialWarrior && lev.level === 5) {
                    this.baseAttackLevels.baseAttack = this.baseAttackLevels.baseAttack + 1;
                    this.baseAttackLevels.history.push({ level: lev.level, history: "extra +1 for " + thisPartialWarrior.className + " class at level 5" });
                }
            })
        }

        // +1 bonus from 'Aptitude for Violence' benefit from Alien focus
        if (this.charTraits.levelOne.freeFocusLevelPick.focus.indexOf("Alien - ") !== -1) {
            const lookups = Lookups.getInstance();
            const selectedFocusData = lookups.focuses.find((fd) => fd.focus === this.charTraits.levelOne.freeFocusLevelPick.focus);
            if (selectedFocusData?.benefits) {
                if (selectedFocusData.benefits.find((ben) => ben.benefit === "aptViolence")) {
                    this.baseAttackLevels.baseAttack = this.baseAttackLevels.baseAttack + 1;
                    this.baseAttackLevels.history.push({ level: 1, history: "extra +1 for Alien Focus with Aptitude For Violence benefit" });
                }
            }
        }

    }

    calculateSavingThrows = (step: CreationStep) => {

        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);
        charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, 100, 100);

        // Physical save at level 1:
        const str = charDerivedStats.attributeLevels[0];
        const dex = charDerivedStats.attributeLevels[1];
        const con = charDerivedStats.attributeLevels[2];
        const int = charDerivedStats.attributeLevels[3];
        const wis = charDerivedStats.attributeLevels[4];
        const cha = charDerivedStats.attributeLevels[5];

        const strMod = getAttributeModifierRaw("Strength", str.level !== null ? str.level : 0, charDerivedStats);
        const dexMod = getAttributeModifierRaw("Dexterity", dex.level !== null ? dex.level : 0, charDerivedStats);
        const conMod = getAttributeModifierRaw("Constitution", con.level !== null ? con.level : 0, charDerivedStats);
        const intMod = getAttributeModifierRaw("Intelligence", int.level !== null ? int.level : 0, charDerivedStats);
        const wisMod = getAttributeModifierRaw("Wisdom", wis.level !== null ? wis.level : 0, charDerivedStats);
        const chaMod = getAttributeModifierRaw("Charisma", cha.level !== null ? cha.level : 0, charDerivedStats);

        const levelAdj = this.charTraits.level - 1;

        const physical = Math.max(15 - Math.max(strMod, conMod) - levelAdj, 1);
        const mental = Math.max(15 - Math.max(wisMod, chaMod) - levelAdj, 1);
        const evasion = Math.max(15 - Math.max(dexMod, intMod) - levelAdj, 1);

        const levelText = " - level " + this.charTraits.level;

        const physMinNote = physical === 1 ? " (min. 1)" : "";
        const mentMinNote = physical === 1 ? " (min. 1)" : "";
        const evMinNote = physical === 1 ? " (min. 1)" : "";

        const physicalHistory = "16 - best of (STR modifier " + PlusMinus(strMod) + " or CON modifer " + PlusMinus(conMod) + ")" + levelText + physMinNote;
        const mentalHistory = "16 - best of (WIS modifier " + PlusMinus(wisMod) + " or CHA modifer " + PlusMinus(chaMod) + ")" + levelText + mentMinNote;
        const evasionHistory = "16 - best of (DEX modifier " + PlusMinus(dexMod) + " or INT modifer " + PlusMinus(intMod) + ")" + levelText + evMinNote;

        this.savingThrowLevels.physical = { score: physical, history: [physicalHistory] };
        this.savingThrowLevels.mental = { score: mental, history: [mentalHistory] };
        this.savingThrowLevels.evasion = { score: evasion, history: [evasionHistory] };

        // Add notes about save modifiers:

        // Metapsionics - Core Technique Psychic Refinement level-0 gives +2 vs psionic powers
        const psychicRefinement = charDerivedStats.psychicTechniqueLevels.find((pt) => pt.skill === "Metapsionics");
        if (psychicRefinement) {

            charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100, 100);
            const metapsionics = charDerivedStats.skillLevels.find((s) => s.skill === "Metapsionics");
            if (metapsionics) {
                if (metapsionics.level && metapsionics.level >= 3) {
                    this.savingThrowLevels.notes.push("Metapsionics core technique Psychic Refinement (level-2) gives a +3 bonus on any saving throw versus a psionic power");
                } else {
                    this.savingThrowLevels.notes.push("Metapsionics core technique Psychic Refinement (level-0) gives a +2 bonus on any saving throw versus a psionic power");
                }
            } else {
                // Wild Psychic Taslent can have the core technique without the skill. 
                this.savingThrowLevels.notes.push("Metapsionics core technique Psychic Refinement (level-0) gives a +2 bonus on any saving throw versus a psionic power");
            }
        }

    }

    calculateEffort = (step: CreationStep) => {
        let isPsychic = false;
        let isWildTalent = false;

        const psychicClasses = this.charTraits.levelOne.classes.filter((c) => c.className === "Psychic" || c.className === "Partial Psychic");
        if (psychicClasses.length > 0) { isPsychic = true; }

        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);
        charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, 100, 100);
        charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100, 100);
        charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, 100, 100);
        const derivedAttributeLevels = charDerivedStats.attributeLevels;
        const derivedFocusLevels = charDerivedStats.focusLevels;
        const derivedSkillLevels = charDerivedStats.skillLevels;
        const derivedTechniqueLevels = charDerivedStats.psychicTechniqueLevels;

        const wildTalentFocuses = derivedFocusLevels.filter((f) => f.focus === "Wild Psychic Talent");
        if (wildTalentFocuses.length > 0) { isWildTalent = true; }

        if (isPsychic) {
            // maximum Effort score equal to 1 plus their highest psychic skill plus the better of their Wisdom or Constitution modifiers.
            // Metapsionics core technique level-1 gives an extra +1. 
            const wis = derivedAttributeLevels[4];
            const wisMod = getAttributeModifierRaw("Wisdom", wis.level !== null ? wis.level : 0, charDerivedStats);
            const con = derivedAttributeLevels[2];
            const conMod = getAttributeModifierRaw("Constitution", con.level !== null ? con.level : 0, charDerivedStats);

            const lookups = Lookups.getInstance();
            const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
            const charactersPsychicSkills = derivedSkillLevels.filter((s) => allPsychicSkills.map((s) => s.skill).indexOf(s.skill) !== -1);

            let highestPsychicSkillLevel = 0;
            let highestPsychicSkills: SkillLevel[] = [];
            if (charactersPsychicSkills) {
                // Find highest psychic skill levels

                charactersPsychicSkills.forEach((sl) => {
                    if (sl.level && sl.level > highestPsychicSkillLevel) { highestPsychicSkillLevel = sl.level; }
                })
                highestPsychicSkills = charactersPsychicSkills.filter((sl => sl.level === highestPsychicSkillLevel));
            }

            highestPsychicSkillLevel = Math.max(highestPsychicSkillLevel - 1, 0);

            let highestSkillsList = highestPsychicSkills.map((s) => s.skill).join("/");
            if (highestSkillsList === "") { highestSkillsList = "n/a"; }

            this.effortLevels.effort = 1 + highestPsychicSkillLevel + Math.max(wisMod, conMod)

            this.effortLevels.history.push("Base 1 for " + psychicClasses[0].className);
            this.effortLevels.history.push("+" + highestPsychicSkillLevel + " for highest psychic skill level (" + highestSkillsList + ")");
            this.effortLevels.history.push(PlusMinus(Math.max(wisMod, conMod)) + " for highest of WIS modifier " + PlusMinus(wisMod) + " and CON modifer " + PlusMinus(conMod))
        }

        if (isWildTalent) {
            wildTalentFocuses.forEach((f) => {
                if (f.level === 1) {
                    this.effortLevels.effort = 1;
                    this.effortLevels.history.push("1 for Wild Talent Focus (level-1)");
                }
                if (f.level === 2) {
                    this.effortLevels.effort = 2;
                    this.effortLevels.history.push("+1 for Wild Talent Focus (level-2)");
                }
            })
        }

        const psychicTrainingFocuses = derivedFocusLevels.filter((f) => f.focus === "Psychic Training");
        if (psychicTrainingFocuses) {
            psychicTrainingFocuses.forEach((f) => {
                if (f.level === 1) {
                    this.effortLevels.effort = this.effortLevels.effort + 1;
                    this.effortLevels.history.push("+1 for Psychic Training Focus (level-1)");
                }
            })
        }

        const psiRefinement1 = derivedTechniqueLevels.find((tl) => tl.technique === "Psychic Refinement" && tl.level === 1);
        if (psiRefinement1) {
            this.effortLevels.effort = this.effortLevels.effort + 1;
            this.effortLevels.history.push("+1 for Metapsionics Psi Refinement core technique Level-1");
        }

        // + 1 Effort if has Alien origin focus with the Psychic Aptitude benefit. 
        const alienFocus = derivedFocusLevels.find((f) => f.focus.indexOf("Alien -") !== -1);
        if (alienFocus) {
            const lookups = Lookups.getInstance();
            const selectedFocusData = lookups.focuses.find((fd) => fd.focus === alienFocus.focus);
            if (selectedFocusData?.benefits) {
                if (selectedFocusData.benefits.find((ben) => ben.benefit === "psyAptitude")) {
                    this.effortLevels.effort = this.effortLevels.effort + 1;
                    this.effortLevels.history.push("+1 for Alien origin focus with the Psychic Aptitude benefit");
                }
            }
        }

        if (this.effortLevels.effort < 1) {
            this.effortLevels.effort = 1;
            this.effortLevels.history.push("minimum 1");
        }
    }

    calculateArmorClassByType = (theType: string): ArmorClassLevel => {
        // Get all character's readied armor items. Take the highest AC.

        const getName = (a: EquipmentItem) => {
            if (a.customName && a.customName.trim() !== "") { return a.customName; }
            return a.name;
        }

        let armorItems: EquipmentItem[] = [];
        let ACL: ArmorClassLevel = { ac: 10, history: [], notes: [] };

        if (theType === "All") {
            armorItems = this.charTraits.gear.equipment.filter((g) => g.type === "Armor" || g.name.indexOf("Dermal Armor") !== -1);
        }

        if (theType === "Advanced") {
            armorItems = this.charTraits.gear.equipment.filter((g) => (g.type === "Armor" || g.name.indexOf("Dermal Armor") !== -1) && g.subtype !== "Primitive Armor");
        }

        // Get AC based only on items that do not grant an AC bonus (like shields)
        let bestAC = 10;
        let bestACHistory_baseAC = "";
        armorItems.forEach((a) => {
            if (a?.baseAC && !a.bonusToAC) {
                const storQuant = a.storageQuantity.find((sq) => sq.storage === "Readied");
                if (storQuant) {
                    if (storQuant.quantity > 0) {
                        if (a.baseAC > bestAC) {
                            bestAC = a.baseAC;
                            bestACHistory_baseAC = storQuant.storage + " " + getName(a) + " gives base AC " + bestAC;
                        }
                    }
                }
            }
        })

        // Ironhide 1 focus grants a base AC of 15 (which does not stack with armour, though Dexterity or shield modifiers apply.)
        let hasIronHide = false;
        let ironHideLevel = 0;
        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);
        charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100, 100);
        charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, 100, 100);

        const derivedFocusLevels = charDerivedStats.focusLevels;
        const ironHideFocuses = derivedFocusLevels.filter((f) => f.focus === "Ironhide");
        if (ironHideFocuses.length > 0) {
            hasIronHide = true;
            ironHideFocuses.forEach((f) => {
                ironHideLevel = f.level;
            })
        }
        if (hasIronHide) {
            if (bestAC < 15) {
                const level = this.charTraits.level;
                const ironHideBaseAC = 15 + (Math.round(level / 2));
                bestAC = ironHideBaseAC;
                bestACHistory_baseAC = "Ironhide focus gives base AC " + ironHideBaseAC + " (15 + 1/2 level)";
                if (ironHideLevel === 2) {
                    bestACHistory_baseAC += " and you are immune to unarmed attacks or primitive weaponry as if you wore powered armor"
                }
            }
        }

        // Alien focus benefit 'Natural Defenses (Armor)' gives base AC 15 plus half level rounded up.
        let hasNaturalDefensesArmor = false;
        const alienFocus = derivedFocusLevels.find((f) => f.focus.indexOf("Alien -") !== -1);
        if (alienFocus) {
            const lookups = Lookups.getInstance();
            const selectedFocusData = lookups.focuses.find((fd) => fd.focus === this.charTraits.levelOne.freeFocusLevelPick.focus);
            if (selectedFocusData?.benefits) {
                if (selectedFocusData.benefits.find((ben) => ben.benefit === "natDefArmor")) {
                    hasNaturalDefensesArmor = true;
                }
            }
        }
        if (hasNaturalDefensesArmor) {
            if (bestAC < 15) {
                const level = this.charTraits.level;
                const natDefAC = 15 + (Math.round(level / 2));
                bestAC = natDefAC;
                bestACHistory_baseAC = "Alien focus with 'Natural Defenses (Armor)' benefit gives base AC " + natDefAC + " (15 + 1/2 level)";
            }
        }

        // Dermal Armor (TL5) cyberware gives AC 16 plus immunity to primitive weapon damage
        const hasDermalArmorTL5 = this.charTraits.gear.equipment.find((g) => g.name.indexOf("Dermal Armor (TL5)") !== -1);
        if (hasDermalArmorTL5) {
            if (bestAC < 16) {
                bestAC = 16;
                bestACHistory_baseAC = "Dermal Armor (TL5) cyberware gives base AC 16 and you are immune to damage from primitive weapons as though you wore powered armor";
            }
        } else {
            // Dermal Armor (TL4) cyberware gives AC 16 plus immunity to primitive weapon Shock
            const hasDermalArmorTL4 = this.charTraits.gear.equipment.find((g) => g.name.indexOf("Dermal Armor (TL4)") !== -1);
            if (hasDermalArmorTL4) {
                if (bestAC < 16) {
                    bestAC = 16;
                    bestACHistory_baseAC = "Dermal Armor (TL4) cyberware gives base AC 16 and you are immune to Shock from primitive weapons";
                }
            }
        }

        // If the character's AC is less than or equal to the AC bonus for any readied armor, add that to AC.
        let bonusToAC = 0;
        let bestACHistory_bonusAC = "";
        armorItems.forEach((a) => {
            if (a?.bonusToAC) {  // Only items with bonus to AC
                const storQuant = a.storageQuantity.find((sq) => sq.storage === "Readied");
                if (storQuant) {
                    if (a.bonusToAC && a.baseAC) {
                        if (a.baseAC <= bestAC) { // If item has base AC less or equal to AC from other armour, only add the AC bonus
                            bonusToAC = a.bonusToAC;
                            bestACHistory_bonusAC = storQuant.storage + " " + getName(a) + " gives +" + bonusToAC + " AC";
                        } else {
                            if (a.baseAC >= bestAC) { // If the item grants base AC better than otehr armour, use that AC
                                bestAC = a.baseAC;
                                bestACHistory_baseAC = storQuant.storage + " " + getName(a) + " gives base AC " + bestAC;
                            }
                        }

                    }
                }

            }
        })

        // mods: 
        armorItems.forEach((a) => {
            if (a.mods) {
                const lookups = Lookups.getInstance();
                a.mods.forEach((mod) => {
                    const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                    if (theMod) {
                        if (theMod.tags?.includes("+1ToAC")) {
                            bestAC = bestAC + 1;
                            bestACHistory_baseAC = bestACHistory_baseAC + "; " + theMod.name + " mod gives +1 AC";
                        }
                    }
                })
            }
        });


        ACL.ac = bestAC;
        if (bestACHistory_baseAC !== "") {
            ACL.history.push(bestACHistory_baseAC);
        } else {
            ACL.history.push("Unarmored base AC 10");
        }

        ACL.ac = ACL.ac + bonusToAC;
        if (bestACHistory_bonusAC !== "") {
            ACL.history.push(bestACHistory_bonusAC);
        }

        // Add DEX modifier.
        charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);

        const dex = charDerivedStats.attributeLevels[1];
        const dexMod = getAttributeModifierRaw("Dexterity", dex.level !== null ? dex.level : 0, charDerivedStats);

        if (dexMod !== 0) {
            ACL.ac = ACL.ac + dexMod;
            ACL.history.push("Dexterity gives " + PlusMinus(dexMod) + " AC");
        }

        // If the armor is Telekinetic Armory armor, add the user's Telekinesis score to AC:
        if (charDerivedStats.psychicTechniqueLevels.find((tl) => tl.technique === "Telekinetic Armory")) {
            let telekinesisSkill = 0;
            const telekinesisSkillLevel = charDerivedStats.skillLevels.find((sl) => sl.skill === "Telekinesis");
            if (telekinesisSkillLevel && telekinesisSkillLevel.level !== null) {
                telekinesisSkill = telekinesisSkillLevel.level - 1;
            }

            ACL.ac = ACL.ac + telekinesisSkill;
            if (telekinesisSkill > 0) {
                ACL.history.push("Telekinesis skill level gives " + PlusMinus(telekinesisSkill) + " AC");
            }
        }

        return ACL;
    }

    calculateArmorClassVsPrimitiveWeapons = (step: CreationStep) => {

        this.armorClassLevels = this.calculateArmorClassByType("All");
    }

    calculateArmorClassVsAdvancedWeapons = (step: CreationStep) => {

        this.armorClassLevelsVsTL4 = this.calculateArmorClassByType("Advanced");

        const getName = (a: EquipmentItem) => {
            if (a.customName && a.customName.trim() !== "") { return a.customName; }
            return a.name;
        }

        // Add notes regarding TL effects, etc.

        const primitiveArmorItems = this.charTraits.gear.equipment.filter((g) => {
            const storQuant = g.storageQuantity.find((sq) => sq.storage === "Readied");
            if (storQuant) {
                if (storQuant.quantity > 0) {
                    return g.type === "Armor" && g.subtype && g.subtype === "Primitive Armor";
                }
            }
            return false;
        });

        const primitiveArmors = primitiveArmorItems.map((a) => getName(a)).join(", ");
        if (primitiveArmors.length > 0) {
            const note = "Primitive armor (" + primitiveArmors + ") does not protect against TL4+ melee weapons and all firearms";
            this.armorClassLevelsVsTL4.notes.push(note);
        }

    }

    getTotalEncumbrance = (storageType: string): number => {

        let total = 0;

        const getByStorage = (e: EquipmentItem, storage: string) => {
            const storQuant = e.storageQuantity.find((sq) => sq.storage === storage);
            if (storQuant) { return true; }
            return false;
        }

        const items = this.charTraits.gear.equipment.filter((i) => getByStorage(i, storageType));
        if (items.length > 0) {
            items.forEach((i) => {
                const storQuant = i.storageQuantity.find((sq) => sq.storage === storageType);
                if (storQuant) {
                    if (i.enc === -1) {
                        total = total + 0;
                    } else if (i.enc % 1 !== 0) {
                        // Fractional .33 items count as 1 per 3 items (in a pack)
                        total = total + Math.ceil((i.enc * storQuant.quantity));
                    }
                    else {

                        let enc = i.enc;
                        total = total + enc * storQuant.quantity;
                    }
                }
            });
        };

        return total;
    }

    calculateEncumbrance = (step: CreationStep) => {

        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);
        const strAttribute: AttributeLevel = charDerivedStats.attributeLevels[0];
        let str = 0;
        if (strAttribute && strAttribute.level) { str = strAttribute.level; }

        // Readied Storm Armor gives +4 STR for encumbrance purposes.
        const stormArmor = this.charTraits.gear.equipment.find((i) => i.id === "A16");
        if (stormArmor) {
            const storQuant = stormArmor.storageQuantity.find((sq) => sq.storage === "Readied");
            if (storQuant) { str = str + 4; }
        }

        this.readiedEquipmentEncumbrance = this.getTotalEncumbrance("Readied");
        this.readiedUnencumberedLimit = Math.floor(str / 2);
        this.readiedLightlyEncumberedLimit = this.readiedUnencumberedLimit + 2;
        this.readiedHeavilyEncumberedLimit = this.readiedLightlyEncumberedLimit + 2;

        this.stowedEquipmentEncumbrance = this.getTotalEncumbrance("Stowed");
        this.stowedUnencumberedLimit = str;
        this.stowedLightlyEncumberedLimit = this.stowedUnencumberedLimit + 4;
        this.stowedHeavilyEncumberedLimit = this.stowedLightlyEncumberedLimit + 4;

        let worstEncLevel = 0;
        if (this.readiedEquipmentEncumbrance > this.readiedUnencumberedLimit) { worstEncLevel = 1; }
        if (this.readiedEquipmentEncumbrance > this.readiedLightlyEncumberedLimit) { worstEncLevel = 2; }
        if (this.readiedEquipmentEncumbrance > this.readiedHeavilyEncumberedLimit) { worstEncLevel = 3; }

        if (this.stowedEquipmentEncumbrance > this.stowedUnencumberedLimit) { worstEncLevel = 1; }
        if (this.stowedEquipmentEncumbrance > this.stowedLightlyEncumberedLimit) { worstEncLevel = 2; }
        if (this.stowedEquipmentEncumbrance > this.stowedHeavilyEncumberedLimit) { worstEncLevel = 3; }

        let encLevel = "";
        let baseMove = 0;
        switch (worstEncLevel) {
            case 0: encLevel = "Unencumbered"; baseMove = 10; break;
            case 1: encLevel = "Lightly Encumbered"; baseMove = 7; break;
            case 2: encLevel = "Heavily Encumbered"; baseMove = 5; break;
            case 3: encLevel = "Immobilized"; baseMove = 0; break;
            default: encLevel = worstEncLevel.toString();
        }
        this.encumbranceLevel = encLevel;
        this.encumbranceLevelBaseMove = baseMove;
    }

    calculateRangedWeaponStats = (step: CreationStep, longNotes: boolean) => {

        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);
        charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100, 100);
        charDerivedStats.calculateBaseAttack(CreationStep.AllSteps);
        charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);

        const baseAttack = charDerivedStats.baseAttackLevels.baseAttack;

        let shootSkill = -2;
        let shootSkillDesc = "";
        const shootSkillLevel = charDerivedStats.skillLevels.find((sl) => sl.skill === "Shoot");
        if (shootSkillLevel && shootSkillLevel.level !== null) {
            shootSkill = shootSkillLevel.level - 1;
            shootSkillDesc = "Shoot skill level " + shootSkill;
        } else {
            shootSkillDesc = "No Shoot skill level";
        }

        let telekinesisSkill = -2;
        let telekinesisSkillDesc = "";
        const telekinesisSkillLevel = charDerivedStats.skillLevels.find((sl) => sl.skill === "Telekinesis");
        if (telekinesisSkillLevel && telekinesisSkillLevel.level !== null) {
            telekinesisSkill = telekinesisSkillLevel.level - 1;
            telekinesisSkillDesc = "Telekinesis skill level " + telekinesisSkill;
        } else {
            telekinesisSkillDesc = "No Telekinesis skill level";
        }

        let dex = 0;
        const dexAttribute: AttributeLevel = charDerivedStats.attributeLevels[1];
        if (dexAttribute && dexAttribute.level) { dex = dexAttribute.level; }
        const dexMod = getAttributeModifierRaw("Dexterity", dex, charDerivedStats);

        let wis = 0;
        const wisAttribute: AttributeLevel = charDerivedStats.attributeLevels[4];
        if (wisAttribute && wisAttribute.level) { wis = wisAttribute.level; }
        const wisMod = getAttributeModifierRaw("Wisdom", wis, charDerivedStats);

        let con = 0;
        const conAttribute: AttributeLevel = charDerivedStats.attributeLevels[2];
        if (conAttribute && conAttribute.level) { con = conAttribute.level; }
        const conMod = getAttributeModifierRaw("Constitution", con, charDerivedStats);

        const lookups = Lookups.getInstance();

        charDerivedStats.rangedWeaponStats = [];

        const items = this.charTraits.gear.equipment;
        if (items.length > 0) {
            items.forEach((i) => {
                if (i.type === "Ranged Weapons" || i.type === "Heavy Weapons") {

                    let name = i.name;
                    if (i.customName && i.customName.trim() !== "") { name = i.customName; }

                    let toHitBonus = 0;
                    let toHitBonusNote = "";

                    let energyWeaponToHitBonus = 0;
                    let energyWeaponToHitBonusNote = "";
                    if (i.subtype && i.subtype === "Energy Weapons") {
                        energyWeaponToHitBonus = 1;
                        energyWeaponToHitBonusNote = "Energy weapon";
                    }

                    // mods
                    let modBonusNotes: string[] = [];
                    let modBonusToHit = 0;
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                if (theMod.tags?.includes("+1ToHit")) {
                                    modBonusToHit = modBonusToHit + 1;
                                    modBonusNotes.push(" +1 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+1ToHitRanged")) {
                                    modBonusToHit = modBonusToHit + 1;
                                    modBonusNotes.push(" +1 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+2ToHit")) {
                                    modBonusToHit = modBonusToHit + 2;
                                    modBonusNotes.push(" +2 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+2ToHitRanged")) {
                                    modBonusToHit = modBonusToHit + 2;
                                    modBonusNotes.push(" +2 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+3ToHit")) {
                                    modBonusToHit = modBonusToHit + 3;
                                    modBonusNotes.push(" +3 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+3ToHitRanged")) {
                                    modBonusToHit = modBonusToHit + 3;
                                    modBonusNotes.push(" +3 (" + theMod.name + " mod)");
                                }
                            }
                        })
                    }

                    // All ranged weapons use DEX... except for telekinetic attacks from Telekinetic Armory psychic discipline. 
                    let attackStat = "DEX";
                    let attackStatMod = dexMod;
                    if (i.subtype !== "Telekinetic Armory Weapons") {
                        toHitBonus = baseAttack + shootSkill + dexMod + energyWeaponToHitBonus + modBonusToHit;
                        toHitBonusNote = PlusMinus(baseAttack) + " (Base Attack) " + PlusMinus(shootSkill) + " (" + shootSkillDesc + ") " + PlusMinus(dexMod) + " (DEX modifier)" + modBonusNotes.join("");

                        if (energyWeaponToHitBonus > 0) {
                            toHitBonusNote = toHitBonusNote + " " + PlusMinus(energyWeaponToHitBonus) + " (" + energyWeaponToHitBonusNote + ")";
                        }
                    } else {
                        // Telekinetic armory weapons:
                        let attackSkillLevel = telekinesisSkill;

                        let attackSkillDesc = shootSkillDesc;
                        if (telekinesisSkill >= shootSkill) {
                            attackSkillDesc = telekinesisSkillDesc;
                        }

                        if (shootSkill > telekinesisSkill) {
                            attackSkillLevel = shootSkill;
                        }
                        if (dexMod >= wisMod && dexMod >= conMod) {
                            attackStat = "DEX";
                            attackStatMod = dexMod;
                        } else {
                            if (wisMod >= dexMod && wisMod >= conMod) {
                                attackStat = "WIS";
                                attackStatMod = wisMod;
                            } else {
                                if (conMod >= wisMod && conMod >= dexMod) {
                                    attackStat = "CON";
                                    attackStatMod = conMod;
                                }
                            }
                        }

                        toHitBonus = baseAttack + attackSkillLevel + attackStatMod;
                        toHitBonusNote = PlusMinus(baseAttack) + " (Base Attack) " + PlusMinus(attackSkillLevel) + " (" + attackSkillDesc + ") " + PlusMinus(attackStatMod) + " (" + attackStat + " modifier)";
                    }


                    let mag = 0;
                    let noBurst = false;
                    if (i.magazine) { mag = i.magazine; }
                    // mods 
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                if (theMod.tags?.includes("ExtendedMag")) {
                                    mag = mag * 2;
                                }
                                if (theMod.tags?.includes("InfiniteMag") && i.type === "Ranged Weapons" && i.subtype === "Projectile Weapons") {
                                    mag = 100000;
                                }
                                if (theMod.tags?.includes("InfinitePower") && i.type !== "Heavy Weapons") {
                                    mag = 100000;
                                }
                                if (theMod.tags?.includes("NoBurst")) {
                                    noBurst = true;
                                }
                            }
                        })
                    }

                    let range = [0, 0];
                    if (i.range) { range = i.range; }

                    let burst = false;
                    if (i.burst && !noBurst) { burst = i.burst; }

                    let suppress = false;
                    if (i.suppress) { suppress = i.suppress; }

                    let damage = [0, 0, 0];
                    let damageNote = "";
                    if (i.damage) {
                        // base damage
                        damage = [i.damage[0], i.damage[1], i.damage[2]];
                        damageNote = formatDamage(damage) + " (base damage)";

                        // attack stat mod:
                        damage[2] = damage[2] + attackStatMod;
                        damageNote = damageNote + " " + PlusMinus(attackStatMod) + " (" + attackStat + " modifier)";

                        // Gunslinger focus:
                        const gunslinger1 = charDerivedStats.focusLevels.find((f) => f.focus === "Gunslinger" && f.level === 1);
                        if (gunslinger1) {
                            damage[2] = damage[2] + shootSkill;
                            damageNote = damageNote + " " + PlusMinus(shootSkill) + " (Gunslinger-1 focus adds Shoot skill level)";
                        }

                        // mods 
                        if (i.mods) {
                            i.mods.forEach((mod) => {
                                const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                                if (theMod) {
                                    if (theMod.tags?.includes("+1ToDamage")) {
                                        damage[2] = damage[2] + 1;
                                        damageNote = damageNote + " +1 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+1ToDamageRanged")) {
                                        damage[2] = damage[2] + 1;
                                        damageNote = damageNote + " +1 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+2ToDamage")) {
                                        damage[2] = damage[2] + 2;
                                        damageNote = damageNote + " +2 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+2ToDamageRanged")) {
                                        damage[2] = damage[2] + 2;
                                        damageNote = damageNote + " +2 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+3ToDamage")) {
                                        damage[2] = damage[2] + 3;
                                        damageNote = damageNote + " +3 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+3ToDamageRanged")) {
                                        damage[2] = damage[2] + 3;
                                        damageNote = damageNote + " +3 (" + theMod.name + " mod)";
                                    }
                                }
                            })
                        }
                    }

                    const damageRoll: DieRoll = { numDice: damage[0], dieSize: damage[1], bonus: damage[2] };

                    let notes: string[] = [];

                    // Burst fire
                    if (burst) {
                        const burstDamage: DieRoll = { numDice: damageRoll.numDice, dieSize: damageRoll.dieSize, bonus: damageRoll.bonus + 2 };
                        notes.push("burst fire (uses 3 ammo, " + PlusMinus(toHitBonus + 2) + " to hit, " + formatDieRoll(burstDamage) + " damage)")
                    }

                    // Suppress
                    if (i.suppress) {
                        notes.push("can fire to suppress (double ammo use per round, all targets in front of weapon not under hard cover automatically takes half damage, Evasion save for no damage.)")
                    }

                    // Slow reload
                    if (i.magazineReload && i.magazineReload > 1) {
                        notes.push("Takes " + i.magazineReload + " Main actions to reload.");
                    }

                    const add1d4DamageOnShootMiss = charDerivedStats.focusLevels.find((fl) => fl.focus === "Gunslinger" && fl.level === 2);
                    if (add1d4DamageOnShootMiss) {
                        if (longNotes) {
                            notes.push("Gunslinger-2 gives 1d4 damage on a miss");
                        } else {
                            notes.push("1d4 dmg on a miss");
                        }
                    }

                    // Tags:
                    if (i.tags) {
                        i.tags.forEach((tag) => {
                            const theTag = lookups.equipmentTags.find((t) => t.tag === tag);
                            if (theTag) {
                                notes.push(theTag.note);
                            }
                        })
                    }

                    // mods
                    let mods: string[] = [];
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                mods.push(theMod.name + " (" + theMod.shortDesc + ")");
                            }
                        })
                    }
                    if (mods.length > 0) { notes.push("Mods: " + mods.join("; ")); }

                    if (i.customNotes) {
                        notes.push(i.customNotes);
                    }

                    const weaponStat: WeaponStat = {
                        name: name,
                        toHitBonus: toHitBonus,
                        toHitBonusNote: toHitBonusNote,
                        damage: damageRoll,
                        damageNote: damageNote,
                        mag: mag,
                        range: range,
                        notes: notes,
                        techLevel: getTechLevel(i),
                        burst: burst,
                        suppress: suppress,
                        shockDamage: 0,
                        shockAC: 0,
                        shockNotes: [],
                        attributes: "DEX",
                        mods: i.mods
                    };

                    this.rangedWeaponStats.push(weaponStat);
                }
            })
        }

    }

    calculateMeleeWeaponStats = (step: CreationStep, longNotes: boolean) => {

        const charDerivedStats = new CharacterDerivedStats(this.charTraits);
        charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100);
        charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100, 100);
        charDerivedStats.calculateBaseAttack(CreationStep.AllSteps);
        charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);

        const lookups = Lookups.getInstance();

        const baseAttack = charDerivedStats.baseAttackLevels.baseAttack;

        let punchSkill = -2;
        let punchSkillDesc = "";
        const punchSkillLevel = charDerivedStats.skillLevels.find((sl) => sl.skill === "Punch");
        if (punchSkillLevel && punchSkillLevel.level !== null) {
            punchSkill = punchSkillLevel.level - 1;
            punchSkillDesc = "Punch skill level " + punchSkill;
        } else {
            punchSkillDesc = "No Punch skill level";
        }

        let stabSkill = -2;
        let stabSkillDesc = "";
        const stabSkillLevel = charDerivedStats.skillLevels.find((sl) => sl.skill === "Stab");
        if (stabSkillLevel && stabSkillLevel.level !== null) {
            stabSkill = stabSkillLevel.level - 1;
            stabSkillDesc = "Stab skill level " + stabSkill;
        } else {
            stabSkillDesc = "No Stab skill level";
        }

        let telekinesisSkill = -2;
        let telekinesisSkillDesc = "";
        const telekinesisSkillLevel = charDerivedStats.skillLevels.find((sl) => sl.skill === "Telekinesis");
        if (telekinesisSkillLevel && telekinesisSkillLevel.level !== null) {
            telekinesisSkill = telekinesisSkillLevel.level - 1;
            telekinesisSkillDesc = "Telekinesis skill level " + telekinesisSkill;
        } else {
            telekinesisSkillDesc = "No Telekinesis skill level";
        }

        let dex = 0;
        const dexAttribute: AttributeLevel = charDerivedStats.attributeLevels[1];
        if (dexAttribute && dexAttribute.level) { dex = dexAttribute.level; }
        const dexMod = getAttributeModifierRaw("Dexterity", dex, charDerivedStats);

        let str = 0;
        const strAttribute: AttributeLevel = charDerivedStats.attributeLevels[0];
        if (strAttribute && strAttribute.level) { str = strAttribute.level; }
        const strMod = getAttributeModifierRaw("Strength", str, charDerivedStats);

        let wis = 0;
        const wisAttribute: AttributeLevel = charDerivedStats.attributeLevels[4];
        if (wisAttribute && wisAttribute.level) { wis = wisAttribute.level; }
        const wisMod = getAttributeModifierRaw("Wisdom", wis, charDerivedStats);

        let con = 0;
        const conAttribute: AttributeLevel = charDerivedStats.attributeLevels[2];
        if (conAttribute && conAttribute.level) { con = conAttribute.level; }
        const conMod = getAttributeModifierRaw("Constitution", con, charDerivedStats);

        charDerivedStats.meleeWeaponStats = [];

        let items = this.charTraits.gear.equipment;
        // items = items.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i); // filter any duplicate items out

        if (items.length > 0) {
            items.forEach((i) => {
                if (i.type === "Melee Weapons") {

                    const getName = (a: EquipmentItem) => {
                        if (a.customName && a.customName.trim() !== "") { return a.customName; }
                        return a.name;
                    }

                    const name = getName(i);
                    let notes: string[] = [];
                    // mods
                    let mods: string[] = [];
                    let modBonusNotes: string[] = [];
                    let modBonusToHit = 0;
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                mods.push(theMod.name + " (" + theMod.shortDesc + ")");
                                if (theMod.tags?.includes("+1ToHit")) {
                                    modBonusToHit = modBonusToHit + 1;
                                    modBonusNotes.push(" +1 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+1ToHitMelee")) {
                                    modBonusToHit = modBonusToHit + 1;
                                    modBonusNotes.push(" +1 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+2ToHit")) {
                                    modBonusToHit = modBonusToHit + 2;
                                    modBonusNotes.push(" +2 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+2ToHitMelee")) {
                                    modBonusToHit = modBonusToHit + 2;
                                    modBonusNotes.push(" +2 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+3ToHit")) {
                                    modBonusToHit = modBonusToHit + 3;
                                    modBonusNotes.push(" +3 (" + theMod.name + " mod)");
                                }
                                if (theMod.tags?.includes("+3ToHitMelee")) {
                                    modBonusToHit = modBonusToHit + 3;
                                    modBonusNotes.push(" +3 (" + theMod.name + " mod)");
                                }
                            }
                        })
                    }

                    // mods
                    let toHitBonus = baseAttack + modBonusToHit;
                    let toHitBonusNote = PlusMinus(baseAttack) + " (Base Attack) ";

                    // Stab or Punch skill
                    // let weaponSkillName = "";

                    let weaponSkillLevel = -2;
                    let weaponSkillDesc = "";
                    let attributeMod = 0;
                    let attributeDesc = "";
                    let attributes = "";
                    const isPunchWeapon = i.tags && i.tags.indexOf("Punch") !== -1;

                    if (i.subtype !== "Telekinetic Armory Weapons") {

                        if (isPunchWeapon) {
                            weaponSkillLevel = punchSkill;
                            weaponSkillDesc = PlusMinus(punchSkill) + " (" + punchSkillDesc + ") ";
                        } else {
                            weaponSkillLevel = stabSkill;
                            weaponSkillDesc = PlusMinus(stabSkill) + " (" + stabSkillDesc + ") ";
                        }

                        toHitBonus = toHitBonus + weaponSkillLevel;
                        toHitBonusNote = toHitBonusNote + " " + weaponSkillDesc + " ";

                        // Attribute modifier:

                        // STR/DEX weapons
                        if (i.attributes) { attributes = i.attributes.join("/"); }
                        if (i.attributes && i.attributes.join("/") === "STR/DEX") {
                            if (strMod > dexMod) {
                                // attribute = "STR";
                                attributeMod = strMod;
                                attributeDesc = PlusMinus(strMod) + " (STR modifier)";
                            } else { // uses dex mod
                                // attribute = "DEX";
                                attributeMod = dexMod;
                                attributeDesc = PlusMinus(dexMod) + " (DEX modifier)";
                            }
                        }

                        // STR-only weapons
                        if (i.attributes && i.attributes.join("/") === "STR") {
                            // attribute = "STR";
                            attributeMod = strMod;
                            attributeDesc = PlusMinus(strMod) + " (STR modifier)";
                        }

                        // DEX-only weapons (not listed)
                        if (i.attributes && i.attributes.join("/") === "DEX") {
                            // attribute = "DEX";
                            attributeMod = dexMod;
                            attributeDesc = PlusMinus(dexMod) + " (DEX modifier)";
                        }
                    }

                    // special handling for Telekinetic Armory weapons:
                    if (i.subtype === "Telekinetic Armory Weapons") {
                        weaponSkillLevel = telekinesisSkill;
                        weaponSkillDesc = PlusMinus(telekinesisSkill) + " " + telekinesisSkillDesc + " ";
                        if (stabSkill > telekinesisSkill) {
                            weaponSkillLevel = stabSkill;
                            weaponSkillDesc = PlusMinus(stabSkill) + " " + stabSkillDesc + " ";
                        }
                        if (dexMod >= wisMod && dexMod >= conMod) {
                            attributeDesc = PlusMinus(dexMod) + " (DEX modifier)";
                            attributeMod = dexMod;
                        } else {
                            if (wisMod >= dexMod && wisMod >= conMod) {
                                attributeDesc = PlusMinus(wisMod) + " (WIS modifier)";
                                attributeMod = wisMod;
                            } else {
                                if (conMod >= wisMod && conMod >= dexMod) {
                                    attributeDesc = PlusMinus(dexMod) + " (CON modifier)";
                                    attributeMod = conMod;
                                }
                            }
                        }
                        toHitBonus = toHitBonus + weaponSkillLevel;
                        toHitBonusNote = toHitBonusNote + " " + weaponSkillDesc;
                    }
                    // end special handling for Telekinetic Armor melee weapons. 

                    toHitBonus = toHitBonus + attributeMod;
                    toHitBonusNote = toHitBonusNote + attributeDesc + modBonusNotes.join(" ");;

                    // Stats which are not relevant to melee weapons:
                    let mag = 0;
                    let range = [0, 0];
                    let burst = false;
                    let suppress = false;

                    // Damage
                    let damage = [0, 0, 0];
                    let damageNote = "";
                    let shockDamage = 0;
                    let shockAC = 0;
                    let shockNotes: string[] = [];

                    let hasUnarmedCombatant1 = false;

                    if (i.damage) {

                        // base damage die:
                        damage = [i.damage[0], i.damage[1], i.damage[2]];

                        // Punch weapon
                        if (isPunchWeapon) {

                            // Unarmed Combatant focus:
                            const uc1 = charDerivedStats.focusLevels.find((f) => f.focus === "Unarmed Combatant" && f.level === 1);
                            if (uc1) {
                                hasUnarmedCombatant1 = true;
                                // Higher punch skill increases base damage
                                switch (punchSkill) {
                                    case 0: {
                                        damage[1] = 6;
                                        if (longNotes) { notes.push("Unarmed Combatant-1 and Punch-0 gives base 1d6 damage"); }
                                        break;
                                    }
                                    case 1: {
                                        damage[1] = 8;
                                        if (longNotes) { notes.push("Unarmed Combatant-1 and Punch-1 gives base 1d8 damage"); }
                                        break;
                                    }
                                    case 2: {
                                        damage[1] = 10;
                                        if (longNotes) { notes.push("Unarmed Combatant-1 and Punch-2 gives base 1d10 damage"); }
                                        break;
                                    }
                                    case 3: {
                                        damage[1] = 12;
                                        if (longNotes) { damageNote = damageNote + " (Unarmed Combatant-1 and Punch-3 gives base 1d12 damage)"; }
                                        break;
                                    }
                                    case 4: {
                                        damage[1] = 12;
                                        damage[2] = damage[2] + 1;
                                        if (longNotes) { notes.push("Unarmed Combatant-1 and Punch-4 gives base 1d12+1 damage"); }
                                        break;
                                    }
                                    default: {
                                        break;
                                    }
                                }
                            }

                        }

                        damageNote = formatDamage(damage) + " base damage ";

                        // Damage bonuses:

                        // attribute mod:
                        damage[2] = damage[2] + attributeMod;
                        damageNote = damageNote + attributeDesc + " ";

                        // Add Punch to damage for Unarmed attacks
                        if (isPunchWeapon) {
                            damage[2] = damage[2] + Math.max(punchSkill, 0);
                            damageNote = damageNote + PlusMinus(Math.max(punchSkill, 0)) + " (Punch skill level)";
                        }

                        // If have Armsman-1 focus, add Stab skill level to damage and shock
                        const armsman1 = charDerivedStats.focusLevels.find((f) => f.focus === "Armsman" && f.level === 1);
                        if (armsman1) {
                            if (!isPunchWeapon) {
                                damage[2] = damage[2] + stabSkill;
                                damageNote = damageNote + " " + PlusMinus(stabSkill) + " (Stab skill level)";
                                if (i.shock && i.shock[0] > 0) {
                                    shockDamage = shockDamage + stabSkill;
                                }
                                if (longNotes) { notes.push("Armsman-1 adds the attacker’s Stab skill level to damage rolls and Shock damage"); }
                            }
                        }

                        // mods 
                        let AC10Shock = false;
                        if (i.mods) {
                            i.mods.forEach((mod) => {
                                const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                                if (theMod) {
                                    if (theMod.tags?.includes("+1ToDamage")) {
                                        damage[2] = damage[2] + 1;
                                        damageNote = damageNote + " +1 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+1ToDamageMelee")) {
                                        damage[2] = damage[2] + 1;
                                        damageNote = damageNote + " +1 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+2ToDamage")) {
                                        damage[2] = damage[2] + 2;
                                        damageNote = damageNote + " +2 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+2ToDamageMelee")) {
                                        damage[2] = damage[2] + 2;
                                        damageNote = damageNote + " +2 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+3ToDamage")) {
                                        damage[2] = damage[2] + 3;
                                        damageNote = damageNote + " +3 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("+3ToDamageMelee")) {
                                        damage[2] = damage[2] + 3;
                                        damageNote = damageNote + " +3 (" + theMod.name + " mod)";
                                    }
                                    if (theMod.tags?.includes("AC10Shock")) {
                                        AC10Shock = true;
                                    }
                                }
                            })
                        }

                        const damageRoll: DieRoll = { numDice: damage[0], dieSize: damage[1], bonus: damage[2] };

                        if (i.shock && i.shock[0] > 0) {
                            // Set basic shock for the weapon
                            shockDamage = i.shock[0];
                            shockAC = i.shock[1];
                            shockNotes.push("Weapon has Shock " + i.shock[0] + "pts/AC" + i.shock[1]);

                            // Add attribute modifier to Shock
                            if (attributeMod !== 0) {
                                shockDamage = shockDamage + attributeMod;
                                shockNotes.push(attributeDesc);
                            }
                        }

                        // If have Armsman-1 focus, add Stab skill level to damage and shock
                        if (armsman1 && !isPunchWeapon) {
                            if (i.shock && i.shock[0] > 0) {
                                shockDamage = shockDamage + stabSkill;
                                shockNotes.push(PlusMinus(stabSkill) + " Armsman-1 adds Stab skill to Shock damage");
                            }
                        }

                        // If have Close Combatant-2 focus, treat opponents as AC 10 for Shock
                        const closeCombatant2 = charDerivedStats.focusLevels.find((f) => f.focus === "Close Combatant" && f.level === 2);
                        if (closeCombatant2 && !isPunchWeapon) {
                            shockAC = 10;
                            shockNotes.push("Close Combatant-2 treats all targets of your melee attacks as AC 10 for Shock damage");
                        }

                        // Unarmed Combatant also gives Shock damage = Punch skill at Punch-1 or better at AC 15
                        if (isPunchWeapon && hasUnarmedCombatant1 && punchSkill >= 1) {
                            shockDamage = punchSkill;
                            shockAC = 15;
                            shockNotes.push("Unarmed Combatant-1 gives Shock damage equal to Punch skill vs AC 15");

                            // Add attribute modifier to Shock
                            if (attributeMod !== 0) {
                                shockDamage = shockDamage + attributeMod;
                                shockNotes.push(attributeDesc + " to Shock damage");
                            }
                        }

                        if (isPunchWeapon) {
                            const UC2 = charDerivedStats.focusLevels.find((fl) => fl.focus === "Unarmed Combatant" && fl.level === 2);
                            if (UC2) {
                                if (longNotes) {
                                    notes.push("Unarmed Combatant-2 gives 1d6 damage on a miss (plus any possible Shock damage)");
                                } else {
                                    notes.push("1d6 dmg on a miss (plus any Shock damage)");
                                }
                            }
                        }

                        if (!isPunchWeapon) {
                            const Armsman2 = charDerivedStats.focusLevels.find((fl) => fl.focus === "Armsman" && fl.level === 2);
                            if (Armsman2) {
                                if (longNotes) {
                                    notes.push("Armsman-2 does an unmodified 1d4 damage to the target on a miss with melee weapon (plus any possible Shock damage)");
                                } else {
                                    notes.push("1d4 dmg on a miss in melee (plus any Shock dmg)");
                                }
                            }
                        }

                        const SA1 = charDerivedStats.focusLevels.find((fl) => fl.focus === "Shocking Assault" && fl.level === 1);
                        if (SA1) {
                            shockAC = 10;
                            shockNotes.push("Shocking Assault-1 treats all targets of your melee attacks as AC 10 for Shock damage");
                        }

                        const SA2 = charDerivedStats.focusLevels.find((fl) => fl.focus === "Shocking Assault" && fl.level === 2);
                        if (SA2) {
                            shockDamage = shockDamage + 2;
                            shockNotes.push("Shocking Assault-2 gives +2 Shock damage to melee and unarmed attacks");
                        }

                        if (AC10Shock) {
                            shockAC = 10;
                            shockNotes.push("Weapon with the Windcutter mod treats all targets of your melee attacks as AC 10 for Shock damage");
                        }

                        // Tags:
                        if (i.tags) {
                            i.tags.forEach((tag) => {
                                const theTag = lookups.equipmentTags.find((t) => t.tag === tag);
                                if (theTag) {
                                    notes.push(theTag.note);
                                }
                            })
                        }

                        // mods
                        let mods: string[] = [];
                        if (i.mods) {
                            i.mods.forEach((mod) => {
                                const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                                if (theMod) {
                                    mods.push(theMod.name + " (" + theMod.shortDesc + ")");
                                }
                            })
                        }
                        if (mods.length > 0) { notes.push("Mods: " + mods.join("; ")); }

                        if (i.customNotes) {
                            notes.push(i.customNotes);
                        }

                        const weaponStat: WeaponStat = {
                            name: name,
                            toHitBonus: toHitBonus,
                            toHitBonusNote: toHitBonusNote,
                            damage: damageRoll,
                            damageNote: damageNote,
                            mag: mag,
                            range: range,
                            notes: notes,
                            techLevel: getTechLevel(i),
                            burst: burst,
                            suppress: suppress,
                            shockDamage: shockDamage,
                            shockAC: shockAC,
                            shockNotes: shockNotes,
                            attributes: attributes,
                            mods: i.mods
                        };

                        this.meleeWeaponStats.push(weaponStat);
                    }
                }
                //}
            })
        }

    }

    calculateVehicleStats = (step: CreationStep, longNotes: boolean) => {

        this.vehicleStats = [];

        const lookups = Lookups.getInstance();

        let items = this.charTraits.gear.equipment;
        // items = items.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i); // filter any duplicate items out
        if (items.length > 0) {
            items.forEach((i) => {
                if (i.type === "Vehicles") {

                    let notes: string[] = [];

                    // Tags:
                    if (i.tags) {
                        i.tags.forEach((tag) => {
                            const theTag = lookups.equipmentTags.find((t) => t.tag === tag);
                            if (theTag) {
                                notes.push(theTag.note);
                            }
                        })
                    }

                    // mods
                    let mods: string[] = [];
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                mods.push(theMod.name + " (" + theMod.shortDesc + ")");
                            }
                        })
                    }
                    if (mods.length > 0) { notes.push("Mods: " + mods.join("; ")); }

                    if (i.customNotes) {
                        notes.push(i.customNotes);
                    }

                    const getName = (a: EquipmentItem) => {
                        if (a.customName && a.customName.trim() !== "") { return a.customName; }
                        return a.name;
                    }

                    const vehicleStat: VehicleStat = {
                        name: getName(i),
                        speed: i.speed === undefined ? 0 : i.speed,
                        armor: i.armor === undefined ? 0 : i.armor,
                        hp: i.hp === undefined ? 0 : i.hp,
                        crew: i.crew === undefined ? 0 : i.crew,
                        tonnage: i.tonnage === undefined ? 0 : i.tonnage,
                        techLevel: i.techLevel,
                        mods: i.mods,
                        notes
                    };

                    this.vehicleStats.push(vehicleStat);
                }
            })
        }

    }

    calculateDroneStats = (step: CreationStep, longNotes: boolean) => {

        this.droneStats = [];

        const lookups = Lookups.getInstance();

        let items = this.charTraits.gear.equipment;
        items = items.filter((i) => i.type === "Drones");
        // items = items.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i); // filter any duplicate items out
        if (items.length > 0) {
            items.forEach((i) => {
                if (i.type === "Drones") {

                    let notes: string[] = [];

                    // Tags:
                    if (i.tags) {
                        i.tags.forEach((tag) => {
                            const theTag = lookups.equipmentTags.find((t) => t.tag === tag);
                            if (theTag) {
                                notes.push(theTag.note);
                            }
                        })
                    }

                    // mods
                    let mods: string[] = [];
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                mods.push(theMod.name + " (" + theMod.shortDesc + ")");
                            }
                        })
                    }
                    if (mods.length > 0) { notes.push("Mods: " + mods.join("; ")); }

                    if (i.customNotes) {
                        notes.push(i.customNotes);
                    }

                    const getName = (a: EquipmentItem) => {
                        if (a.customName && a.customName.trim() !== "") { return a.customName; }
                        return a.name;
                    }

                    // HP:
                    let hp = i.hp === undefined ? 0 : i.hp;
                    let liftKg = 1;
                    let move = 30;
                    let endurance = 60;
                    let ac = i.ac === undefined ? 0 : i.ac;
                    let controlRange = i.controlRange === undefined ? 0 : i.controlRange;
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                if (theMod.tags?.includes("+50%HP")) {
                                    hp = Math.ceil(hp * 1.5);
                                }
                                if (theMod.tags?.includes("+20kg")) {
                                    liftKg = 20 * mod.quantity;
                                }
                                if (theMod.tags?.includes("x2Endurance")) {
                                    endurance = endurance + 60;
                                }
                                if (theMod.tags?.includes("x2Move")) {
                                    move = move * 2;
                                    endurance = Math.ceil(endurance / 2);
                                }
                                if (theMod.tags?.includes("+50%Endurance")) {
                                    endurance = Math.ceil(endurance * 1.5);
                                }
                                if (theMod.tags?.includes("unlimitedEndurance")) {
                                    endurance = 10000;
                                }
                                if (theMod.tags?.includes("+1ToAC")) {
                                    ac = ac + 1;
                                }
                                if (theMod.tags?.includes("unlimitedRange")) {
                                    controlRange = 10000;
                                }
                            }
                        })
                    }

                    const droneStat: DroneStat = {
                        id: i.id,
                        name: getName(i),
                        fittings: i.fittings === undefined ? 0 : i.fittings,
                        mods: i.mods,
                        ac,
                        hp,
                        controlRange,
                        enc: i.enc === undefined ? 0 : i.enc,
                        liftKg,
                        move,
                        endurance,
                        techLevel: i.techLevel,
                        notes
                    };

                    this.droneStats.push(droneStat);
                }
            })
        }

    }

    calculateRobotStats = (step: CreationStep, longNotes: boolean) => {

        this.robotStats = [];

        const lookups = Lookups.getInstance();

        let items = this.charTraits.gear.equipment;
        // items = items.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i); // filter any duplicate items out
        if (items.length > 0) {
            items.forEach((i) => {
                if (i.type === "Robots") {

                    let notes: string[] = [];

                    let hitDice = i.hitDice === undefined ? 0 : i.hitDice;
                    let attackBonus = i.attackBonus === undefined ? 0 : i.attackBonus;
                    let skill = i.skill === undefined ? 0 : i.skill;
                    let save = i.save === undefined ? 0 : i.save;

                    // Tags:
                    if (i.tags) {
                        i.tags.forEach((tag) => {
                            const theTag = lookups.equipmentTags.find((t) => t.tag === tag);
                            if (theTag) {
                                notes.push(theTag.note);
                            }
                        })
                    }

                    // mods
                    let mods: string[] = [];
                    if (i.mods) {
                        i.mods.forEach((mod) => {
                            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
                            if (theMod) {
                                mods.push(theMod.name + " (" + theMod.shortDesc + ")");
                                if (theMod.tags?.includes("Level2Robot") || theMod.tags?.includes("Level2Robot_VI")) {
                                    hitDice = hitDice + 1;
                                    attackBonus = attackBonus + 1;
                                    skill = skill + 1;
                                    save = save - 1;
                                }
                                if (theMod.tags?.includes("Level3Robot") || theMod.tags?.includes("Level3Robot_VI")) {
                                    hitDice = hitDice + 2;
                                    attackBonus = attackBonus + 2;
                                    skill = skill + 1;
                                    save = save - 1;
                                }
                                if (theMod.tags?.includes("Level4Robot") || theMod.tags?.includes("Level4Robot_VI")) {
                                    hitDice = hitDice + 4;
                                    attackBonus = attackBonus + 4;
                                    skill = skill + 2;
                                    save = save - 2;
                                }
                            }
                        })
                    }
                    if (mods.length > 0) { notes.push("Mods: " + mods.join("; ")); }

                    if (i.customNotes) {
                        notes.push(i.customNotes);
                    }

                    const getName = (a: EquipmentItem) => {
                        if (a.customName && a.customName.trim() !== "") { return a.customName; }
                        return a.name;
                    }

                    let damageRoll: DieRoll = { numDice: 0, dieSize: 0, bonus: 0 };
                    if (i.damage) {
                        damageRoll = { numDice: i.damage[0], dieSize: i.damage[1], bonus: i.damage[2] };
                    }

                    const robotStat: RobotStat = {
                        name: getName(i),
                        hitDice,
                        damage: damageRoll,
                        ac: i.ac === undefined ? 0 : i.ac,
                        attackBonus,
                        move: i.move === undefined ? 0 : i.move,
                        morale: i.morale === undefined ? 0 : i.morale,
                        skill,
                        save,
                        techLevel: i.techLevel,
                        mods: i.mods,
                        notes
                    };

                    this.robotStats.push(robotStat);
                }
            })
        }

    }

    calculateSkillPointsAvailableSoFar = (step: CreationStep, level: number) => {

        // Add the skill points that the character gets by virtue of their class(es): 

        let isExpertOrPartialExpert = false;
        let isPsychicOrPartialPsychic = false;

        const thisExpert = this.charTraits.levelOne.classes.find((c) => c.className === "Expert" || c.className === "Partial Expert");
        if (thisExpert) { isExpertOrPartialExpert = true; }

        const thisPsychic = this.charTraits.levelOne.classes.find((c) => c.className === "Psychic" || c.className === "Partial Psychic");
        if (thisPsychic) { isPsychicOrPartialPsychic = true; }

        let generalPointsPerLevel = 0;
        let nonCombatNonPsychicPointsPerLevel = 0;
        let psychicPointsPerLevel = 0;

        if (isPsychicOrPartialPsychic) {
            generalPointsPerLevel = 2;
            psychicPointsPerLevel = 1;
        }
        else {
            generalPointsPerLevel = 3;
        }

        if (isExpertOrPartialExpert) {
            nonCombatNonPsychicPointsPerLevel = 1;
        }

        this.skillPointsAvailableSoFar.general = (level - 1) * generalPointsPerLevel;
        this.skillPointsAvailableSoFar.nonCombatNonPsychic = (level - 1) * nonCombatNonPsychicPointsPerLevel;
        this.skillPointsAvailableSoFar.psychic = (level - 1) * psychicPointsPerLevel;

        // Add any skill points gained by virtue of foci gained at this level or previous levels. 
        for (let lev = 0; lev <= level - 2; lev++) {
            const thisLevel = this.charTraits.levels[lev];
            if (thisLevel) {
                const thisFocusPick = thisLevel.focusLevelPick
                if (thisFocusPick) {
                    if (thisFocusPick.grantsSkill) {
                        const thisSkillPointsPick = thisFocusPick.skillPointsPicks[0];
                        if (thisSkillPointsPick) {
                            const skillName = thisSkillPointsPick.skill + "_skill";
                            let thisSkillPointCategory = this.skillPointsAvailableSoFar[skillName];
                            if (thisSkillPointCategory) {
                                this.skillPointsAvailableSoFar[skillName] = this.skillPointsAvailableSoFar[skillName] + thisSkillPointsPick.points;
                            } else {
                                this.skillPointsAvailableSoFar[skillName] = thisSkillPointsPick.points;
                            }
                        }
                    }
                }
            }
        }

        // Add any skill points gained through taking Psychic Training 1 or 2 foci: 
        const psychicTraining1Focus = this.focusLevels.find((f) => f.focus === "Psychic Training" && f.level === 1);
        const psychicTraining2Focus = this.focusLevels.find((f) => f.focus === "Psychic Training" && f.level === 2);

        let psychicTraining1Skill = "";
        let levelGainedPsychicTraining1Focus: any = undefined;
        if (psychicTraining1Focus !== undefined) {
            if (psychicTraining1Focus.grantedSkill) {
                psychicTraining1Skill = psychicTraining1Focus.grantedSkill + "_skill";
            }
            if (psychicTraining1Focus.gainedAtLevel) {
                levelGainedPsychicTraining1Focus = psychicTraining1Focus.gainedAtLevel;
            }

        }

        let levelGainedPsychicTraining2Focus: any = undefined;
        if (psychicTraining2Focus) {
            if (psychicTraining2Focus.gainedAtLevel) {
                levelGainedPsychicTraining2Focus = psychicTraining2Focus.gainedAtLevel;
            }
        }

        // Psychic Training-1; if gained at level2+, gain 3 skill points in the psychic skill
        if (levelGainedPsychicTraining1Focus > 1) {
            if (psychicTraining1Focus !== undefined && psychicTraining1Focus.grantedSkill && psychicTraining1Focus.gainedAtLevel) {
                for (let lev = 1; lev <= level; lev++) {
                    if (lev === levelGainedPsychicTraining1Focus) {
                        this.skillPointsAvailableSoFar[psychicTraining1Skill] = 3;
                    }
                }
            }
        }

        // Psychic Training-1; if gained at level2+, gain 1 skill points/level in the psychic skill (retrospectively)
        if (psychicTraining2Focus !== undefined) {
            for (let lev = 1; lev <= level; lev++) {

                let thisSkillPointCategory = this.skillPointsAvailableSoFar[psychicTraining1Skill];
                if (!thisSkillPointCategory) {
                    this.skillPointsAvailableSoFar[psychicTraining1Skill] = 0;
                };

                if (lev === levelGainedPsychicTraining2Focus) {
                    this.skillPointsAvailableSoFar[psychicTraining1Skill] = this.skillPointsAvailableSoFar[psychicTraining1Skill] + (lev - 1);
                }
                if (lev > levelGainedPsychicTraining2Focus) {
                    this.skillPointsAvailableSoFar[psychicTraining1Skill] = this.skillPointsAvailableSoFar[psychicTraining1Skill] + 1;
                }
            }
        }

    }

    calculateSkillPointsSpentSoFar = (step: CreationStep, level: number, index: number) => {

        // calculate skill points spent on each point type:

        if (this.charTraits.levels[level - 2] && this.charTraits.levels[level - 2].skillPointSpends) {

            this.charTraits.levels.forEach((lev) => {
                if (lev.level <= level) {

                    const maxIndex = lev.level !== level ? 1000 : index;
                    const skillPointsSpends = lev.skillPointSpends.filter((sps) => sps.pointType !== "");

                    skillPointsSpends.forEach((sps, thisIndex) => {
                        if (thisIndex <= maxIndex) {
                            if (this.skillPointsSpentSoFar[sps.pointType]) {
                                this.skillPointsSpentSoFar[sps.pointType] = this.skillPointsSpentSoFar[sps.pointType] + sps.pointsSpent;
                            } else {
                                this.skillPointsSpentSoFar[sps.pointType] = sps.pointsSpent;
                            }
                        }
                    })
                }
            })

        }

        // calculate skill points spent on psychic techniques

        if (this.charTraits.levels[level - 2] && this.charTraits.levels[level - 2].skillPointSpends) {

            this.charTraits.levels.forEach((lev) => {
                if (lev.level <= level) {

                    const maxIndex = lev.level !== level ? 1000 : index;
                    const skillPointsSpends = lev.skillPointSpends.filter((sps) => sps.spendType === "learnTechnique");

                    skillPointsSpends.forEach((sps, thisIndex) => {
                        if (thisIndex <= maxIndex) {
                            if (sps.techniqueName !== "") {
                                if (sps.techniqueName in this.skillPointsSpentSoFarOnTechniques) {
                                    this.skillPointsSpentSoFarOnTechniques[sps.techniqueName] = this.skillPointsSpentSoFarOnTechniques[sps.techniqueName] + sps.pointsSpent;
                                } else {
                                    this.skillPointsSpentSoFarOnTechniques[sps.techniqueName] = sps.pointsSpent;
                                }
                            }
                        }
                    })
                }
            })

        }

    }

    calculateSkillPointsLeftSoFar = () => {
        for (const pointType in this.skillPointsAvailableSoFar) {
            const skillPointsAvailableSoFar = !this.skillPointsAvailableSoFar[pointType] ? 0 : this.skillPointsAvailableSoFar[pointType];
            const skillPointsSpentSoFar = !this.skillPointsSpentSoFar[pointType] ? 0 : this.skillPointsSpentSoFar[pointType];
            this.skillPointsLeftSoFar[pointType] = skillPointsAvailableSoFar - skillPointsSpentSoFar;
        }
    }

    calculateSystemStrain = () => {

        // maximum strain
        if (this.attributeLevels[2] && this.attributeLevels[2].level) {
            this.systemStrainMaximum.strain = this.attributeLevels[2].level;
            this.systemStrainMaximum.history.push("Base equal to Constitution " + this.attributeLevels[2].level);
        }

        //permanent strain
        this.charTraits.gear.equipment.forEach((g) => {
            if (g.strain) {
                const totalQuantity = g.storageQuantity.reduce((prev, current) => prev + current.quantity, 0);
                const totalStrain = totalQuantity * g.strain;
                this.systemStrainPermanent.strain += totalStrain;
                this.systemStrainPermanent.history.push(totalStrain + " from " + totalQuantity + " " + g.name);
            }
        })
    }

    calculateTotalModsMaintenance = () => {
        let total = 0;
        const lookups = Lookups.getInstance();
        this.charTraits.gear.equipment.forEach((i) => {
            if (i.mods && i.mods.length > 0) {
                let totalMaint = 0;
                i.mods.forEach((m) => {
                    const theMod = lookups.gearMods.find((mod) => mod.id === m.id);
                    if (theMod) {
                        totalMaint = totalMaint + theMod.maintenance;
                    }
                });
                total = total + Math.max(totalMaint, 0);
            }
        });
        this.totalModsMaintenance = total;
    }

    calculateMaintenanceScore = () => {

        const isNull = (x: number | null) => {
            if (x === null) { return 0; }
            return x;
        }

        if (this.attributeModifierLevels) {

            let intMod = 0;
            const INT = this.attributeLevels[3].level;
            if (INT) {
                intMod = getAttributeModifierRaw("Intelligence", INT, this);
            }

            let conMod = 0;
            const CON = this.attributeLevels[2].level;
            if (CON) {
                conMod = getAttributeModifierRaw("Constitution", CON, this);
            }

            let fixSkillLevel = 0;
            const fixSkill = this.skillLevels.find((s) => s.skill === "Fix")
            if (fixSkill) { fixSkillLevel = isNull(fixSkill.level); }

            this.maintenanceScore = (intMod + conMod) + (3 * fixSkillLevel);
        }
    }

    calculateTotalGearValue = () => {
        let total = 0;
        const lookups = Lookups.getInstance();

        this.charTraits.gear.equipment.forEach((e) => {

            const rawItem = lookups.gear.find((g) => g.id === e.id);
            if (rawItem) {

                let rawItemCost = 0;
                let totalQuantityofItem = 0;
                let totalBaseItemCostMultiplier = 0;
                let totalCostOfAllModsOnItem = 0;

                rawItemCost = rawItem.cost;

                if (e.mods && e.mods.length > 0) {
                    e.mods.forEach((gm) => {
                        const rawMod = lookups.gearMods.find((rgm) => rgm.id === gm.id);
                        if (rawMod) {

                            let modTotalCost = 0;
                            let modBaseCost = rawMod.cost;

                            const modCostMultipier = rawMod.modCostMultiplier !== undefined ? rawMod.modCostMultiplier : 1;

                            if (modCostMultipier !== 1) {
                                modTotalCost = (rawItemCost * modCostMultipier);
                            } else {
                                modTotalCost = (modTotalCost + modBaseCost) * gm.quantity;
                            }

                            // add up totals so far
                            totalCostOfAllModsOnItem = totalCostOfAllModsOnItem + modTotalCost;

                            // base item cost multipliers:
                            const baseItemCostMultiplier = rawMod.baseCostMultiplier !== undefined ? rawMod.baseCostMultiplier : 1;
                            totalBaseItemCostMultiplier = totalBaseItemCostMultiplier * baseItemCostMultiplier;
                        }
                    })
                }

                let finalCostOfItemIncludingMods = 0;

                let finalBaseItemCost = 0;
                if (totalBaseItemCostMultiplier === 0) {
                    finalBaseItemCost = rawItemCost;
                } else {
                    finalBaseItemCost = totalBaseItemCostMultiplier * rawItemCost;
                }
                finalCostOfItemIncludingMods = finalCostOfItemIncludingMods + (finalBaseItemCost + totalCostOfAllModsOnItem);

                totalQuantityofItem = 0;
                e.storageQuantity.forEach((sq) => {
                    totalQuantityofItem = totalQuantityofItem + sq.quantity;
                })

                total = total + (finalCostOfItemIncludingMods * totalQuantityofItem);
            }

        })

        this.totalGearCost = total;
        this.creditsAvailable = this.charTraits.gear.startingCredits + this.charTraits.gear.additionalCredits - this.totalGearCost;
    }

}
