import React from "react";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterTraits } from "../interfaces/CharacterTraits";
import { FocusLevelPick } from "../interfaces/FocusLevelPick";
import { SkillLevelPick } from "../interfaces/SkillLevelPick";

import { Lookups } from "../lookups/Lookups";

import FocusPickControls from "./charGen/FocusPickControls";
import SkillPickControls from "./charGen/SkillPickControls";
import ValidationAlert from "./ValidationAlert";

import { convertAnySkillToSkillListDesc } from "../utilities/SkillUtilities";

import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";
import { useCollapsibleSection } from "../hooks/useCollapsibleSection";

interface IProps {
    charTraits: CharacterTraits;
    onSelectFocusLevel: (focusName: string, focusLevel: number, traitToUpdate: string) => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onSelectAttributeModifierBonusPick: (attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => void;
    onManageCustomSkills: () => void;
    onSwitchCollapsed: (sectionName: string) => void;
    onSelectVehicleBody: (vehicleBody: string) => void;
    onSetUniqueGift: (focusLevel: number, text: string) => void;
}

const CharacterDesignStep5FreeFocusAndSkill: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "freeFocusAndSkill";
    
    const { sectionClassName, switchDisplay, getCollapseIcon } = useCollapsibleSection(
        props.charTraits.basicTraits.collapsedSections,
        props.onSwitchCollapsed,
        sectionName,
    );

    const lookups = Lookups.getInstance();

    let freeFocusLevelPick: FocusLevelPick = char.levelOne.freeFocusLevelPick;
    if (freeFocusLevelPick) {
        if (!freeFocusLevelPick.skillLevelPicks) {
            freeFocusLevelPick.skillLevelPicks = [];
        } else {
            if (!freeFocusLevelPick.grantsSkill) {
                freeFocusLevelPick.skillLevelPicks = [];
            }
        }
    };

    let freeSkillLevelPicks: SkillLevelPick[] = char.levelOne.freeSkillLevelPicks;
    if (!freeSkillLevelPicks) {
        freeSkillLevelPicks = [];
    };

    const getSkillsAtFreeFocusStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.FreeFocus);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const skillsAtFreeFocusStep = getSkillsAtFreeFocusStep();

    const getSkillsBeforeFreeFocusStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.Classes, 10);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const skillsBeforeFreeFocusStep = getSkillsBeforeFreeFocusStep();

    const getSkillsAtFreeSkillStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.FreeSkill);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const getFocusesAtThisStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateFocusLevels(CreationStep.FreeFocus);
        return charDerivedStatsAtThisClass.focusLevels;
    }

    const focusesAtThisStep = getFocusesAtThisStep();

    const skillsAtFreeSkillStep = getSkillsAtFreeSkillStep();

    const getFocusLevelPicksToChooseFrom = () => {

        const allFocusesAtLevelOne = lookups.focuses.map((f) => {
            let grantsSkill = false;
            const hasSkillBonus = f.levels[0].bonuses.find((b) => b.type === "bonusSkill");
            if (hasSkillBonus) {
                grantsSkill = true;
            }
            const flp: FocusLevelPick = { focus: f.focus, level: 1, type: f.type, skillLevelPicks: [], skillPointsPicks: [], grantsSkill: grantsSkill };
            return flp;
        });

        // Replace any focuses that have already been picked (except those that can be picked multiple times) with
        // level 2 version.

        let finalFocuses: FocusLevelPick[] = [];

        allFocusesAtLevelOne.forEach((l1f) => {
            const existingFocusLevelOne = focusesAtThisStep.find((f) => f.focus === l1f.focus && f.level === l1f.level);
            const isCurrentFocus = freeFocusLevelPick && l1f.focus === freeFocusLevelPick.focus && l1f.level === freeFocusLevelPick.level;
            if (existingFocusLevelOne && !isCurrentFocus) {
                const secondLevelFocus: FocusLevelPick = { ...l1f, level: 2 };
                finalFocuses.push(secondLevelFocus);
            } else {
                finalFocuses.push(l1f);
            }

        });

        finalFocuses.sort((a, b) => a.focus < b.focus ? -1 : 1);

        return finalFocuses;
    }

    const focusLevelPicksToChooseFrom = getFocusLevelPicksToChooseFrom();

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Free Focus and Skill {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                <div className="mb-3 border-bottom">

                    <FocusPickControls
                        className={""}
                        key={"FreeFocus"}
                        focusType={"Any"}
                        focusLevelPicksToChooseFrom={focusLevelPicksToChooseFrom}
                        focusLevelAlreadyPicked={freeFocusLevelPick}
                        skillLevelsAlreadyPicked={freeFocusLevelPick.skillLevelPicks}
                        skillPointsAlreadyPicked={[]}
                        attributeModifiersAlreadyPicked={freeFocusLevelPick.attributeModifierPicks === undefined ? [] : freeFocusLevelPick.attributeModifierPicks}
                        traitToUpdate_Focuses={"levelOne.freeFocusPickLevel"}
                        traitToUpdate_Skills={"levelOne.freeFocusPickLevel.skillLevelPicks"}
                        traitToUpdate_AttributeModifiers={"levelOne.freeFocusPickLevel.attributeModifierPicks"}
                        charTraits={char}
                        charDerivedSkills={skillsAtFreeFocusStep}
                        charDerivedSkillsAtPreviousStep={skillsBeforeFreeFocusStep}
                        charDerivedFocuses={focusesAtThisStep}
                        includeLeadingIcon={true}
                        onSelectFocusLevel={(focusName: string, focusLevel: number) => props.onSelectFocusLevel(focusName, focusLevel, "levelOne.freeFocusPickLevel")}
                        onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                        onSelectAttributeModifierBonusPick={(attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => props.onSelectAttributeModifierBonusPick(attributeIndex, attributeModifierBonus, selected, traitToUpdate)}
                        onManageCustomSkills={props.onManageCustomSkills}
                        onSelectVehicleBody={props.onSelectVehicleBody}
                        onSetUniqueGift={props.onSetUniqueGift}
                    />

                    {char.showValidation && char.levelOne.validationCodes.indexOf("freeFocusNotSelected") !== -1 &&
                        <ValidationAlert msg="Focus must be selected" />
                    }
                    {char.levelOne.validationCodes.indexOf("viRobotCannotBePsychic") !== -1 &&
                        <ValidationAlert msg="A Virtual Intelligence cannot be a Psychic or Partial Psychic" />
                    }
                    {char.levelOne.validationCodes.indexOf("viCannotPossessCyber") !== -1 &&
                        <ValidationAlert key={"viCannotPossessCyber"} msg="VI characters cannot possess cyberware" />
                    }
                    {char.showValidation && char.levelOne.validationCodes.indexOf("freeFocusSkillLevelNotSelected") !== -1 &&
                        <ValidationAlert msg={"Select one level of a skill (you have selected " + freeFocusLevelPick.skillLevelPicks.length + ")"} />
                    }
                    {char.levelOne.validationCodes.indexOf("freeFocusSkillLevelAboveLimit") !== -1 &&
                        <ValidationAlert msg="You cannot have any skill higher than level-1 at 1st level" />
                    }
                    {char.levelOne.validationCodes.indexOf("freeFocusPsychicTrainingOnlyForPsychic") !== -1 &&
                        <ValidationAlert msg="Only a character with the Psychic or Partial Psychic class can take the Psychic Training focus" />
                    }
                    {char.levelOne.validationCodes.indexOf("freeFocusWildPsychicNotForPsychic") !== -1 &&
                        <ValidationAlert msg="Only a character that does not have the Psychic or Partial Psychic class can take the Wild Psychic focus" />
                    }
                    {char.levelOne.validationCodes.indexOf("freeFocusPsychicTrainingForPartialPsychicMustHaveSameSkill") !== -1 &&
                        <ValidationAlert msg="You must select the same skill for the Psychic Training focus as you select for Partial Psychic class." />
                    }
                    {char.showValidation && char.levelOne.validationCodes.indexOf("freeFocusAttributeModifierNotSelected") !== -1 &&
                        <ValidationAlert msg="Attribute Modifier must be selected" />
                    }
                    {char.levelOne.validationCodes.indexOf("freeFocusAlienMustBePsychic") !== -1 &&
                        <ValidationAlert msg="A character with an Alien origin focus that has the Psychic Aptitude benefit must be of the Psychic or Partial Psychic class." />
                    }


                    {/* <div><pre>{JSON.stringify(skillsAtFreeFocusStep, null, 2)}</pre></div>
                    <p></p>
                    <div><pre>{JSON.stringify(skillsBeforeFreeFocusStep, null, 2)}</pre></div> */}

                </div>
                <div>
                    <div className="mb-1">
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                        Pick a level in any non-Psychic skill:
                    </div>
                    <SkillPickControls
                        lockedSkillName=""
                        maxLevel={1}
                        singleSkillOnly={true}
                        skillNamesToChooseFrom={convertAnySkillToSkillListDesc("AnyNonPsychicSkill", lookups.skills, true).split(", ")}
                        skillLevelsAlreadyPicked={char.levelOne.freeSkillLevelPicks}
                        skillPointsAlreadyPicked={[]}
                        traitToUpdate="levelOne.freeSkillLevelPick"
                        charTraits={char}
                        charDerivedSkills={skillsAtFreeSkillStep}
                        isLevelFocus={false}
                        onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                        onManageCustomSkills={props.onManageCustomSkills}
                    />

                    {char.showValidation && char.levelOne.validationCodes.indexOf("freeSkillLevelNotSelected") !== -1 &&
                        <ValidationAlert msg={"Select one level of a skill (you have selected " + freeSkillLevelPicks.length + ")"} />
                    }
                    {char.levelOne.validationCodes.indexOf("freeSkillLevelAboveLimit") !== -1 &&
                        <ValidationAlert msg="You cannot have any skill higher than level-1 at 1st level" />
                    }
                    {/* <div><pre>{JSON.stringify(skillsAtFreeSkillStep.filter((s) => s.history.length > 0), null, 2)}</pre></div> */}

                </div>

            </div>

        </div>
    );

}

export default CharacterDesignStep5FreeFocusAndSkill;