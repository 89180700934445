import React, { useState } from 'react';

import { faEye, faCaretRight, faDice, faHandPaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertSkillToFriendlyName, displayBGTable } from "../utilities/SkillUtilities";

import { CharacterTraits } from '../interfaces/CharacterTraits';

import { Lookups } from "../lookups/Lookups";

import ValidationAlert from "./ValidationAlert";
import DescriptionAlert from "./DescriptionAlert";

import BackgroundAssign from "./charGen/BackgroundAssign";
import BackgroundRoll from "./charGen/BackgroundRoll";


import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";
import { useCollapsibleSection } from "../hooks/useCollapsibleSection";

interface IProps {
    charTraits: CharacterTraits;
    onSelectBackgroundMethod: (method: string) => void;
    onSelectBackground: (background: string) => void;
    onSelectQuickSkills: () => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onSelectBackgroundRollTable: (table: string, rollNum: number) => void;
    onSelectAttributeScoreBonusPick: (attributeIndex: number, attributeScoreBonus: number, selected: boolean, traitToUpdate: string, singleAttributeOnly: boolean) => void;
    onSetHomeworld: (homeworld: string) => void;
    onSetLanguages: (languages: string) => void;
    onManageCustomSkills: () => void;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep3Background: React.FunctionComponent<IProps> = (props: IProps) => {

    const [showDescription, setShowDescription] = useState(false);
    const lookups = Lookups.getInstance();

    const char = props.charTraits;

    const sectionName = "background";
    
    const { sectionClassName, switchDisplay, getCollapseIcon } = useCollapsibleSection(
        props.charTraits.basicTraits.collapsedSections,
        props.onSwitchCollapsed,
        sectionName,
    );


    const selectedBackground = lookups.backgrounds.find((bg) => bg.background === char.background.backgroundName);

    // Event handlers

    const onSelectBackground = (e: any): void => {
        const backgroundName = e.currentTarget.value;
        props.onSelectBackground(backgroundName);
    }

    const d20 = () => Math.floor(Math.random() * 20) + 1;

    const setRandomBackground = () => {
        const background = lookups.backgrounds[d20() - 1];
        if (background) {
            props.onSelectBackground(background.background);
        }
    }

    const onChangeHomeworld = (e: any): void => {
        const homeworld = e.currentTarget.value;
        props.onSetHomeworld(homeworld);
    }

    const onChangeLanguages = (e: any): void => {
        const languages = e.currentTarget.value;
        props.onSetLanguages(languages);
    }

    // Create the Background dropdown. 

    const getBackgroundDropdown = () => {
        return (
            <select onChange={onSelectBackground} value={char.background.backgroundName}>
                <option value="">-- select --</option>
                {lookups.backgrounds.map((bgd) => <option key={bgd.background} value={bgd.background}>{bgd.background}</option>)}
            </select>
        )
    }
    const backgroundDropdown = getBackgroundDropdown();

    // Derived skills to pass to SkillPickControls

    const charDerivedStatsAtBackground = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtBackground.calculateSkillLevels(CreationStep.Background);

    // If character has already rolled for at least one background skill, can no longer change their background. 

    const hasAlreadyRolledForBackgroundSkills = () => {
        return char.background.tableRolls[0].result !== "" ||
            char.background.tableRolls[1].result !== "" ||
            char.background.tableRolls[2].result !== "";
    }

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Background {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                {!hasAlreadyRolledForBackgroundSkills() &&
                    <div className="mb-2">
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                        Select a background: {backgroundDropdown} <button className="btn btn-secondary btn-tiny" onClick={setRandomBackground}>Random</button>
                    </div>
                }
                {hasAlreadyRolledForBackgroundSkills() &&
                    <div className="mb-2">
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                        You can no longer change your background because you have started rolling background abilities.
                    </div>
                }

                {char.showValidation && char.background.validationCodes.indexOf("bgNotSelected") !== -1 &&
                    <ValidationAlert msg="Background must be selected" />
                }

                {selectedBackground &&
                    <>
                        <h6>
                            {selectedBackground.background}
                            <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => setShowDescription(!showDescription)} title="Show Skill description">
                                <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                            </button>
                        </h6>

                        <DescriptionAlert display={showDescription}>
                            <div><b>{selectedBackground.background}:</b> {selectedBackground.desc}</div>
                            {selectedBackground.background !== "Custom" &&
                                <>
                                    <div className="mt-1 mb-1"><span className="title">Free Skill:</span> {convertSkillToFriendlyName(selectedBackground.freeSkill, 0)}</div>
                                    <div className="mb-1"><span className="title">Quick Skills:</span> {selectedBackground.quickSkills.split(", ").map((s) => convertSkillToFriendlyName(s, 0)).join(", ")}</div>
                                    <div className="mb-1"><span className="title">Growth Table:</span> {displayBGTable(selectedBackground.growthTable)}</div>
                                    <div><span className="title">Learning Table:</span> {displayBGTable(selectedBackground.learningTable)}</div>
                                </>
                            }
                        </DescriptionAlert>

                        {char.background.method === "" &&
                            <div className="mt-2">
                                <div className="mb-2">
                                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                                    Pick how to set your character's background abilities:
                                </div>
                                <div className="mb-2">
                                    <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectBackgroundMethod("assign")}>
                                        <FontAwesomeIcon icon={faHandPaper} title="text-info" className="mr-1"></FontAwesomeIcon >
                                        Assign
                                    </button>
                                    Assign three skills from Learning Table.</div>
                                <div className="mb-2">
                                    <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectBackgroundMethod("roll")} >
                                        <FontAwesomeIcon icon={faDice} title="text-info" className="mr-1"></FontAwesomeIcon >
                                        Roll
                                    </button> Get the background's free skill, plus roll three times on Growth/Learning Tables.
                                </div>
                                {char.showValidation && char.background.validationCodes.indexOf("backgroundMethodNotSelected") !== -1 &&
                                    <ValidationAlert msg="Background ability assignment method must be selected" />
                                }
                            </div>
                        }

                        {char.background.method === "assign" &&
                            <BackgroundAssign
                                charTraits={char}
                                selectedBackground={selectedBackground}
                                onSelectQuickSkills={props.onSelectQuickSkills}
                                onSelectSkillLevelPick={props.onSelectSkillLevelPick}
                                onManageCustomSkills={props.onManageCustomSkills}
                            />
                        }

                        {char.background.method === "roll" &&
                            <BackgroundRoll
                                charTraits={char}
                                selectedBackground={selectedBackground}
                                onSelectBackgroundRollTable={(table: string, rollNum: number) => props.onSelectBackgroundRollTable(table, rollNum)}
                                onSelectSkillLevelPick={props.onSelectSkillLevelPick}
                                onSelectAttributeScoreBonusPick={props.onSelectAttributeScoreBonusPick}
                                onManageCustomSkills={props.onManageCustomSkills}
                            />
                        }

                    </>
                }

                <div className="form-group form-row mt-2 mb-1">
                    <label htmlFor="homeworld" className="col-12 col-sm-3 col-md-2 col-form-label">
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                        Homeworld
                    </label>
                    <div className="col-12 col-sm-9 col-md-10">
                        <input type="text" className="form-control" id="homeworld" value={char.background.homeworld} onChange={onChangeHomeworld} />
                    </div>
                </div>

                <div className="form-group form-row mb-0">
                    <label htmlFor="languages" className="col-12 col-sm-3 col-md-2 col-form-label">
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                        Languages
                    </label>
                    <div className="col-12 col-sm-9 col-md-10">
                        <input type="text" className="form-control" id="languages" value={char.background.languages} onChange={onChangeLanguages} />
                    </div>
                </div>

            </div>

        </div>
    );

}

export default CharacterDesignStep3Background;