import React from "react";

type IProps = {}

const Updates: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <div className="pageBody">
            <h1 className="pageHeading pb-1" >Updates</h1>

            <h5 className="faqheader">0.9.0 : 2022/02/20 : Initial Release</h5>

            <ul>
                <li>Production release of the basic 1st level character designer.</li>
            </ul>

            <p></p>

            <h5 className="faqheader">0.9.1 : 2022/02/23 : Bug fix #1</h5>

            <ul>
                <li>Add 'version updated, please refresh' popup. </li>
                <li>Add Updates page.</li>
                <li>Fix bug where level 2 of foci was granting a bonus skill level.</li>
                <li>Fix bug where second attribute pick on growth tables was not being applied to the attribute.</li>
                <li>Fix bug where Unarmed Attack was being listed as equipment.</li>
                <li>Fix bug where partial internationalization was being applied.</li>
                <li>Fix bug where the mouseover tooltip for the Stow Item button was "Stow Iten".</li>
            </ul>

            <h5 className="faqheader">0.9.2 : 2022/02/27 : Minor Feature Update #1</h5>

            <ul>
                <li>Add non-standard attribute generation methods - 3d6 each, order as desired, set one to 14; and set attributes as desired (3-18). </li>
                <li>Add reroll button to attribute and hit point rolls (and reroll counters so that all may know your shame).</li>
                <li>Add buttons for selecting a random Background and random Class.</li>
                <li>Hilight all skills that have level-0 or better in skill selection sections.</li>
                <li>Validation messages are now not displayed until the user clicks a 'View Errors' button. </li>
                <li>Tweak the layout so that skill selections can render in a two column format on mobile screens.</li>
            </ul>

            <h5 className="faqheader">0.9.3 : 2022/08/29 : Major Feature Update - Leveling Up</h5>

            <ul>
                <li>Added support for advancing character's level. </li>
            </ul>

            <h5 className="faqheader">0.9.4 : 2022/09/01 : Bug fix and Minor Feature Update #2</h5>

            <ul>
                <li>Attribute increases purchased with skill points are now applied to Hit Points, Armor Class, Weapon stats, Encumbramce, Saving Throws, and Effort.</li>
                <li>Points spent on buying psychic techniques were being counted towards points spent on increasing psychic skill levels in message saysing how many points requried to increase skill level.</li>
                <li>Fix issue where technique descriptions were blank when buying a technique. </li>
                <li>Add list of current skill levels when buying skills.</li>
                <li>Add collapsible sections in character designer.</li>
                <li>Add random name generator.</li>
            </ul>

            <h5 className="faqheader">0.9.5 : 2022/09/03 : Minor Feature Update #3</h5>

            <ul>
                <li>Add Telekinetic Armory weapons and armor. If the character takes the Telekinetic Armory psychic discipline, the TK weapons and armor are automatically added to their gear list. </li>
            </ul>

            <h5 className="faqheader">0.9.6 : 2022/09/03 : Minor Feature Update #4</h5>

            <ul>
                <li>Add Heavy Weapons to equipment.</li>
            </ul>

            <h5 className="faqheader">0.9.7 : 2022/09/09 : Minor Feature Update #5</h5>

            <ul>
                <li>Add Vehicles to equipment.</li>
            </ul>

            <h5 className="faqheader">0.9.8 : 2022/09/12 : Minor Feature Update #6</h5>

            <ul>
                <li>Add VI (Robot) player-characters.</li>
            </ul>

            <h5 className="faqheader">0.9.9 : 2022/09/17 : Minor Feature Update #7</h5>

            <ul>
                <li>Add Cyberware and Unique Gift.</li>
            </ul>

            <h5 className="faqheader">0.9.10 : 2022/09/23 : Minor Feature Update #8</h5>

            <ul>
                <li>Add designing Alien Foci.</li>
            </ul>

            <h5 className="faqheader">0.9.11 : 2022/10/09 : Minor Feature Update #9</h5>

            <ul>
                <li>Add Drones and equipment mods.</li>
                <li>Remove 'Built-In' storage, replaced with 'Built-In' and 'Vehicle Bot Body' mods.</li>
            </ul>

            <h5 className="faqheader">0.9.12 : 2022/10/12 : Minor Feature Update #10</h5>

            <ul>
                <li>Add Artifact items and Artifact Manufacture mods.</li>
            </ul>

            <h5 className="faqheader">1.0.0 : 2022/10/15 : Minor Feature Update #11</h5>

            <ul>
                <li>Add Robots and Robot upgrades as items.</li>
                <li>This completes all the core functions required to create, level-up and equip a SWN character.</li>
            </ul>

            <h5 className="faqheader">1.1.0 : 2022/11/13 : Major Feature Update #12</h5>

            <ul>
                <li>Add Starship Designer.</li>
            </ul>

            <h5 className="faqheader">1.1.1 : 2022/11/19 : Minor Feature Update #13</h5>

            <ul>
                <li>Allow combining System Drive with Spike Drive upgrades.</li>
                <li>Add validation that checks if Automation Support fitting also has Automation Support Bots.</li>
            </ul>

            <h5 className="faqheader">1.1.2 : 2023/03/05 : Minor Bug Fix #14</h5>

            <ul>
                <li>Fix issue where if character has the Metapsionics skill it breaks the display of psychic skills and disciplines on the downloaded Print sheet.</li>
            </ul>

            <h5 className="faqheader">1.1.3 : 2025/03/22 : Minor Bug Fix #15</h5>

            <ul>
                <li>Fix issue where hamburger menu disappears at small screen widths. Fix kindly provided by <a href="https://github.com/AppSynergy" target="_blank">AppSynergy</a>.</li>
                <li>Fix issue where Psych drug was listed with price $1000 (instead of $25). Fix kindly provided by <a href="https://github.com/winterbeef" target="_blank">WinterBeef</a>.</li>
            </ul>

        </div>

    );
}

export default Updates;
