import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import app from "../firebase";
import { getFirestore, collection, getDocs, query, where, orderBy, deleteDoc, doc, updateDoc } from "firebase/firestore";

import { AuthContext } from "../Auth";

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfirmModal from "../components/ConfirmModal";
import { getAlienFocusesFromDB } from "../utilities/Utilities";
import { Lookups } from "../lookups/Lookups";

type IProps = {}

const CustomAlienFoci: React.FunctionComponent<IProps> = (props: IProps) => {

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [alienFoci, setAlienFoci] = useState<Array<any>>([]);
    const [displayConfirmDelete, setDisplayConfirmDelete] = useState(false);
    const [alienFocusIdToDelete, setAlienFocusIdToDelete] = useState("");
    const [alienFocusNameToDelete, setAlienFocusNameToDelete] = useState("");
    const [reloadFoci, setReloadFoci] = useState(0);

    const lookups = Lookups.getInstance();

    useEffect(() => {
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {
                    const db = getFirestore(app);
                    const q = query(
                        collection(db, "alienFoci"),
                        where("user_id", "==", user.uid),
                        orderBy("alienFocus_lower", "asc")
                    );
                    const data = await getDocs(q);
                    const alienFoci = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setAlienFoci(alienFoci);
                }
            } catch (error: any) {
                console.log(error);
            }
            setLoading(false);
        };

        asyncUseEffect();
    }, [user, reloadFoci]);

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const onDelete = (alienFocusId: string, alienFocusName: string): void => {
        setAlienFocusIdToDelete(alienFocusId);
        setAlienFocusNameToDelete(alienFocusName);

        setDisplayConfirmDelete(true);
    }

    const deleteAlienFocus = async () => {
        // Delete the focus from the database. 
        const db = getFirestore(app);
        await deleteDoc(doc(db, "alienFoci", alienFocusIdToDelete));

        if (user) {
            const alienFocusesFromDB = await getAlienFocusesFromDB(user, app);
            lookups.addFoci("alienFoci", alienFocusesFromDB);

            const q = query(
                collection(db, "character"),
                where("user_id", "==", user.uid),
                where("origin", "==", alienFocusNameToDelete)
            );

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (docSnap) => {
                await updateDoc(docSnap.ref, { origin: "Human" });
            });
        }

        // Close the modal and reload the foci. 
        setDisplayConfirmDelete(false);
        setReloadFoci(reloadFoci + 1);
    }

    const alienFociRows = alienFoci.map((c: any) =>
        <tr key={c.id}>
            <td className="" ><Link to={"/customAlienFociDesigner/" + c.id}>{isBlank(c.alienFocus, "(No name)")}</Link></td>
            <td style={{ width: "10%" }} className="text-center">
                <button className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); onDelete(c.id, c.alienFocus) }}><FontAwesomeIcon icon={faTrash} title="Delete"></FontAwesomeIcon ></button>
            </td>
        </tr>
    )
    return (
        <div className="pageBody">
            <h1 className="pageHeading">Alien Foci</h1>

            <ConfirmModal
                displayModal={displayConfirmDelete}
                title="Confirm Delete Alien Origin Focus"
                message={"Are sure you want to delete the " + alienFocusNameToDelete + " Alien Origin Focus? The focus will be removed from all characters."}
                confirmButtonText="Delete"
                closeButtonText="Cancel"
                onClose={() => setDisplayConfirmDelete(false)}
                onConfirm={() => deleteAlienFocus()}
            />

            <div className="mt-2 mb-2"><Link className="btn btn-primary" to="/customAlienFociDesigner">Design an Alien Focus</Link></div>

            {!loading && alienFoci.length > 0 &&

                <table className="table table-striped table-sm w-auto">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" className="">Alien Species</th>
                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alienFociRows}
                    </tbody>
                </table>
            }

            {!loading && alienFoci.length === 0 &&
                <p>You currently have no Alien Foci.</p>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}

export default CustomAlienFoci;
