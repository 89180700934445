import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

// import { PrintTraits } from "../../interfaces/CharacterTraits";
import CharacterDesignMenu from "./../CharacterDesignMenu";

// import { Lookups } from "../../lookups/Lookups";
import StarshipDesignScreen from "./StarshipDesignScreen";
import Audit from "./Audit";
import { getInitialStarshipTraits, StarshipTraits } from "../../interfaces/StarshipTraits";


const StarshipDesignMainDemo: React.FunctionComponent<{}> = () => {

    const [screen, setScreen] = useState("design");
    const [starshipTraits, setStarshipTraits] = useState(getInitialStarshipTraits());
    const [goToCharacterList, setGoToCharacterList] = useState(false);

    useEffect(() => {
        const savedTraits = localStorage.getItem("tryShipTraits");
        if (savedTraits !== null) {
            const traits: StarshipTraits = JSON.parse(savedTraits) as unknown as StarshipTraits;
            setStarshipTraits(traits);
        }
    }, []);

    const cancel = () => {
        setGoToCharacterList(true);
    }

    const onSetStarshipTraits = (ship: StarshipTraits) => {
        // save ship traits into session storage:
        localStorage.setItem("tryShipTraits", JSON.stringify(ship, null, 2));
        setStarshipTraits(ship);
    }

    return (
        <>
            {screen === "design" &&
                <h1>Starship Designer</h1>
            }

            {screen === "audit" &&
                <h1>Starship Audit</h1>
            }

            {screen === "sheet" &&
                <h1>Starship Download</h1>
            }

            <CharacterDesignMenu
                demoMode={true}
                readOnly={false}
                screen={screen}
                setScreen={(screen: string) => setScreen(screen)}
                saveCharacter={() => null}
                cancel={cancel}
            />

            <p>Try out the Ship Designer, then sign up for an account so that you can save your ships.</p>

            {screen === "design" &&
                <StarshipDesignScreen starshipTraits={starshipTraits} setStarshipTraits={onSetStarshipTraits} readOnly={false} />}

            {screen === "audit" &&
                <Audit shipTraits={starshipTraits} />
            }

            {screen === "sheet" &&
                <p>Coming soon!</p>
            }

            <CharacterDesignMenu
                demoMode={true}
                readOnly={false}
                screen={screen}
                setScreen={(screen: string) => setScreen(screen)}
                saveCharacter={() => null}
                cancel={cancel}
            />

            {goToCharacterList &&
                <Redirect to="/characters" />
            }

            {/* <div><pre>{localStorage.getItem("tryShipTraits")}</pre></div> */}

        </>

    );
}

export default StarshipDesignMainDemo;

