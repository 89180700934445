import React, { useState, useEffect } from "react";

import app from "../firebase";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

import { version } from "../version";

const AdminVersion: React.FunctionComponent<unknown> = (props: any) => {

    const [versionOnServer, setVersionOnServer] = useState("-1");
    const [versionToSet, setVersionToSet] = useState("-1");

    // On first render, check if the code version thas been updated.
    useEffect(() => {
        const fetchVersion = async () => {
            const db = getFirestore(app);
            const docRef = doc(db, "version", "1");
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data) {
                    const versionOnServer = data.version;
                    setVersionOnServer(versionOnServer);
                    setVersionToSet(versionOnServer);
                }
            }
        };
    
        fetchVersion();
    }, []);

    const setVersion = async () => {
        try {
            const db = getFirestore(app);
            await setDoc(doc(db, "version", "1"), { version: versionToSet });
            setVersionOnServer(versionToSet);
        } catch (error) {
            alert(error);
        }
    };

    return (
        <div className="pageBody">

            <p>Version on local code: {version}</p>

            <p>Version on server: {versionOnServer}</p>

            <div>Set version on server: <input type="text" value={versionToSet} onChange={(e) => { setVersionToSet(e.target.value.trim()) }} /><button onClick={setVersion}>Set Version</button></div>

        </div>
    );
}

export default AdminVersion;
