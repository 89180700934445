import React, { useState } from "react";
import { Lookups } from "../../lookups/Lookups";
import $ from "jquery";

import { faShoppingCart, faRecycle, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { checkFittingIsAllowed, getCost, getDamage, getMass, getPower } from "../../utilities/starshipMainUtilities";
import { ShipFittingData } from "../../interfaces/ShipFitting";
import uniqid from "uniqid";
import DescriptionAlert from "../DescriptionAlert";


interface IProps {
    shipTraits: StarshipTraits;
    displayModal: boolean;
    itemType: string;
    onBuyOrSellFitting: (id: string, isBuy: boolean) => void;
    onClose: () => void;
}

const BuyFittingsModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const lookups = Lookups.getInstance();

    const [includeNonStationFittings, setIncludeNonStationFittings] = useState(false);
    const [displayGearDesc, setDisplayGearDesc] = useState([] as string[]);
    const [catFilter, setCatFilter] = useState("");

    const modalId = props.itemType + "_modal";

    if (props.displayModal) {
        ($("#" + modalId) as any).modal('show');
    }

    if (!props.displayModal) {
        ($("#" + modalId) as any).modal('hide');
    }

    const getNonStarshipFittingsCheckbox = () => {
        if(props.itemType !== "fitting") { return null; }

        const theHull = lookups.shipHulls.find((sh) => sh.name === props.shipTraits.hullType);
        if (theHull) {
            if (theHull.isSpaceStation) {
                return <div key={"getNonStarship"}><label htmlFor="cbNotForStation" className="small"><input id="cbNotForStation" type="checkbox" checked={includeNonStationFittings} onChange={(e) => setIncludeNonStationFittings(!includeNonStationFittings)}></input> Include fittings not permitted on a space station</label></div>
            }
        }

        return null;
    }

    const getCategoriesDropdown = () => {
        const theHull = lookups.shipHulls.find((sh) => sh.name === props.shipTraits.hullType);
        if (theHull) {
            const fittings = lookups.shipFittings.filter((f) => checkFittingIsAllowed(props.itemType, f, theHull, includeNonStationFittings));


            const makeUnique = (arr: string[]) => arr.filter(function (elem: any, index: number, self: any) {
                return index === self.indexOf(elem);
            });

            const categories = makeUnique(fittings.map((f) => f.category)).sort();

            if (categories.length > 1) {
                let output: any = [];
                categories.forEach((cat) => {
                    output.push(<option key={cat} value={cat}>{cat}</option>)
                })

                return <div key={"getCats"}className="mt-0 mb-1"><select value={catFilter} onChange={(e) => setCatFilter(e.currentTarget.value)}><option value="">-- All Fittings --</option>{output}</select></div>;
            }

        }
        return null;

    }

    const getFittings = () => {

        let output: any = [];

        const getRawQuantity = (fitting: ShipFittingData) => {
            let total = 0;

            const ownedFittings = props.shipTraits.fittings.filter((f) => f.id === fitting.id);
            if (ownedFittings) {
                total = ownedFittings.length;
            }

            return total;
        }

        const getQuantity = (fitting: ShipFittingData) => {
            let total = getRawQuantity(fitting);
            if (total !== 0) {
                return total + " x ";
            }
            return null;
        }

        const getMultiple = (fitting: ShipFittingData) => {
            let output: any[] = [];
            output.push(fitting.multiple ? <sup key={"M"} className="mr-1">M</sup> : "");
            if (includeNonStationFittings) {
                if (fitting.notForStation) { output.push(<sup key={"XSS"}>XSS</sup>); }
            }
            return output;
        }

        const getFootnote = () => {
            let output: any[] = [];

            output.push(<span key={"mutlFoot"} className="mr-2"><b><sup>M</sup>:</b> Can install multiple copies of this fitting</span>);

            if (includeNonStationFittings) {
                output.push(<span key={"xssFoot"}><b><sup>XSS</sup>:</b> Not usually permitted on a space station</span>);
            }

            return <div key={"BuyFittingsFootnote"} className="small">{output}</div>;
        }

        const onSetShowDescription = (id: string, name: string) => {
            let updatedDisplayGearDesc = [...displayGearDesc];
            if (displayGearDesc.indexOf(id + "_" + name) !== -1) {
                updatedDisplayGearDesc = updatedDisplayGearDesc.filter((g) => g !== id + "_" + name);
            } else {
                updatedDisplayGearDesc.push(id + "_" + name)
            }
            setDisplayGearDesc(updatedDisplayGearDesc);
        }

        const theHull = lookups.shipHulls.find((sh) => sh.name === props.shipTraits.hullType);
        if (theHull) {
            let fittings = lookups.shipFittings.filter((f) => checkFittingIsAllowed(props.itemType, f, theHull, includeNonStationFittings));

            if (catFilter !== "") {
                fittings = fittings.filter((f) => f.category === catFilter);
            }

            fittings.forEach((f) => {

                let className = "row border-bottom pt-1 pb-1"
                const quant = getQuantity(f);
                const rawQuant = getRawQuantity(f);
                if (rawQuant > 0) { className = className + " table-primary"; }

                const canBuy = f.multiple ? false : rawQuant === 1;
                const canSell = rawQuant === 0;

                if (props.itemType !== "weapons") {
                    output.push(
                        <div className={className} key={uniqid()} >
                            <div className={"col-8 col-sm-4 col-md-4 col-lg-4 col-xl-4 small"}><b>{quant}{f.name}{getMultiple(f)}</b></div>
                            <div className={"col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 small"}>
                                <button disabled={canBuy} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuy(f.id)}>
                                    <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                                </button>
                                <button disabled={canSell} className="btn btn-primary btn-tiny d-inline" onClick={(e) => onSell(f.id)}>
                                    <FontAwesomeIcon icon={faRecycle} title="Sell"></FontAwesomeIcon >
                                </button>
                                <button className="btn btn-primary btn-tiny" type="button" onClick={(e) => onSetShowDescription(f.id, f.name)} title="Show item description">
                                    <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                                </button>
                            </div>
                            <div className="col-4 col-sm-1 col-md-2 col-lg-2 col-xl-2 small">{getCost(f, props.shipTraits.hullType, lookups)}</div>
                            <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 small"><span className="d-none d-md-block">Power&nbsp;{getPower(f, props.shipTraits.hullType, lookups)}</span><span className="d-md-none">Pow&nbsp;{getPower(f, props.shipTraits.hullType, lookups)}</span></div>
                            <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 smaller">Mass&nbsp;{getMass(f, props.shipTraits.hullType, lookups)}</div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 smaller">{f.shortDesc}</div>
                            <div className="col-12">
                                <DescriptionAlert display={displayGearDesc.indexOf(f.id + "_" + f.name) !== -1}>
                                    {f.desc}
                                </DescriptionAlert>
                            </div>
                        </div>
                    );
                }

                if (props.itemType === "weapons") {
                    output.push(
                        <div className={className} key={uniqid()} >
                            <div className={"col-8 col-sm-4 col-md-4 col-lg-3 col-xl-3 small"}><b>{quant}{f.name}{getMultiple(f)}</b></div>
                            <div className={"col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 small"}>
                                <button disabled={false} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuy(f.id)}>
                                    <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                                </button>
                                <button disabled={false} className="btn btn-primary btn-tiny d-inline" onClick={() => onSell(f.id)}>
                                    <FontAwesomeIcon icon={faRecycle} title="Sell"></FontAwesomeIcon >
                                </button>
                                <button className="btn btn-primary btn-tiny" type="button" onClick={(e) => onSetShowDescription(f.id, f.name)} title="Show item description">
                                    <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                                </button>
                            </div>
                            <div className="col-4 col-sm-1 col-md-2 col-lg-2 col-xl-2 small">{getCost(f, props.shipTraits.hullType, lookups)}</div>
                            <div className="col-4 col-sm-2 col-md-2 col-lg-1 col-xl-1 small"><span className="d-none d-md-block">Power&nbsp;{getPower(f, props.shipTraits.hullType, lookups)}</span><span className="d-md-none">Pow&nbsp;{getPower(f, props.shipTraits.hullType, lookups)}</span></div>
                            <div className="col-4 col-sm-2 col-md-2 col-lg-1 col-xl-1 small">Mass&nbsp;{getMass(f, props.shipTraits.hullType, lookups)}</div>
                            <div className="col-4 col-sm-12 col-md-12 col-lg-1 col-xl-1 smaller">Hard. {f.weaponHardpoints}</div>
                            <div className="col-8 col-sm-2 col-md-2 col-lg-1 col-xl-1 small">Dmg&nbsp;{getDamage(f)}</div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 smaller">{f.shortDesc}, TL {f.TL}</div>
                            <div className="col-12">
                                <DescriptionAlert display={displayGearDesc.indexOf(f.id + "_" + f.name) !== -1}>
                                    {f.desc}
                                </DescriptionAlert>
                            </div>
                        </div>
                    );
                }


            })
        }

        output.push(getFootnote());

        return output;

    }

    const onBuy = (id: string): void => {
        props.onBuyOrSellFitting(id, true);
    }

    const onSell = (id: string): void => {
        props.onBuyOrSellFitting(id, false);
    }

    const onCloseModal = () => {
        props.onClose();
    }

    const getTitle = () => {
        if (props.itemType === "fitting") { return "Fittings"; }
        if (props.itemType === "defenses") { return "Defences"; }
        if (props.itemType === "weapons") { return "Weapons"; }
        return "'" + props.itemType + "'";
    }

    return (
        <div id={modalId} className="modal " tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Select {getTitle()}</h5>
                        <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        {getNonStarshipFittingsCheckbox()}

                        {/* <div>{JSON.stringify(props.shipTraits.fittings, null, 2)}</div> */}

                        {getCategoriesDropdown()}

                        {getFittings()}
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyFittingsModal;

