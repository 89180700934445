import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import app from "../firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import { version } from "../version";

import { AuthContext } from "../Auth";

import { useEffectHookLoadCharacter } from "../hooks/useEffectHookLoadCharacter";

import { SaveNewCharacter, UpdateCharacter } from "../data/UpdateCharacter";

import { getInitialCharacterTraits, PrintTraits } from "../interfaces/CharacterTraits";

import CharacterDesignMenu from "../components/CharacterDesignMenu";
import CharacterDesignDerivedStats from "./CharacterDesignDerivedStats";

import { Lookups } from "../lookups/Lookups";
import CharacterDesignScreen from "./charGen/CharacterDesignScreen";
import CharacterDesignSheetScreen from "./CharacterDesignSheetScreen";

import UpdateVersionModal from "../components/UpdateVersionModal";

interface RouteParams {
    id: string
}

const CharacterDesignMain: React.FunctionComponent<{}> = () => {

    // const { t } = useTranslation();

    const { user: currentUser } = useContext(AuthContext);
    const lookups = Lookups.getInstance();

    // Get the character Id from the URL route (if any). 
    let { id: IdInQuerystring } = useParams<RouteParams>();

    // State hooks:

    const [isLoading, setIsLoading] = useState(false);
    const [screen, setScreen] = useState("design");
    const [characterId, setCharacterId] = useState("");
    const [characterTraits, setCharacterTraits] = useState(getInitialCharacterTraits(lookups.backgrounds, lookups.classes, lookups.skills, lookups.focuses));
    const [goToCharacterList, setGoToCharacterList] = useState(false);
    const [showReloadModal, setShowReloadModal] = useState(false);
    const [versionOnServer, setVersionOnServer] = useState("");

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const db = getFirestore(app); 
                const docRefDetail = doc(db, "version", "1"); 
                const docSnap = await getDoc(docRefDetail);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data) {
                        const versionOnServer = data.version;
                        if (version.trim() !== versionOnServer) {
                            setVersionOnServer(versionOnServer);
                            setShowReloadModal(true);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching version:", error);
            }
        };

        checkVersion();
    }, [setVersionOnServer, setShowReloadModal]);

    // useEffect hook to load character if id in querystring. 
    useEffectHookLoadCharacter(IdInQuerystring, currentUser, setCharacterId, setCharacterTraits, setIsLoading, lookups.backgrounds, lookups.classes, lookups.skills, lookups.focuses);

    // Save the character:

    const saveCharacter = async () => {
        if (currentUser) {
            setIsLoading(true);
            if (characterId !== "") {
                await UpdateCharacter(currentUser, characterTraits, characterId);
            } else {
                await SaveNewCharacter(currentUser, characterTraits, setCharacterId);
            }
            setGoToCharacterList(true);
        }
    }

    const setPrintTraits = (printTraits: PrintTraits) => {
        const charTraits = { ...characterTraits };
        charTraits.print = printTraits;
        setCharacterTraits(charTraits);
    }

    const cancel = () => {
        setGoToCharacterList(true);
    }

    return (
        <>

            {showReloadModal &&
                <UpdateVersionModal newVersion={versionOnServer} />
            }

            {isLoading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

            {!isLoading &&
                <>

                    {screen === "design" &&
                        <h1>Character Designer</h1>
                    }

                    {screen === "audit" &&
                        <h1>Character Audit</h1>
                    }

                    {screen === "sheet" &&
                        <h1>Character Download</h1>
                    }

                    <CharacterDesignMenu
                        demoMode={false}
                        readOnly={false}
                        screen={screen}
                        setScreen={(screen: string) => setScreen(screen)}
                        saveCharacter={saveCharacter}
                        cancel={cancel}
                    />

                    {screen === "design" &&
                        <CharacterDesignScreen characterTraits={characterTraits} setCharacterTraits={setCharacterTraits} />}

                    {screen === "audit" &&
                        <CharacterDesignDerivedStats charTraits={characterTraits} />
                    }

                    {screen === "sheet" &&
                        <CharacterDesignSheetScreen charTraits={characterTraits} onSetPrintTraits={setPrintTraits} />
                    }

                    {screen !== "sheet" &&
                        <CharacterDesignMenu
                            demoMode={false}
                            readOnly={false}
                            screen={screen}
                            setScreen={(screen: string) => setScreen(screen)}
                            saveCharacter={saveCharacter}
                            cancel={cancel}
                        />
                    }

                    <a className="btn btn-primary btn-sm mr-2" href="https://www.buymeacoffee.com/swoone" target="_blank" rel="noopener noreferrer"><img src="/BuyMeACoffee.svg" height="20px" width="20px" alt="Buy Me A Coffee" title="Buy Me A Coffee" /> Buy Me A Coffee</a>


                </>
            }

            {goToCharacterList &&
                <Redirect to="/characters" />
            }

        </>

    );
}

export default CharacterDesignMain;

