import app from "../firebase";
import { CharacterTraits } from './../interfaces/CharacterTraits';
import { User } from "firebase/auth";
import { getFirestore, collection, addDoc, setDoc, doc } from "firebase/firestore";

import * as toastr from "toastr";

const getClassName = (c: CharacterTraits) => {
    const selectedClassNames = c.levelOne.classes.map((c) => c.className);

    let className = "";
    if (c.levelOne.isAdventurer) {
        className = "Adventurer (" + selectedClassNames.sort().join("/").replace(/Partial/gi, "").replace(/ /ig, "").replace(/ /ig, "") + ")";
    } else {
        if (selectedClassNames.length > 0) {
            className = selectedClassNames[0];
        } else {
            className = "";
        }
    }

    return className;
}

const getBasicDataToSave = (c: CharacterTraits, currentUser: User) => {
    return {
        background: c.background.backgroundName,
        className: getClassName(c),
        origin: c.basicTraits.origin,
        isPublic: c.basicTraits.isPublic,
        level: c.level,
        name: c.basicTraits.name,
        name_lower: c.basicTraits.name.toLowerCase(),
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}

const getTraitsDataToSave = (c: CharacterTraits, currentUser: User) => {
    return {
        name: c.basicTraits.name,
        traits: c,
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}


export const SaveNewCharacter = async (
    currentUser: User,
    characterTraits: CharacterTraits,
    setCharacterId: any,
) => {

    const c = characterTraits;

    if (currentUser) {

        let characterId = "";

        // Save basic details of character into 'character' collection, used for listing characters.
        const basicDataToSave = getBasicDataToSave(c, currentUser);

        const db = getFirestore(app);
        try {
            const docRef = await addDoc(collection(db, "character"), basicDataToSave);
            characterId = docRef.id;
            setCharacterId(characterId);
        } catch (error: any) {
            console.log(JSON.stringify(error, null, 2));
        }

        // Save details of character into 'characterTraits' collection, used for editing a character.

        const characterTraitsDataToSave = getTraitsDataToSave(c, currentUser);
        characterTraits.id = characterId;

        try {
            await setDoc(doc(db, "characterTraits", characterId), characterTraitsDataToSave); 
        } catch (error: any) {
            console.log(JSON.stringify(error, null, 2));
        }
    }
}

export const UpdateCharacter = async (
    currentUser: User | null,
    characterTraits: CharacterTraits,
    characterId: string,

) => {

    const c = characterTraits;

    if (currentUser) {

        // Update basic details of character into 'character' collection, used for listing characters.
        const basicDataToSave = getBasicDataToSave(c, currentUser);

        const db = getFirestore(app);
        try {
            await setDoc(doc(db, "character", characterId), basicDataToSave); 
        } catch (error: any) {
            console.log(JSON.stringify(error, null, 2));
        }

        // Update details of character into 'characterTraits' collection, used for editing a character.
        const characterTraitsDataToSave = getTraitsDataToSave(c, currentUser);

        try {
            await setDoc(doc(db, "characterTraits", characterId), characterTraitsDataToSave);
        } catch (error: any) {
            console.log(JSON.stringify(error, null, 2));
        }
    }
}