import React from "react";

import { faCaretRight, faDice, faHandPaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterTraits } from "../interfaces/CharacterTraits";

import ValidationAlert from "./ValidationAlert";
import BuyEquipment from "./charGen/BuyEquipment";
import PickEquipmentPack from "./charGen/PickEquipmentPack";
import { useCollapsibleSection } from "../hooks/useCollapsibleSection";

interface IProps {
    charTraits: CharacterTraits;
    onSetStartingCredits: (credits: number) => void;
    onSetGearMethodToPack: () => void;
    onSelectEquipmentPack: (pack: string) => void;
    onBuyGear: (uniqid: string, id: string, name: string, price: number, buy: boolean, storage: string) => void;
    onSetItemStorage: (uniqid: string, existingStorage: string, newStorage: string) => void;
    onSetAdditionalCredits: (additionalCredits: number) => void;
    onSwitchCollapsed: (sectionName: string) => void;
    onAddMod: (itemUniqueId: string, modId: string, isAddMod: boolean) => void;
    onSetCustomName: (itemUniqueId: string, customName: string) => void;
    onSetCustomNotes: (itemUniqueId: string, customNotes: string) => void;
}

const CharacterDesignStep7Gear: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "equipment";
    
    const { sectionClassName, switchDisplay, getCollapseIcon } = useCollapsibleSection(
        props.charTraits.basicTraits.collapsedSections,
        props.onSwitchCollapsed,
        sectionName,
    );

    const onSetStartingCredits = () => {
        const credits = ((Math.floor(Math.random() * 6) + 1) + (Math.floor(Math.random() * 6) + 1)) * 100;
        props.onSetStartingCredits(credits);
        props.onBuyGear("-999", "ME9", "Unarmed Attack", 0, true, "Readied"); // Everyone gets Unarmed Attack Weapon for free. 
    }

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Equipment {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>


                <div className="mb-2">
                    {char.gear.method === "" &&
                        <>

                            <div>
                                <div className="mb-2">
                                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                                    Pick how to equip your character:
                                </div>
                                <div className="mb-2">
                                    <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => onSetStartingCredits()}>
                                        <FontAwesomeIcon icon={faDice} title="text-info" className="mr-1"></FontAwesomeIcon >
                                        Roll
                                    </button> Roll 2d6x100 for starting credits, then buy equipment</div>
                                <div className="mb-2">
                                    <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSetGearMethodToPack()}>
                                        <FontAwesomeIcon icon={faHandPaper} title="text-info" className="mr-1"></FontAwesomeIcon >
                                        Pack
                                    </button> Select a premade package of gear</div>
                                {char.showValidation && char.levelOne.validationCodes.indexOf("equipmentMethodNotSelected") !== -1 &&
                                    <ValidationAlert msg="Equipment method must be selected" />
                                }
                            </div>

                        </>
                    }
                </div>

                {char.gear.method === "pack" &&
                    <PickEquipmentPack
                        charTraits={props.charTraits}
                        onSelectEquipmentPack={props.onSelectEquipmentPack}
                    />
                }

                {char.gear.startingCredits !== -1 &&
                    <BuyEquipment
                        charTraits={props.charTraits}
                        onBuyGear={props.onBuyGear}
                        onSetItemStorage={props.onSetItemStorage}
                        onSetAdditionalCredits={props.onSetAdditionalCredits}
                        onAddMod={props.onAddMod}
                        onSetCustomName={props.onSetCustomName}
                        onSetCustomNotes={props.onSetCustomNotes}
                    />
                }

                {/* <div><pre>{JSON.stringify(props.charTraits.gear, null, 2)}</pre></div> */}
            </div>

        </div>
    );

}

export default CharacterDesignStep7Gear;
